import { useContext } from "react";
import avatarImg from "../../assets/avatar.png";
import logoImg from "../../assets/logo.png";
import { Link } from "react-router-dom";

import { AuthContext } from "../../contexts/auth";
import { FiHome, FiUser, FiSettings } from "react-icons/fi";
import { RxExit, RxEnter } from "react-icons/rx";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import { AiFillCopy } from "react-icons/ai";
import "./header.css";
import { FiUsers } from "react-icons/fi";

export default function Header() {
  const { signed, user, logout } = useContext(AuthContext);

  return (
    <div className="sidebar">
      <div className="links">
 
        <Link to="/home">
          <FiHome color="#FFfffF" size={24} />
          Inicio
        </Link>

{user?.admin &&
        <Link to="/edit-map">
          <AiFillCopy color="#FFfffF" size={24} />
          Mapas
        </Link>     
        }

{user?.admin &&
          <Link to="/users">
          <FiUsers color="#FfffFF" size={24} />
          Usuários
        </Link>
}

        {signed === true ? (
          <Link to="/profile">
            <FiSettings color="#FfffFF" size={24} />
            Perfil
          </Link>
        ) : null}

 
      </div>

      {signed === true ? (
        <div className="link-profile">
          {/* <img
            src={user?.avatarUrl === null ? avatarImg : user?.avatarUrl}
            alt="Foto do usuario"
          /> */}
          <span>{user?.name}</span>
          <button onClick={() => logout()}>
            <RxExit color="#FFF" size={24} />
          </button>
        </div>
      ) : (
        <Link to="/" className="link-login">
          <span>Login</span>
          <RxEnter color="#FFF" size={24} />
        </Link>
      )}
      {/* <div className="link-profile">
        <img
          src={user?.avatarUrl === null ? avatarImg : user?.avatarUrl}
          alt="Foto do usuario"
        />
        <span>{user?.name}</span>
        <button onClick={() => logout()}>
          <RxExit color="#FFF" size={24} />
        </button>
      </div> */}
    </div>
  );
}
