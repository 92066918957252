export const formatarNumero = (numero) => {
  if (isNaN(numero)) {
    return "Número inválido";
  }

  // Formatação para separar milhares e decimais
  const formato = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formato.format(numero);
};
