import React, { useState, useEffect, useContext } from "react";

import "./queue.css";
import { AuthContext } from "../../../contexts/auth";
import { format, parseISO } from "date-fns";

import { FiRefreshCcw } from "react-icons/fi";

import { AiOutlineClose } from "react-icons/ai";
import {
  IoLocationOutline,
  IoCaretUp,
  IoCaretDown,
  IoOptions,
} from "react-icons/io5";
import config from "../../../config";
import { toast } from "react-toastify";
import { IoMdPersonAdd } from "react-icons/io";
import Modal from "react-modal";

import { ImUpload } from "react-icons/im";
import { IoArrowDownOutline, IoArrowUpOutline } from "react-icons/io5";

import { LiaUserEditSolid } from "react-icons/lia";
import { MdOutlineDelete, MdBlockFlipped } from "react-icons/md";
import { ImBlocked } from "react-icons/im";
import ModalTransferQueueForTable from "../../../components/ModalTransferQueueForTable";
import ModalDeleteQueue from "../../../components/ModalDeleteQueue";
import ModalEditQueue from "../../../components/ModalEditQueue";
export default function Queue({
  event,
  dataLocacao,
  event_id,
  vendedores,
  getEvent,
  id_map,
  boxes,
  setBoxes,
}) {
  const [formData, setFormData] = useState({
    id_map,
    comprador: "",
    vendedor: "",
    anotacoes: "",
  });
  const { user } = useContext(AuthContext);

  const [modalEditQueueIsOpen, setModalEditQueueIsOpen] = useState(false);
  const [modalDeleteQueueIsOpen, setModalDeleteQueueIsOpen] = useState(false);
  const [modalTransferIsOpen, setModalTransferIsOpen] = useState(false);
  const [queueIdEdit, setQueueIdEdit] = useState(null);
  const [ordemSelecionada, setOredemSelecionada] = useState(null);
  const [dataEdit, setDataEdit] = useState(null);

  const [queue, setQueue] = useState([{}, {}, {}]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getQueue();
    return () => ({});
  }, [dataLocacao]);

  const handleFormSubmit = (e) => {
    addForQueue();
  };

  const openModalEdit = (idUserEdit, ordem, data) => {
    setDataEdit(data);
    setOredemSelecionada(ordem);
    setQueueIdEdit(idUserEdit);
    setModalEditQueueIsOpen(true);
  };

  const closeModalEdit = () => {
    setOredemSelecionada(null);
    setModalEditQueueIsOpen(false);
    getQueue();
    // setQueueIdEdit(null)
  };

  const openModalDelete = (idUserDelete, ordem) => {
    setOredemSelecionada(ordem);
    setQueueIdEdit(idUserDelete);
    setModalDeleteQueueIsOpen(true);
  };

  const closeModalDelete = () => {
    setOredemSelecionada(null);
    setModalDeleteQueueIsOpen(false);
    // setQueueIdEdit(null)
    getQueue();
  };

  const openModalTransfer = (idUserDelete, ordem) => {
    setOredemSelecionada(ordem);
    setQueueIdEdit(idUserDelete);
    setModalTransferIsOpen(true);
  };

  const closeModalTransfer = () => {
    setOredemSelecionada(null);
    setModalTransferIsOpen(false);
    // setQueueIdEdit(null)
    getQueue();
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "90%", // Defina a largura desejada
      maxWidth: "900px", // Defina uma largura máxima opcional
      height: "40vh", // Defina a altura desejada (exemplo: 60% da altura da viewport)
      maxHeight: "90vh", // Defina uma altura máxima opcional
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.8)", // Cor de fundo do overlay (fundo escuro semitransparente)
      zIndex: 1000, // Ajusta a ordem de empilhamento para garantir que o modal esteja acima do conteúdo
    },
  };

  const customStyles_ = {
    content: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "90%", // Defina a largura desejada
      maxWidth: "900px", // Defina uma largura máxima opcional
      height: "auto", // Defina a altura desejada (exemplo: 60% da altura da viewport)
      minHeight: "70vh",
      maxHeight: "90vh", // Defina uma altura máxima opcional
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.8)", // Cor de fundo do overlay (fundo escuro semitransparente)
      zIndex: 1000, // Ajusta a ordem de empilhamento para garantir que o modal esteja acima do conteúdo
    },
  };

  async function addForQueue() {
    try {
      setLoading(true);

      const response = await fetch(
        `${config.urlServer}/event/queue/queue-add`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: user.email,
            token: user.token,
            eventid: event_id,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.status !== 201) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao atualizar o evento";
        throw new Error(errorMessage);
      }

      const json = await response?.json();

      console.log(json);
      setLoading(false);
      getQueue();
      toast.success("Fila editada com sucesso!");
      setFormData({
        id_map,
        comprador: "",
        vendedor: "",
        anotacoes: "",
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error(error.msg || "Ops, algo deu errado ao editar o evento!");
    }
  }

  async function getQueue() {
    try {
      setLoading(true);

      const response = await fetch(
        `${config.urlServer}/event/queue/queue-list`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: user.email,
            token: user.token,
            idmap: id_map,
          },
        }
      );

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao buscar usuários";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // json deve ser uma lista de eventos
      const queue_result = json?.fila_de_espera?.map((result) => ({
        id: result?._id,
        comprador: result?.comprador,
        vendedor: result?.vendedor,
        anotacoes: result?.anotacoes,
        created_at: result?.created_at,
        status: result?.status,
        ordem: result?.ordem,
      }));

      // Use setEvents para salvar os eventos no estado do seu componente
      setQueue(queue_result);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao buscar os usuarios!"
      );
    }
  }

  const updateTransferLocalBoxes = (alteracoes) => {
    // updateLocalBoxes(__id_locacao, json?.locacao_de_origem)
    // updateLocalBoxes(__locacao_destino, json?.locacao_de_destino)

    // Crie uma cópia do array 'boxes'
    const updatedBoxes = [...boxes];

    for (let i = 0; i < alteracoes.length; i++) {
      console.log(alteracoes);
      const index = updatedBoxes.findIndex(
        (box) => box._id === alteracoes[i].id
      );

      if (index !== -1) {
        // Atualize a cor do item no índice encontrado
        updatedBoxes[index] = {
          ...updatedBoxes[index],
          comprador: alteracoes[i].dados?.comprador,

          forma_de_pagamento: alteracoes[i].dados?.forma_de_pagamento,
          lugares: alteracoes[i].dados?.lugares,
          mapCopy: alteracoes[i].dados?.mapCopy,
          numero: alteracoes[i].dados?.numero,
          status: alteracoes[i].dados?.status,
          valor_por_lugar: alteracoes[i].dados?.valor_por_lugar,
          valor_recebido: alteracoes[i].dados?.valor_recebido,
          valor_total_locacao: alteracoes[i].dados?.valor_total_locacao,
          vendedor: alteracoes[i].dados?.vendedor,
        };
        setBoxes(updatedBoxes);
        console.log(updatedBoxes[index]);
        // Atualize o estado com o novo array
      }
    }
  };

  const handleInputChange = (e, index, fieldName) => {
    const { value, name } = e.target;

    const updatedFormData = { ...formData };

    if (name.includes("lugares")) {
      updatedFormData.lugares[index][fieldName] = value;
    } else {
      updatedFormData[name] = value;
    }

    setFormData(updatedFormData);
    console.log(updatedFormData);
  };

  const [statusListFilter, setStatusListFilter] = useState(["espera"]);

  const handleCheckboxChange = (filtro) => {
    const filtroIndex = statusListFilter.indexOf(filtro);

    if (filtroIndex !== -1) {
      // Se o filtro já estiver presente, remove-o
      const newFilters = [...statusListFilter];
      newFilters.splice(filtroIndex, 1);
      setStatusListFilter(newFilters);
    } else {
      // Se o filtro não estiver presente, adiciona-o
      setStatusListFilter([...statusListFilter, filtro]);
    }
  };

  const handleEditOrdemUp =async (id, ordem, index) => {
    try{

    // Verifica se não é a primeira linha da tabela
    if (index > 0) {
      // Obtém a referência para a linha atual e a linha anterior
      const currentRow = queue[index];
      const previousRow = queue[index - 1];
  
      // Troca as posições na lista
      queue[index] = previousRow;
      queue[index - 1] = currentRow;
  
      // Atualiza as variáveis ordem
      const tempOrdem = currentRow.ordem;
      currentRow.ordem = previousRow.ordem;
      previousRow.ordem = tempOrdem;
  
      // Atualiza o estado ou a variável de controle que armazena a fila (queue)
      // Isso pode ser feito de acordo com a lógica do seu componente/estado
      // Exemplo (considerando que você está usando useState):
      setQueue([...queue]);
    }
  
          // Atualiza a ordem no banco de dados
    await updateOrdemInDatabase(id, queue[index].ordem, queue[index - 1].ordem);

    // Atualiza o estado ou a variável de controle que armazena a fila (queue)
    setQueue([...queue]);
  } catch (error) {
    console.error(error);
  }
    
  };
  
  const handleEditOrdemDown =async (id, ordem, index) => {
    try{
    // Verifica se não é a última linha da tabela
    if (index < queue.length - 1) {
      // Obtém a referência para a linha atual e a linha seguinte
      const currentRow = queue[index];
      const nextRow = queue[index + 1];
  
      // Troca as posições na lista
      queue[index] = nextRow;
      queue[index + 1] = currentRow;
  
      // Atualiza as variáveis ordem
      const tempOrdem = currentRow.ordem;
      currentRow.ordem = nextRow.ordem;
      nextRow.ordem = tempOrdem;
  
      // Atualiza o estado ou a variável de controle que armazena a fila (queue)
      // Exemplo (considerando que você está usando useState):
      setQueue([...queue]);
      }
      await updateOrdemInDatabase(id, queue[index].ordem, queue[index + 1].ordem);

      // Atualiza o estado ou a variável de controle que armazena a fila (queue)
      setQueue([...queue]);
    } catch (error) {
      console.error(error);
    }
  };

  const updateOrdemInDatabase = async (id, ordemAtual, novaOrdem) => {
    try {
      // Faça a chamada para a rota ou método no seu servidor que atualiza a ordem no banco de dados
      const response = await fetch(`${config.urlServer}/event/queue/queue-update-order`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
        },
        body: JSON.stringify({ id_map, id, ordemAtual, novaOrdem }),
      });
  
      if (response.status !== 200) {
        const errorData = await response.json();
        throw new Error(errorData.msg || "Erro desconhecido ao atualizar ordem no banco de dados");
      }
  
      const json = await response.json();
      console.log(json);  // Se desejar, pode imprimir a resposta do servidor
    } catch (error) {
      console.error(error);
      throw new Error("Erro ao atualizar ordem no banco de dados");
    }
  };


  return (
    <section className="">
      <section className="">
        <table className="table-list-users">
          <thead>
            <tr>
              <th colSpan={7}>
                Fila de espera
                <label style={{ paddingLeft: 30 }}>
                  <input
                    style={{ marginRight: 5 }}
                    type="checkbox"
                    checked={statusListFilter?.includes("espera")}
                    onChange={() => handleCheckboxChange("espera")}
                  />
                  Espera
                </label>
                <label style={{ paddingLeft: 30 }}>
                  <input
                    style={{ marginRight: 5 }}
                    type="checkbox"
                    checked={statusListFilter?.includes("alocado")}
                    onChange={() => handleCheckboxChange("alocado")}
                  />
                  Alocados
                </label>
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: 0,
                    position: "absolute",
                    right: "5%",
                  }}
                  onClick={() => getQueue()}
                >
                  <FiRefreshCcw size={18} />
                </button>
              </th>
            </tr>
            <tr>
              <th style={{ width: 10, textAlign: "center" }} colSpan={2}>
                Ordem
              </th>
              {/* <th>Status</th> */}
              <th>Comprador</th>
              <th>Vendedor</th>
              <th>Anotações</th>
              <th>Adicionado em</th>
              <th style={{ width: 150 }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr className="form-add-queue-in-tr">
              <td>Espera</td>
              <td>Geral</td>

              {/* <th></th> */}
              <td>
                <input
                  width={"100%"}
                  type="text"
                  name="comprador"
                  autoFocus
                  value={formData?.comprador}
                  onChange={handleInputChange}
                  placeholder="Nome do comprador"
                />
              </td>

              <td>
                <select
                  name="vendedor"
                  value={formData?.vendedor}
                  onChange={handleInputChange}
                >
                  <option value="" selected disabled hidden>
                    Escolha um...
                  </option>
                  {vendedores?.map((item) => {
                    return <option value={item?.email}>{item?.nome}</option>;
                  })}
                </select>
              </td>
              <td>
                <input
                  type="text"
                  name="anotacoes"
                  value={formData?.anotacoes}
                  onChange={handleInputChange}
                  placeholder="Anotações"
                />
              </td>
              <td>{format(new Date(), "dd/MM/yyyy")}</td>

              <td>
                <button
                  className="btn-action-users"
                  onClick={() => handleFormSubmit()}
                >
                  Adicionar
                  <IoMdPersonAdd size={20} />
                </button>
              </td>
            </tr>

            {queue
              ?.filter((item) => statusListFilter?.includes(item?.status))
              ?.map((item, index) => {
                return (
                  <tr
                    key={item?.id}
                    style={{
                      color: item?.status === "alocado" ? "var(--grass)" : "",
                    }}
                  >
                    <td style={{ fontWeight: "bold" }}>{index + 1}º</td>
                    <td>{item?.ordem}</td>
                    {/* <td style={{textTransform: 'capitalize'}}>{item?.status}</td> */}
                    <td>{item?.comprador}</td>
                    <td>{item?.vendedor}</td>
                    <td>{item?.anotacoes}</td>
                    <td>
                      {item?.created_at &&
                        format(
                          parseISO(item?.created_at),
                          "dd/MM/yyyy - HH:mm:ss"
                        )}
                    </td>

                    <td>
                      
                      <button
                        className="btn-action-users"
                        onClick={() =>
                          openModalEdit(item?.id, item?.ordem, item)
                        }
                      >
                        <LiaUserEditSolid size={25} />
                      </button>

                      <button
                        className="btn-action-users"
                        onClick={() => openModalDelete(item?.id, item?.ordem)}
                      >
                        <MdOutlineDelete size={25} />
                      </button>
                      {item?.status === "alocado" ? null : (
                        <button
                          className="btn-action-users"
                          onClick={() =>
                            openModalTransfer(item?.id, item?.ordem)
                          }
                        >
                          <ImUpload size={22} />
                        </button>
                      )}

                      <button
                        className="btn-action-users"
                        onClick={() => handleEditOrdemUp(item?.id, item?.ordem, index)}
                      >
                        <IoArrowUpOutline size={22} />
                      </button>

                      <button
                        className="btn-action-users"
                        onClick={() => handleEditOrdemDown(item?.id, item?.ordem, index)}
                      >
                        <IoArrowDownOutline size={22} />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <Modal
          isOpen={modalTransferIsOpen}
          onRequestClose={closeModalTransfer}
          style={customStyles}
        >
          <ModalTransferQueueForTable
            closeModal={closeModalTransfer}
            boxes={boxes}
            id_map={id_map}
            queueIdEdit={queueIdEdit}
            ordem={ordemSelecionada}
            updateTransferLocalBoxes={updateTransferLocalBoxes}
          />
        </Modal>

        <Modal
          isOpen={modalEditQueueIsOpen}
          onRequestClose={closeModalEdit}
          style={customStyles}
        >
          <ModalEditQueue
            closeModalEdit={closeModalEdit}
            boxes={boxes}
            vendedores={vendedores}
            id_map={id_map}
            queueIdEdit={queueIdEdit}
            dataEdit={dataEdit}
            ordem={ordemSelecionada}
            updateTransferLocalBoxes={updateTransferLocalBoxes}
          />
        </Modal>

        <Modal
          isOpen={modalDeleteQueueIsOpen}
          onRequestClose={closeModalDelete}
          style={customStyles}
        >
          <ModalDeleteQueue
            closeModal={closeModalDelete}
            boxes={boxes}
            id_map={id_map}
            queueIdEdit={queueIdEdit}
            ordem={ordemSelecionada}
            closeModalDelete={closeModalDelete}
          />
        </Modal>
      </section>
    </section>
  );
}
