import { useState, useContext } from "react";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { AuthContext } from "../../contexts/auth";

export default function SignUp() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const { 
    // signUp,
     loadingAuth } = useContext(AuthContext);

  async function handleSubmit(e) {
    e.preventDefault();

    if (
      !(
      name !== "" &&
      email !== "" &&
      password !== "" &&
      passwordConfirm !== ""
      )
    ) {
      toast.error("Preencha todos os campos!\n");
      return;
    }

    if (password !== passwordConfirm) {
      toast.error("As senhas estão diferentes!\n");
      return;
    }

    // await signUp(name, email, password, passwordConfirm);
  }

  return (
    <div className="container-center">
      <div className="login">
        <div className="login-area">
          {/* <img src={logo} alt="Logo do sistema de chamados" /> */}
        </div>

        <form onSubmit={handleSubmit}>
          <h1>Nova conta</h1>
          <input
            type="text"
            placeholder="Seu name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <input
            type="text"
            placeholder="email@email.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            type="text"
            placeholder="********"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            type="text"
            placeholder="********"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
          {passwordConfirm && passwordConfirm !== password ? (
            <span style={{ color: "red", marginTop: -10, marginBottom: 25 }}>
              Senhas diferentes!
            </span>
          ) : null}

          <button type="submit">
            {loadingAuth ? "Carregando..." : "Cadastrar"}
          </button>
        </form>

        <Link to="/">Já possui uma conta? Faça login</Link>
      </div>
    </div>
  );
}
