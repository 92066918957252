import React, { useState, useEffect, useContext } from "react";

import "./modalUpdateEvent.css";
import { format } from "date-fns";
import { AuthContext } from "../../contexts/auth";

import { AiOutlineClose } from "react-icons/ai";
import { IoLocationOutline, IoCaretUp, IoCaretDown, IoOptions } from "react-icons/io5";
import config from "../../config";
import { toast } from "react-toastify";

export default function ModalUpdateEvent({ closeModal, boxes, dataLocacao }) {
  const [formData, setFormData] = useState({
    status: dataLocacao?.status,
    qtd_lugares: dataLocacao?.lugares?.length,
    valor_total_locacao: dataLocacao?.valor_total_locacao,
    valor_recebido: dataLocacao?.valor_recebido,
    anotacoes_financeiras: dataLocacao?.anotacoes_financeiras
      ? dataLocacao?.anotacoes_financeiras
      : "",
    lugares: dataLocacao?.lugares || [],
    comprador: dataLocacao?.comprador,
  });
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // getListMaps();
    // setFormData({ ...formData, [name]: value });
    return () => ({});
  }, [dataLocacao]);

  const teste = () => {
    console.log(dataLocacao);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // if (user.email && user.token) {
    //   if (
    //     !formData.nome ||
    //     !formData.descricao ||
    //     !formData.localizacao ||
    //     !formData.data
    //   ) {
    //     toast.error("Preencha todos os campos!");
    //     return;
    //   }
    //   const { nome, descricao, data, localizacao } = formData;
    //   ModalUpdateTable(nome, descricao, data, localizacao);
    // } else {
    //   console.log(user);
    //   console.log("Dados do formulário:", formData);
    // }
  };

  const handleInputChange__ = (e) => {
    const { name, value } = e.target;

    const updatedFormData = { ...formData };

    if (name.includes("lugares")) {
      const [parentName, childName] = name.split(".");
      updatedFormData[parentName][childName] = value;
    } else {
      updatedFormData[name] = value;
    }

    setFormData(updatedFormData);
    // console.log(updatedFormData);
  };

  const handleInputChange = (e, index, fieldName) => {
    const { value, name } = e.target;

    const updatedFormData = { ...formData };

    if (name.includes("lugares")) {
      updatedFormData.lugares[index][fieldName] = value;
    } else {
      updatedFormData[name] = value;
    }

    setFormData(updatedFormData);
    console.log(updatedFormData);
  };

  const increment = () => {
    // Incrementa o valor de qtd_lugares e atualiza o estado
    let lugares = formData.lugares;
    lugares.push({ nome: "", documento: "" });
    setFormData({
      ...formData,
      qtd_lugares: formData.qtd_lugares + 1,
      lugares: lugares,
    });
  };

  const decrement = () => {
    // Decrementa o valor de qtd_lugares, mas não permite que ele seja menor que 0
    let lugares = formData.lugares;
    console.log(lugares[lugares.length - 1].nome);
    if (formData.qtd_lugares > 1) {
      if (
        lugares[lugares.length - 1]?.nome === "" &&
        lugares[lugares.length - 1]?.documento === ""
      ) {
        lugares.pop();

        setFormData({
          ...formData,
          qtd_lugares: formData.qtd_lugares - 1,
        });
      } else {
        toast.error("A ultima linha contém dados.");
      }
    }
  };

  async function updateTable(nome, descricao, data, localizacao) {
    // cons saveTable = () => {}
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/event/create-event`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: user.email,
          token: user.token,
          nome,
          descricao,
          data,
          localizacao,
        }),
      });

      if (response.status !== 201) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao criar o evento";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      const eventData = {
        eventId: json.event_id, // Substitua por um valor real, se necessário
        nome: json.nome,
        descricao: json.descricao,
        data: json.data,
        localizacao: json.localizacao,
      };

      setLoading(false);
      toast.success("Evento criado com sucesso!");
      closeModal();
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error(error.msg || "Ops, algo deu errado ao criar o evento!");
    }
  }

  async function get() {
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/maps/get-maps`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
        },
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao buscar os mapas";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // json deve ser uma lista de eventos
      const maps = json.map((map) => ({
        mapId: map._id,
        nome: map.nome,
        tipo_locacao: map.tipo_locacao,
        created_at: map.created_at,
      }));

      // Use setEvents para salvar os eventos no estado do seu componente
      // setListMaps([{ value: "", nome: "Selecione uma localização" }, ...maps]);
      console.log(maps);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao buscar os eventos!"
      );
    }
  }

  return (
    <section className="modal-update-locacao">
      <header>
        <IoOptions color="rgb(50,50,50)" size={24} />
        <div className="view-number-locacao">
          <h3>Ajustes gerais</h3>
        </div>
        <button onClick={closeModal}>
          <AiOutlineClose color="rgb(50,50,50)" size={24} />
        </button>
      </header>

      <section className="modal-update-locacao-section-form">
        <form onSubmit={handleFormSubmit}>
          <div>
            <section>

            <article>
                <label>
                  <span style={{ minWidth: 100 }}>Nome do evento:</span>
                  <input
                    width={"100%"}
                    type="text"
                    name="comprador"
                    value={formData.comprador}
                    onChange={handleInputChange}
                    placeholder="Nome do comprador"
                  />
                </label>
              </article>

              <article>
                <label>
                  <span style={{ minWidth: 120 }}>Tipo de locação:</span>
                  <input
                    width={"100%"}
                    type="text"
                    name="comprador"
                    value={'Mesa'}
                    onChange={handleInputChange}
                    placeholder="Nome do comprador"
                  />
                </label>
              </article>


              <article>
                <label>
                  <span style={{ minWidth: 130 }}>Qtd. de Locações:</span>
                  <input
                    width={"100%"}
                    type="number"
                    name="valor_recebido"
                    value={formData.valor_recebido}
                    onChange={handleInputChange}
                    placeholder="0,00"
                  />
                </label>
              </article>
              
              <article>
                <label>
                  <span style={{ minWidth: 150 }}>Valor total locação:</span>
                  <input
                    type="number"
                    name="valor_total_locacao"
                    value={formData.valor_total_locacao}
                    onChange={handleInputChange}
                    placeholder="0,00"
                  />
                </label>
              </article>

              <article className="article-lista-lugares">
                <label>
                  <span>Lugares: </span>
                  <button type="button" onClick={decrement}>
                    <IoCaretDown className="icon-decrement-increment" />
                  </button>{" "}
                  <span>{formData.qtd_lugares}</span>
                  <button type="button" onClick={increment}>
                    <IoCaretUp className="icon-decrement-increment" />
                  </button>
                </label>
                {dataLocacao?.lugares.map((item, index) => {
                  return (
                    <div key={item?._id}>
                      <p>{index + 1}</p>
                      <input
                        type="text"
                        name={`lugares[${index}].nome`}
                        placeholder={`Nome `}
                        value={item?.nome}
                        onChange={(e) => handleInputChange(e, index, "nome")}
                      />
                      <input
                        type="text"
                        name={`lugares[${index}].documento`}
                        placeholder={`Documento ${index + 1}`}
                        value={item?.documento}
                        onChange={(e) =>
                          handleInputChange(e, index, "documento")
                        }
                      />
                    </div>
                  );
                })}
              </article>
            </section>

            <section>

            <article>
                <label>
                  <span style={{ minWidth: 120 }}>Descrição do evento:</span>
                  <input
                    width={"100%"}
                    type="text"
                    name="comprador"
                    value={formData.comprador}
                    onChange={handleInputChange}
                    placeholder="Nome do comprador"
                  />
                </label>
              </article>


              <article>
                <label>
                  <span style={{ minWidth: 200 }}>Qtd. de lugares por locação:</span>
                  <input
                    width={"100%"}
                    type="number"
                    name="valor_recebido"
                    value={formData.valor_recebido}
                    onChange={handleInputChange}
                    placeholder="0"
                  />
                </label>
              </article>
              
              <article>
                <label>
                  <span style={{ minWidth: 150 }}>Valor por lugar:</span>
                  <input
                    type="number"
                    name="valor_total_locacao"
                    value={formData.valor_total_locacao}
                    onChange={handleInputChange}
                    placeholder="0,00"
                  />
                </label>
              </article>



              <article>
                <label>
                  <span style={{ minWidth: 150 }}>Valor recebido:</span>
                  <input
                    width={"100%"}
                    type="number"
                    name="valor_recebido"
                    value={formData.valor_recebido}
                    onChange={handleInputChange}
                    placeholder="0,00"
                  />
                </label>
              </article>

              <article>
                <label style={{ alignItems: "start" }}>
                  <span>Anotações financeiras:</span>
                  <textarea
                    name="anotacoes_financeiras"
                    value={formData.anotacoes_financeiras}
                    onChange={handleInputChange}
                    placeholder="Digite as anotações financeiras aqui..."
                  />
                </label>
              </article>
            </section>
          </div>

          <button className="btn-action-save-modal" type="submit">
            Salvar
          </button>
        </form>

        <button onClick={() => teste()}>teste</button>
      </section>
    </section>
  );
}
