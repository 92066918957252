import React from "react";

const MapPreview = ({ formData, boxes, handleMouseDown, handleMouseMove, handleMouseUp }) => {
 
    let locationPreviewStyle = {
        width: `${formData.locacao_width_pixels}px`,
        height: `${formData.locacao_height_pixels}px`,
        backgroundColor: "rgba(255, 0, 0, 0.5)", // Cor de fundo com transparência
        position: "absolute",
        top: "0",
        left: "0",
      };
 
    return (
    <div
      style={{
        position: "relative",
        backgroundImage: `url(${formData.map_url})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: `${formData.img_width_pixels}px`,
        height: `${formData.img_height_pixels}px`,
      }}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      {boxes.map((box) => (
        <div
          key={box.id}
          onMouseDown={(e) => handleMouseDown(e, box.id)}
          style={{
            ...locationPreviewStyle,
            cursor: "pointer",
            top: `${box.y}px`,
            left: `${box.x}px`,
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center'
          }}
        >
              {box.number}
        </div>
      ))}
    </div>
  );
};

export default MapPreview;
