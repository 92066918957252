import React, { useState, useEffect, useContext } from "react";

import "./modalDeleteIngresso.css";
import { AuthContext } from "../../contexts/auth";

import { AiOutlineClose } from "react-icons/ai";
import {
  IoOptions,
} from "react-icons/io5";
import config from "../../config";
import { toast } from "react-toastify";



export default function ModalDeleteIngresso({
  closeModal,
  queueIdEdit,
  id_map,
  deleteLocalIngresso,
  _dataLocacao
}) {
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    
    id_map,
    id_ingresso: _dataLocacao?._id,

  });

  const handleFormSubmit = async (e) => {
    try {
      setLoading(true);

      const response = await fetch(
        `${config.urlServer}/event/delete-ingresso`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: user.email,
            token: user.token
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao atualizar usuário";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // json deve ser uma lista de eventos
      console.log(json);
      toast.success('Deletado da fila com sucesso!')
      deleteLocalIngresso(formData?.id_ingresso)
      closeModal();

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao deletar da fila!"
      );
    }

    e.preventDefault();
    console.log(formData);
  };

  async function handleSubmit(e) {
    e.preventDefault();

      handleFormSubmit(e);
  }

  if (!queueIdEdit) {
    <section className="modal-edit-user">
      <h3>Carregando...</h3>
    </section>
  }

  return (
    <section className="modal-edit-user">
      <header>
        <IoOptions color="rgb(50,50,50)" size={24} />
        <div className="view-number-locacao">
          <h3>Deletar ingresso</h3>
        </div>
        <button onClick={closeModal}>
          <AiOutlineClose color="rgb(50,50,50)" size={24} />
        </button>
      </header>

      <form className="form-edit-users" onSubmit={handleSubmit}>
        <span>Tem certeza que deseja deletar esse ingresso?</span>
        <span>Observe que esta ação não pode ser desfeita.</span>
        {_dataLocacao?._id}
          <button className="btn-action-delete-ingresso" type="submit">Deletar</button>
      
      </form>
    </section>
  );
}
