import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import config from "../../../../config";
import { saveAs } from "file-saver";
import { formatarNumero } from "../../../../functions/formatarNumero";
import { OverviewDetails } from "../OverviewDetails";

let data_ = require("../relatorio_vendas.json.json");
async function getRelatorioPorVendedor(user, event_id, vendedor) {
  try {
    const response = await fetch(
      `${config.urlServer}/event/relatorios/relatorio-por-vendedor`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
          eventid: event_id,
          vendedor: vendedor,
        }, 
      }
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      const errorMessage =
        responseData.msg || "Erro desconhecido ao buscar os eventos";
      throw new Error({ message: errorMessage, status: response.status });
    }

    console.log(responseData);

    let relatorio = `numero;status;vendedor;comprador;valor_total_locacao;valor_por_lugar;lugares;valor_recebido;forma_de_pagamento;\n`;

    if (responseData.locacoes) {
      for (let i = 0; i < responseData.locacoes.length; i++) {
        let element = responseData.locacoes[i];
        relatorio += `${element?.numero};${element?.status};${element?.vendedor};${element?.comprador};${element?.valor_total_locacao};${element?.valor_por_lugar};${element?.lugares.length};${element?.valor_recebido};${element?.forma_de_pagamento};\n`;
      }
    }
    // downloadJsonFile(responseData.locacoes, "relatorio_vendas.json");

    let locacoes = responseData.locacoes
    let ingressos_avulsos = responseData.ingressos_avulsos
    return {locacoes, ingressos_avulsos};
    // return responseData;

  } catch (error) {
    console.error(error);
    throw new Error(
      error.message || "Ops, algo deu errado ao gerar relatório!"
    );
  }
}

const downloadJsonFile = (content, name) => {
  const jsonBlob = new Blob([JSON.stringify(content)], {
    type: "application/json",
  });
  saveAs(jsonBlob, `${name}.json`);
};

export const _relatorioPorVendedor = async (
  user,
  boxes,
  detailsMap,
  event_id,
  vendedor
) => {
  console.log(vendedor);
};

export const relatorioPorVendedor = async (
  user,
  boxes,
  detailsMap,
  event_id,
  vendedor,
  vendedor_nome,
  nomeEvento
) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportTitle = [
    {
      text: `RELATÓRIO POR VENDEDOR: ${vendedor_nome}`,
      fontSize: 12,
      bold: true,
      margin: [15, 20, 0, 45], // left, top, right, bottom
    },
  ];

  const data = await getRelatorioPorVendedor(user, event_id, vendedor);
  // const data = data_;
  const overviewDetails = await OverviewDetails(data.locacoes, data.ingressos_avulsos);

  const dados = data?.locacoes?.map((item) => {
    const pagamento =
      parseFloat(item?.valor_total_locacao) -
        parseFloat(item?.valor_recebido) ===
        0 && parseFloat(item?.valor_total_locacao) !== 0
        ? "Pago"
        : parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) >
            0 &&
          parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) !==
            parseFloat(item?.valor_total_locacao)
        ? "Pago parcial"
        : parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) ===
          parseFloat(item?.valor_total_locacao)
        ? "Pendente"
        : parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) <
          0
        ? "Valor maior"
        : "Erro";
    return [
      { text: item?.numero, fontSize: 9, margin: [0, 1, 0, 1] },
      {
        text:
          item?.status === "vendido"
            ? "Vendido"
            : item?.status === "disponivel"
            ? "Disponível"
            : item?.status === "reservado"
            ? "Reservado"
            : "ERRO",
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },

      { text: item?.vendedor_nome, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: item?.comprador, fontSize: 9, margin: [0, 2, 0, 2] },
      {
        text:
          item?.forma_de_pagamento === "pix"
            ? "PIX"
            : item?.forma_de_pagamento === "dinheiro"
            ? "Dinheiro"
            : item?.forma_de_pagamento === "cheque"
            ? "Cheque"
            : item?.forma_de_pagamento === "outro"
            ? "Outro"
            : item?.forma_de_pagamento === ""
            ? ""
            : "Erro",
        fontSize: 9,
        margin: [0, 1, 0, 1],
      },

      
      {
        text: item.lugares.length,
        fontSize: 9,
        margin: [0, 1, 0, 1],
      },

      {
        text: formatarNumero(item?.valor_total_locacao),
        fontSize: 9,
        margin: [0, 1, 0, 1],
      },

      {
        text: formatarNumero(item?.valor_recebido),
        fontSize: 9,
        margin: [0, 1, 0, 1],
      },
      { text: pagamento, fontSize: 9, margin: [0, 1, 0, 1] },
    ];
  });


  const ingressos_avulsos = data?.ingressos_avulsos?.map((item) => {
    const pagamento =
      parseFloat(item?.valor_total_locacao) -
        parseFloat(item?.valor_recebido) ===
        0 && parseFloat(item?.valor_total_locacao) !== 0
        ? "Pago"
        : parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) >
            0 &&
          parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) !==
            parseFloat(item?.valor_total_locacao)
        ? "Pago parcial"
        : parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) ===
          parseFloat(item?.valor_total_locacao)
        ? "Pendente"
        : parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) <
          0
        ? "Valor maior"
        : "Erro";
    return [
      // { text: item?.numero, fontSize: 9, margin: [0, 1, 0, 1] },
      {
        text:
          item?.status === "vendido"
            ? "Vendido"
            : item?.status === "disponivel"
            ? "Disponível"
            : item?.status === "reservado"
            ? "Reservado"
            : "ERRO",
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },

      { text: item?.vendedor_nome, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: item?.comprador, fontSize: 9, margin: [0, 2, 0, 2] },
      {
        text:
          item?.forma_de_pagamento === "pix"
            ? "PIX"
            : item?.forma_de_pagamento === "dinheiro"
            ? "Dinheiro"
            : item?.forma_de_pagamento === "cheque"
            ? "Cheque"
            : item?.forma_de_pagamento === "outro"
            ? "Outro"
            : item?.forma_de_pagamento === ""
            ? ""
            : "Erro",
        fontSize: 9,
        margin: [0, 1, 0, 1],
      },

      {
        text: formatarNumero(item?.valor_total_locacao),
        fontSize: 9,
        margin: [0, 1, 0, 1],
      },

      {
        text: formatarNumero(item?.valor_recebido),
        fontSize: 9,
        margin: [0, 1, 0, 1],
      },
      { text: pagamento, fontSize: 9, margin: [0, 1, 0, 1] },
    ];
  });


  const details = [
    { text: "Locações/Mesas - Resumo:", style: "subheader" },
    {
      table: {
        headerRows: 1,
        widths: [120,110,110, 70, 70],
        body: [
          // [
          //   { text: "Descrição", colSpan: 4, fontSize: 10, bold: true  },
          // ],
          [
            {
              text: "Descrição",
              style: "tableHeader",
              fontSize: 9,
              bold: true,
            },
            {
              text: "Quantidade Locações",
              style: "tableHeader",
              fontSize: 9,
              bold: true,
            },
            {
              text: "Quantidade Lugares",
              style: "tableHeader",
              fontSize: 9,
              bold: true,
            },
            {
              text: "Porcentagem",
              style: "tableHeader",
              fontSize: 9,
              bold: true,
            },
            { text: "Valores", style: "tableHeader", fontSize: 9, bold: true },
          ],
          [
            {
              text: "Vendidos",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: overviewDetails?.locacoes_vendidas,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text: overviewDetails?.locacoes_lugares_vendidos,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text: parseFloat(overviewDetails?.percentual_vendido || 0 ).toFixed(2)+ "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: formatarNumero(overviewDetails?.valor_vendido),
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: "Reservados",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: overviewDetails?.locacoes_reservadas,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text: overviewDetails?.locacoes_lugares_rervados,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text: parseFloat(overviewDetails?.percentual_reservado || 0).toFixed(2) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: formatarNumero(overviewDetails?.valor_reservado),
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: "Disponível",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: overviewDetails?.locacoes__disponiveis,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text: overviewDetails?.locacoes_lugares_disponiveis,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text: parseFloat(overviewDetails?.percentual_disponivel || 0).toFixed(2) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: formatarNumero(overviewDetails?.valor_disponivel),
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: "TOTAL",
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
            {
              text: overviewDetails?.total_de_locacoes,
              fontSize: 7,
              margin: [0, 1, 0, 1],
              bold: true,
              fillColor: "#eeeeff",
            },
            {
              text: overviewDetails?.locacoes_lugares_totais,
              fontSize: 7,
              margin: [0, 1, 0, 1],
              bold: true,
              fillColor: "#eeeeff",
            },
            {
              text:
                parseFloat(overviewDetails?.percentual_total_de_locacoes || 0).toFixed(2) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
            {
              text: formatarNumero(overviewDetails?.valor_total_das_locacoes),
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
          ],
        ],
      },
      style: "tableExample",
      marginBottom: 5,

    },
    { text: "Locações/Mesas - Resumo dos recebimentos:", style: "subheader" },
    {
      table: {
        headerRows: 1,
        widths: [120,120, 70, 70],
        body: [
          // [
          //   { text: "Descrição", colSpan: 4, fontSize: 9, bold: true  },
          // ],
          [
            {
              text: "Descrição",
              style: "tableHeader",
              fontSize: 9,
              bold: true,
            },
            {
              text: "Quantidade",
              style: "tableHeader",
              fontSize: 9,
              bold: true,
            },
            {
              text: "Porcentagem",
              style: "tableHeader",
              fontSize: 9,
              bold: true,
            },
            { text: "Valores", style: "tableHeader", fontSize: 9, bold: true },
          ],
    
          [
            {
              text: "Recebido integral",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: overviewDetails?.vendido_recebebidos,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text:
                parseFloat(overviewDetails?.percentual_vendido_recebebidos || 0).toFixed(2) +
                "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: formatarNumero(overviewDetails?.valor_vendido_recebebidos),
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: "Recebido parcial",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: overviewDetails?.vendido_recebimentos_parcial,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text:
                parseFloat(overviewDetails?.percentual_vendido_recebimentos_parcial || 0).toFixed(
                  2
                ) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: formatarNumero(
                overviewDetails?.valor_vendido_recebimentos_parcial
              ),
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: "Nenhum valor recebido",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: overviewDetails?.vendido_recebimentos_pendente,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text:
                parseFloat(overviewDetails?.percentual_vendido_recebimentos_pendente ||0).toFixed(
                  2
                ) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: formatarNumero(
                overviewDetails?.valor_vendido_pendente_receber
              ),
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: "TOTALIZADOR DE VENDAS",
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
            {
              text: overviewDetails?.locacoes_vendidas,
              fontSize: 7,
              margin: [0, 1, 0, 1],
              bold: true,
              fillColor: "#eeeeff",
            },
            {
              text:
                ((
                  (overviewDetails?.locacoes_vendidas /
                    overviewDetails?.total_de_locacoes) *
                  100) || 0
                ).toFixed(2) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
            {
              text: formatarNumero(overviewDetails?.valor_vendido),
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
          ],
          [
            {
              text: "PENDENTE RECEBIMENTO",
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
              colSpan: 2,
            },
            {},
            {
              text:
                (
     (             ((overviewDetails?.valor_vendido -
                    (overviewDetails?.valor_vendido_recebimentos_parcial +
                      overviewDetails?.valor_vendido_recebebidos)) /
                    overviewDetails?.valor_vendido) *
                  100) || 0
                ).toFixed(2) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
            {
              text: formatarNumero(
                overviewDetails?.valor_vendido -
                  (overviewDetails?.valor_vendido_recebimentos_parcial +
                    overviewDetails?.valor_vendido_recebebidos)
              ),
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
          ],
          [
            {
              text: "TOTAL RECEBIDO",
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
              colSpan: 2,
            },
            {},
            {
              text:
                (
    (              ((overviewDetails?.valor_vendido_recebimentos_parcial +
                    overviewDetails?.valor_vendido_recebebidos) /
                    overviewDetails?.valor_vendido) *
                  100) || 0
                ).toFixed(2) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
            {
              text: formatarNumero(
                overviewDetails?.valor_vendido_recebimentos_parcial +
                  overviewDetails?.valor_vendido_recebebidos
              ),
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
          ],
        ],
      },
      style: "tableExample",
      marginBottom: 5,
      // layout: "lightHorizontalLines", // headerLineOnly
      // layout: {
      //   fillColor: function (rowIndex, node, columnIndex) {
      //     return rowIndex % 2 === 0 ? "#ddd" : null;
      //   },
      // },
    },
    { text: "Locações/Mesas - Meios de recebimentos:", style: "subheader" },
    {
      table: {
        headerRows: 1,
        widths: [120,120, 70, 70],
        body: [
          
          [
            {
              text: "Descrição",
              style: "tableHeader",
              fontSize: 9,
              bold: true,
            },
            {
              text: "Quantidade",
              style: "tableHeader",
              fontSize: 9,
              bold: true,
            },
            {
              text: "Porcentagem",
              style: "tableHeader",
              fontSize: 9,
              bold: true,
            },
            { text: "Valores", style: "tableHeader", fontSize: 9, bold: true },
          ],
          [
            {
              text: "Dinheiro",
              fontSize: 7,
              margin: [0, 2, 0, 2]
            },
            {
              text: overviewDetails?.qtd_recebido_por_dinheiro,
              fontSize: 7,
              margin: [0, 1, 0, 1]
            },
            {
              text:
                (
                  parseFloat(overviewDetails?.percentual_recebido_por_dinheiro || 0).toFixed(2)
                ) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2]
            },
            {
              text: formatarNumero(overviewDetails?.valor_recebido_por_dinheiro),
              fontSize: 7,
              margin: [0, 2, 0, 2]
            },
          ],
          [
            {
              text: "Pix",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: overviewDetails?.qtd_recebido_por_pix,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text:
                parseFloat(overviewDetails?.percentual_recebido_por_pix || 0).toFixed(2) +
                "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: formatarNumero(overviewDetails?.valor_recebido_por_pix),
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: "Cheque",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: overviewDetails?.qtd_recebido_por_cheque,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text:
                parseFloat(overviewDetails?.percentual_recebido_por_cheque || 0).toFixed(
                  2
                ) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: formatarNumero(
                overviewDetails?.valor_recebido_por_cheque
              ),
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: "Outro",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: overviewDetails?.qtd_recebido_por_outro,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text:
                parseFloat(overviewDetails?.percentual_recebido_por_outro || 0).toFixed(
                  2
                ) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: formatarNumero(
                overviewDetails?.valor_recebido_por_outro
              ),
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: "Não informado",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: overviewDetails?.qtd_recebido_por_meio_nao_informado,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text:
                parseFloat(overviewDetails?.percentual_recebido_por_meio_nao_informado  || 0) .toFixed(
                  2
                ) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: formatarNumero(
                overviewDetails?.valor_recebido_por_meio_nao_informado
              ),
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: "TOTALIZADOR DOS MEIOS DE RECEBIMENTO",
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
              colSpan: 2,
            },
            {},
            {
              text:
               parseFloat(
                  
                  (overviewDetails?.percentual_recebido_por_dinheiro  || 0 )+
                  (overviewDetails?.percentual_recebido_por_pix || 0 )+
                  (overviewDetails?.percentual_recebido_por_cheque || 0) + 
                  (overviewDetails?.percentual_recebido_por_outro || 0  )+
                  (overviewDetails?.percentual_recebido_por_meio_nao_informado || 0)  

                ).toFixed(2)  + "%" ,

            //   text: `${overviewDetails?.percentual_recebido_por_dinheiro  || 0 }+
            //  ${overviewDetails?.percentual_recebido_por_pix || 0} +
            //   ${overviewDetails?.percentual_recebido_por_cheque || 0} + 
            //   ${overviewDetails?.percentual_recebido_por_outro || 0}  +
            //   ${overviewDetails?.percentual_recebido_por_meio_nao_informado || 0} `,
              fontSize: 9,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
            {
              text: formatarNumero(
                overviewDetails?.valor_vendido_recebimentos_parcial +
                  overviewDetails?.valor_vendido_recebebidos
              ),
              fontSize: 9,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
          ],
        ],
      },
      style: "tableExample",
      marginBottom: 5
    },

    { text: "--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------"
  ,
    fontSize: 4,
    margin: [0, 0, 0, 0], // left, top, right, bottom
  },
    //
    { text: "Ingressos Avulsos - Resumo:", style: "subheader" },
    {
      table: {
        headerRows: 1,
        widths: [120,120, 70, 70],
        body: [
          // [
          //   { text: "Descrição", colSpan: 4, fontSize: 10, bold: true  },
          // ],
          [
            {
              text: "Descrição",
              style: "tableHeader",
              fontSize: 9,
              bold: true,
            },
            {
              text: "Quantidade",
              style: "tableHeader",
              fontSize: 9,
              bold: true,
            },
            {
              text: "Porcentagem",
              style: "tableHeader",
              fontSize: 9,
              bold: true,
            },
            { text: "Valores", style: "tableHeader", fontSize: 9, bold: true },
          ],
          [
            {
              text: "Vendidos",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: overviewDetails?.ingressos_avulsos_vendidos,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text: (overviewDetails?.ingressos_avulsos_percentual_vendido || 0).toFixed(2) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: formatarNumero(overviewDetails?.ingressos_avulsos_valor_vendido),
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: "Reservados",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: overviewDetails?.ingressos_avulsos_reservados,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text: (overviewDetails?.ingressos_avulsos_percentual_reservado || 0).toFixed(2) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: formatarNumero(overviewDetails?.ingressos_avulsos_valor_reservado),
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: "Disponível",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: overviewDetails?.ingressos_avulsos_disponiveis,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text: (overviewDetails?.ingressos_avulsos_percentual_disponivel || 0).toFixed(2) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: formatarNumero(overviewDetails?.ingressos_avulsos_valor_disponivel),
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: "TOTAL",
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
            {
              text: overviewDetails?.total_de_ingressos_avulsos,
              fontSize: 7,
              margin: [0, 1, 0, 1],
              bold: true,
              fillColor: "#eeeeff",
            },
            {
              text:
                (overviewDetails?.percentual_total_de_ingressos_avulsos || 0).toFixed(2) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
            {
              text: formatarNumero(overviewDetails?.valor_total_de_ingressos_avulsos),
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
          ],
        ],
      },
      style: "tableExample",
      marginBottom: 5,

    },
    { text: "Ingressos Avulsos - Resumo dos recebimentos:", style: "subheader" },
    {
      table: {
        headerRows: 1,
        widths: [120,120, 70, 70],
        body: [
          // [
          //   { text: "Descrição", colSpan: 4, fontSize: 9, bold: true  },
          // ],
          [
            {
              text: "Descrição",
              style: "tableHeader",
              fontSize: 9,
              bold: true,
            },
            {
              text: "Quantidade",
              style: "tableHeader",
              fontSize: 9,
              bold: true,
            },
            {
              text: "Porcentagem",
              style: "tableHeader",
              fontSize: 9,
              bold: true,
            },
            { text: "Valores", style: "tableHeader", fontSize: 9, bold: true },
          ],
    
          [
            {
              text: "Recebido integral",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: overviewDetails?.ingressos_avulsos_vendido_recebebidos,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text:
                (overviewDetails?.ingressos_avulsos_percentual_vendido_recebebidos ||0).toFixed(2) +
                "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: formatarNumero(overviewDetails?.ingressos_avulsos_valor_vendido_recebebidos),
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: "Recebido parcial",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: overviewDetails?.ingressos_avulsos_valor_vendido_recebimentos_parcial,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text:
                (overviewDetails?.ingressos_avulsos_percentual_vendido_recebimentos_parcial || 0).toFixed(
                  2
                ) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: formatarNumero(
                overviewDetails?.ingressos_avulsos_valor_vendido_recebimentos_parcial
              ),
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: "Nenhum valor recebido",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: overviewDetails?.ingressos_avulsos_vendido_recebimentos_pendente,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text:
                (overviewDetails?.ingressos_avulsos_percentual_vendido_recebimentos_pendente || 0).toFixed(
                  2
                ) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: formatarNumero(
                overviewDetails?.ingressos_avulsos_valor_vendido_pendente_receber
              ),
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: "TOTALIZADOR DE VENDAS",
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
            {
              text: overviewDetails?.ingressos_avulsos_vendidos,
              fontSize: 7,
              margin: [0, 1, 0, 1],
              bold: true,
              fillColor: "#eeeeff",
            },
            {
              text:
                (
                  (overviewDetails?.ingressos_avulsos_vendidos /
                    overviewDetails?.total_de_ingressos_avulsos) *
                  100
                ).toFixed(2) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
            {
              text: formatarNumero(overviewDetails?.ingressos_avulsos_valor_vendido),
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
          ],
          [
            {
              text: "PENDENTE RECEBIMENTO",
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
              colSpan: 2,
            },
            {},
            {
              text:
                (
                  ((overviewDetails?.ingressos_avulsos_valor_vendido -
                    (overviewDetails?.ingressos_avulsos_valor_vendido_recebimentos_parcial +
                      overviewDetails?.ingressos_avulsos_valor_vendido_recebebidos)) /
                    overviewDetails?.ingressos_avulsos_valor_vendido) *
                  100
                ).toFixed(2) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
            {
              text: formatarNumero(
                overviewDetails?.ingressos_avulsos_valor_vendido -
                  (overviewDetails?.ingressos_avulsos_valor_vendido_recebimentos_parcial +
                    overviewDetails?.ingressos_avulsos_valor_vendido_recebebidos)
              ),
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
          ],
          [
            {
              text: "TOTAL RECEBIDO",
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
              colSpan: 2,
            },
            {},
            {
              text:
                (
                  ((overviewDetails?.ingressos_avulsos_valor_vendido_recebimentos_parcial +
                    overviewDetails?.ingressos_avulsos_valor_vendido_recebebidos) /
                    overviewDetails?.ingressos_avulsos_valor_vendido) *
                  100
                ).toFixed(2) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
            {
              text: formatarNumero(
                overviewDetails?.ingressos_avulsos_valor_vendido_recebimentos_parcial +
                  overviewDetails?.ingressos_avulsos_valor_vendido_recebebidos
              ),
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
          ],
        ],
      },
      style: "tableExample",
      marginBottom: 5,
      // layout: "lightHorizontalLines", // headerLineOnly
      // layout: {
      //   fillColor: function (rowIndex, node, columnIndex) {
      //     return rowIndex % 2 === 0 ? "#ddd" : null;
      //   },
      // },
    },
    { text: "Ingressos Avulsos - Meios de recebimentos:", style: "subheader" },
    {
      table: {
        headerRows: 1,
        widths: [120,120, 70, 70],
        body: [
          
          [
            {
              text: "Descrição",
              style: "tableHeader",
              fontSize: 9,
              bold: true,
            },
            {
              text: "Quantidade",
              style: "tableHeader",
              fontSize: 9,
              bold: true,
            },
            {
              text: "Porcentagem",
              style: "tableHeader",
              fontSize: 9,
              bold: true,
            },
            { text: "Valores", style: "tableHeader", fontSize: 9, bold: true },
          ],
          [
            {
              text: "Dinheiro",
              fontSize: 7,
              margin: [0, 2, 0, 2]
            },
            {
              text: overviewDetails?.ingressos_avulsos_qtd_recebido_por_dinheiro,
              fontSize: 7,
              margin: [0, 1, 0, 1]
            },
            {
              text:
                (
                  parseFloat(overviewDetails?.ingressos_avulsos_percentual_recebido_por_dinheiro || 0).toFixed(2)
                ) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2]
            },
            {
              text: formatarNumero(overviewDetails?.ingressos_avulsos_valor_recebido_por_dinheiro),
              fontSize: 7,
              margin: [0, 2, 0, 2]
            },
          ],
          [
            {
              text: "Pix",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: overviewDetails?.ingressos_avulsos_qtd_recebido_por_pix,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text:
                parseFloat(overviewDetails?.ingressos_avulsos_percentual_recebido_por_pix || 0).toFixed(2) +
                "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: formatarNumero(overviewDetails?.ingressos_avulsos_valor_recebido_por_pix),
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: "Cheque",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: overviewDetails?.ingressos_avulsos_qtd_recebido_por_cheque,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text:
                parseFloat(overviewDetails?.ingressos_avulsos_percentual_recebido_por_cheque || 0).toFixed(
                  2
                ) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: formatarNumero(
                overviewDetails?.ingressos_avulsos_valor_recebido_por_cheque
              ),
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: "Outro",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: overviewDetails?.ingressos_avulsos_qtd_recebido_por_outro,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text:
                parseFloat(overviewDetails?.ingressos_avulsos_percentual_recebido_por_outro || 0).toFixed(
                  2
                ) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: formatarNumero(
                overviewDetails?.ingressos_avulsos_valor_recebido_por_outro
              ),
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: "Não informado",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: overviewDetails?.ingressos_avulsos_qtd_recebido_por_meio_nao_informado,
              fontSize: 7,
              margin: [0, 1, 0, 1],
            },
            {
              text:
                parseFloat(overviewDetails?.ingressos_avulsos_percentual_recebido_por_meio_nao_informado  || 0) .toFixed(
                  2
                ) + "%",
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
            {
              text: formatarNumero(
                overviewDetails?.ingressos_avulsos_valor_recebido_por_meio_nao_informado
              ),
              fontSize: 7,
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: "TOTALIZADOR DOS MEIOS DE RECEBIMENTO",
              fontSize: 7,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
              colSpan: 2,
            },
            {},
            {
              text:
               parseFloat(
                  
                  (overviewDetails?.ingressos_avulsos_percentual_recebido_por_dinheiro  || 0 )+
                  (overviewDetails?.ingressos_avulsos_percentual_recebido_por_pix || 0 )+
                  (overviewDetails?.ingressos_avulsos_percentual_recebido_por_cheque || 0) + 
                  (overviewDetails?.ingressos_avulsos_percentual_recebido_por_outro || 0  )+
                  (overviewDetails?.ingressos_avulsos_percentual_recebido_por_meio_nao_informado || 0)  

                ).toFixed(2)  + "%" ,

            //   text: `${overviewDetails?.percentual_recebido_por_dinheiro  || 0 }+
            //  ${overviewDetails?.percentual_recebido_por_pix || 0} +
            //   ${overviewDetails?.percentual_recebido_por_cheque || 0} + 
            //   ${overviewDetails?.percentual_recebido_por_outro || 0}  +
            //   ${overviewDetails?.percentual_recebido_por_meio_nao_informado || 0} `,
              fontSize: 9,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
            {
              text: formatarNumero(
                overviewDetails?.ingressos_avulsos_valor_vendido_recebimentos_parcial +
                  overviewDetails?.ingressos_avulsos_valor_vendido_recebebidos
              ),
              fontSize: 9,
              margin: [0, 2, 0, 2],
              bold: true,
              fillColor: "#eeeeff",
            },
          ],
        ],
      },
      style: "tableExample",
      marginBottom: 5
    },

    //
    { text: "", pageBreak: "before" },
    { text: "Locações/Mesas", style: "subheader" },
    {
      table: {
        headerRows: 1,
        widths: [18,45, 98, 98, 52, 15,50, 50, 65],
        body: [
          [
            { text: "MESA", style: "tableHeader", fontSize: 10,  bold: true },//1
            { text: "STATUS", style: "tableHeader", fontSize: 10,  bold: true },//2
            { text: "VENDEDOR", style: "tableHeader", fontSize: 10 ,  bold: true},//3
            { text: "COMPRADOR", style: "tableHeader", fontSize: 10,  bold: true },//4
            { text: "FORMA DE PGTO", style: "tableHeader", fontSize: 10,  bold: true },//5
            { text: "LUGARES", style: "tableHeader", fontSize: 10,  bold: true },//5
            { text: "VALOR TOTAL", style: "tableHeader", fontSize: 10,  bold: true },//6
            { text: "VALOR RECEBIDO", style: "tableHeader", fontSize: 10,  bold: true },//7
            { text: "PAGAMENTO", style: "tableHeader", fontSize: 10,  bold: true },//8
          ],
          ...dados,
        ],
      },
      //   layout: "lightHorizontalLines", // headerLineOnly
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex % 2 === 0 ? "#ddd" : null;
        },
      },
    },

    { text: "", pageBreak: "before" },
    //ingressos_avulsos
    { text: "Ingressos Avulsos", style: "subheader" },
    {
      table: {
        headerRows: 1,
        widths: [45, 98, 98, 52, 50, 50, 70],
        body: [
          [
            // { text: "MESA", style: "tableHeader", fontSize: 10,  bold: true },
            { text: "STATUS", style: "tableHeader", fontSize: 10,  bold: true },
            { text: "VENDEDOR", style: "tableHeader", fontSize: 10 ,  bold: true},
            { text: "COMPRADOR", style: "tableHeader", fontSize: 10,  bold: true },
            { text: "FORMA DE PGTO", style: "tableHeader", fontSize: 10,  bold: true },
            { text: "VALOR TOTAL", style: "tableHeader", fontSize: 10,  bold: true },
            { text: "VALOR RECEBIDO", style: "tableHeader", fontSize: 10,  bold: true },
            { text: "PAGAMENTO", style: "tableHeader", fontSize: 10,  bold: true },
          ],
          ...ingressos_avulsos,
        ],
      }
      ,
      //   layout: "lightHorizontalLines", // headerLineOnly
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex % 2 === 0 ? "#ddd" : null;
        },
      },
    },
    //
  ];




  function Rodape(currentPage, pageCount) {
    return [
      {
        text: currentPage + " / " + pageCount,
        alignment: "right",
        fontSize: 9,
        margin: [0, 10, 20, 0], // left, top, right, bottom
      },
    ];
  }

  const docDefinitios = {
    pageSize: "A4",
    pageMargins: [10, 35, 35, 10],

    header: [reportTitle],
    content: [details],
    footer: Rodape,
  };

  // pdfMake.createPdf(docDefinitios).download();
    // Crie o PDF
const pdfDocGenerator = pdfMake.createPdf(docDefinitios);
  
// Gere um Blob a partir do PDF
pdfDocGenerator.getBlob((blob) => {
  // Crie um link temporário
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);

  // Defina o atributo "download" com o nome desejado do arquivo

  link.download = `Relatório por vendedor: ${vendedor_nome} - ${nomeEvento || ''}.pdf`;

  // Adicione o link ao corpo da página e clique automaticamente nele
  document.body.appendChild(link);
  link.click();

  // Remova o link depois do download
  document.body.removeChild(link);
});
};
