import React, { useState, useEffect, useContext } from "react";

import "./editEvent.css";
import { AuthContext } from "../../../contexts/auth";

import config from "../../../config";
import { toast } from "react-toastify";
import Modal from "react-modal";
import ModalDeleteEvent from "../../../components/ModalDeleteEvent";
import { useNavigate } from "react-router-dom";

export default function EditEvent({ event, dataLocacao, event_id, getEvent }) {
  const [formData, setFormData] = useState({
    nome_evento: event?.nome,
    descricao_evento: event?.descricao,
    tipo_locacao: event?.mapa_mesas?.tipo_locacao,
    qtd_locacoes: event?.mapa_mesas?.locacoes?.length,
    qtd_lugares_por_locacao: event?.mapa_mesas?.lugares_por_locacao,
    valor_por_lugar: event?.mapa_mesas?.valor_por_lugar,
    anotacoes_evento: event?.anotacoes_evento || "",
    vendedores: event?.vendedores || [],
  });
  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // getListMaps();
    getUsers();
    console.log(formData);
    // setFormData({ ...formData, [name]: value });
    return () => ({});
  }, [dataLocacao]);

  
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "90%", // Defina a largura desejada
      maxWidth: "900px", // Defina uma largura máxima opcional
      height: "auto", // Defina a altura desejada (exemplo: 60% da altura da viewport)
      minHeight: "70vh",
      maxHeight: "90vh", // Defina uma altura máxima opcional
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.8)", // Cor de fundo do overlay (fundo escuro semitransparente)
      zIndex: 1000, // Ajusta a ordem de empilhamento para garantir que o modal esteja acima do conteúdo
    },
  };


  async function getUsers() {
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/users/get-users`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
        },
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao buscar usuários";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // json deve ser uma lista de eventos
      const users_result = json.map((result) => ({
        id: result?._id,
        nome: result?.name,
        email: result?.email,
      }));

      // Use setEvents para salvar os eventos no estado do seu componente
      setUsers(users_result);
      // console.log(users_result);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao buscar os usuarios!"
      );
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    editEvent();
    // console.log(formData)
    // if (user.email && user.token) {
    //   if (
    //     !formData.nome ||
    //     !formData.descricao ||
    //     !formData.localizacao ||
    //     !formData.data
    //   ) {
    //     toast.error("Preencha todos os campos!");
    //     return;
    //   }
    //   const { nome, descricao, data, localizacao } = formData;
    //   ModalUpdateTable(nome, descricao, data, localizacao);
    // } else {
    //   console.log(user);
    //   console.log("Dados do formulário:", formData);
    // }
  };

  async function editEvent() {
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/event/edit-event`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
          eventid: event_id,
        },
        body: JSON.stringify(formData),
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao atualizar o evento";
        throw new Error(errorMessage);
      }

      const json = await response?.json();

      //   const eventData = {
      //     eventId: json.event_id, // Substitua por um valor real, se necessário
      //     nome: json.nome,
      //     descricao: json.descricao,
      //     data: json.data,
      //     localizacao: json.localizacao,
      //   };
      getEvent();
      console.log(json);
      setLoading(false);
      toast.success("Evento editado com sucesso!");
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error(error.msg || "Ops, algo deu errado ao editar o evento!");
    }
  }

  const handleInputChange = (e, index, fieldName) => {
    const { value, name } = e.target;

    if (name === "vendedores") {
      const selectedOptions = Array.from(
        e.target.selectedOptions,
        (option) => ({
          id: option.dataset.id,
          nome: option.dataset.nome,
          email: option.value,
        })
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: selectedOptions,
      }));
    } else if (name.includes("lugares")) {
      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData };
        updatedFormData.lugares[index][fieldName] = value;
        return updatedFormData;
      });
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }
  };


  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);

  const [eventDelete, setEventDelete] = useState('')
  const openModalDelete = (idUserDelete) => {
    setModalDeleteIsOpen(true);
  };

  const closeModalDelete = () => {
    setModalDeleteIsOpen(false);

      navigate("/home");

  };

  return (
    <section className="tab-edit-event">
      <section className="edit-event-locacao-section-form">
        <form onSubmit={handleFormSubmit}>
          <article>
            <label>
              <span>Nome do evento:</span>
              <input
                width={"100%"}
                type="text"
                name="nome_evento"
                value={formData?.nome_evento}
                onChange={handleInputChange}
                placeholder="Nome do Evento"
              />
            </label>
          </article>

          <article>
            <label>
              <span style={{ minWidth: 120 }}>Descrição do evento:</span>
              <input
                width={"100%"}
                type="text"
                name="descricao_evento"
                value={formData?.descricao_evento}
                onChange={handleInputChange}
                placeholder="Descrição do evento"
              />
            </label>
          </article>

          <article>
            <label>
              <span>Tipo de locação:</span>
              <input
                width={"100%"}
                type="text"
                name="tipo_locacao"
                value={formData?.tipo_locacao}
                style={{ textTransform: "capitalize", cursor: "not-allowed" }}
                readOnly
                placeholder="Tipo da locação"
              />
            </label>
          </article>

          <article>
            <label>
              <span>Qtd. de Locações:</span>
              <input
                width={"100%"}
                type="number"
                name="qtd_locacoes"
                style={{ cursor: "not-allowed" }}
                value={formData.qtd_locacoes}
                readOnly
              />
            </label>
          </article>

          <article>
            <label>
              <span style={{ minWidth: 200 }}>
                Qtd. de lugares por locação:
              </span>
              <input
                width={"100%"}
                type="number"
                name="qtd_lugares_por_locacao"
                style={{ cursor: "not-allowed" }}
                value={formData?.qtd_lugares_por_locacao}
              />
            </label>
          </article>

          <article>
            <label>
              <span style={{ minWidth: 150 }}>Valor por lugar:</span>
              <input
                type="number"
                name="valor_por_lugar"
                value={formData.valor_por_lugar}
                onChange={handleInputChange}
                placeholder="0,00"
              />
            </label>
          </article>

          <article>
            <label>
              <span style={{ minWidth: 150 }}>Valor total locação:</span>
              <input
                type="number"
                name="valor_total_locacao"
                value={
                  parseFloat(formData?.valor_por_lugar) *
                  parseFloat(formData?.qtd_lugares_por_locacao)
                }
                style={{ cursor: "not-allowed" }}
                readOnly
              />
            </label>
          </article>

          <article style={{ minWidth: 750 }}>
            <label style={{ alignItems: "start" }}>
              <span>Anotações gerais do evento:</span>
              <textarea
                name="anotacoes_evento"
                value={formData.anotacoes_evento}
                onChange={handleInputChange}
                placeholder="Digite as anotações gerais..."
              />
            </label>
          </article>
          <article style={{ minWidth: 750 }}>
            <label style={{ alignItems: "start" }}>
              <span>Selecione os vendedores:</span>

              <select
                id="vendedores"
                name="vendedores"
                multiple
                value={formData?.vendedores.map((vendedor) => vendedor.email)}
                onChange={handleInputChange}
              >
                {users?.map((item, index) => (
                  <option
                    style={{
                      borderBottom: "1px solid var(--black)",
                      marginBottom: 5,
                      padding: 5,
                    }}
                    key={item?.email}
                    value={item?.email}
                    data-id={item?.id}
                    data-nome={item?.nome}
                  >
                    {index + 1} - {item?.nome} - {item?.email}
                  </option>
                ))}
              </select>

            </label>
          </article>
          <button className="btn-action-save-modal" type="submit">
            Salvar
          </button>
        </form>

        <button className="delete-event"
          onClick={()=>openModalDelete()}
        >
          Deletar evento
        </button>

        <Modal
          isOpen={modalDeleteIsOpen}
          onRequestClose={closeModalDelete}
          style={customStyles}
        >
          <ModalDeleteEvent
            closeModalDelete={closeModalDelete}
            event_id={event_id}
            nome_evento={event?.nome}

          />
        </Modal>


      </section>
    </section>
  );
}
