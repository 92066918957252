import { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { format, parseISO} from "date-fns";

import { FiSettings, FiUpload } from "react-icons/fi";
import avatar from "../../assets/avatar.png";
import { AuthContext } from "../../contexts/auth";

import { db, storage } from "../../services/firebaseConnection";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import config from "../../config";

import { LiaUserEditSolid } from "react-icons/lia";
import { MdOutlineDelete, MdBlockFlipped  } from "react-icons/md";
import { ImBlocked } from "react-icons/im";

import Modal from "react-modal";

import { toast } from "react-toastify";

import "./users.css";
import ModalEditUser from "../../components/ModalEditUser";
import ModalDeleteUser from "../../components/ModalDeleteUser";
import ModalBlockedUser from "../../components/ModalBlockedUser";

export default function Users() {
  const { user, storageUser, setUser, logout } = useContext(AuthContext);

  const [avatarUrl, setAvatarUrl] = useState(user && user.avatarUrl);
  const [imageAvatar, setImageAvatar] = useState(null);

  const [users, setUsers] = useState(null);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
  const [modalBlockedIsOpen, setModalBlockedIsOpen] = useState(false);

  
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    user_email: '',
    password: '',
    confirmPassword: '',
  });

  const [userEdit, setUserEdit] = useState(null);

  const openModalEdit = (idUserEdit) => {
    setUserEdit(idUserEdit);
    setModalEditIsOpen(true);
  };

  const closeModalEdit = () => {
    setModalEditIsOpen(false);
    getUsers();
    // setUserEdit(null)
  };

  const openModalDelete = (idUserDelete) => {
    setUserEdit(idUserDelete);
    setModalDeleteIsOpen(true);
  };

  const closeModalDelete = () => {
    setModalDeleteIsOpen(false);
    // setUserEdit(null)
    getUsers();

  };

  const openModalBlocked = (idUserDelete) => {
    setUserEdit(idUserDelete);
    setModalBlockedIsOpen(true);
  };

  const closeModalBlocked = () => {
    setModalBlockedIsOpen(false);
    // setUserEdit(null)
    getUsers();

  };


  

  const handleInputChange = (e, index, fieldName) => {
    const { value, name } = e.target;

    const updatedFormData = { ...formData };

    updatedFormData[name] = value;

    setFormData(updatedFormData);
  };

  useEffect(() => {
    getUsers();
    return () => ({});
  }, []);

  async function getUsers() {
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/users/get-users`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
        },
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao buscar usuários";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // json deve ser uma lista de eventos
      const users_result = json.map((result) => ({
        id: result?._id,
        nome: result?.name,
        email: result?.email,
        admin: result?.admin,
        created_at: result?.created_at,
        lastLogin: result?.lastLogin,
        userBlocked: result?.userBlocked || false


      }));

      // Use setEvents para salvar os eventos no estado do seu componente
      setUsers(users_result);
      console.log(users_result);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao buscar os usuarios!"
      );
    }
  }
  // async function handleSubmit(e) {
  //   e.preventDefault();

  //   if (imageAvatar === null && name !== "") {
  //     // Atualizar apenas o name do user
  //     const docRef = doc(db, "users", user.uid);
  //     await updateDoc(docRef, {
  //       name: name,
  //     }).then(() => {
  //       let data = {
  //         ...user,
  //         name: name,
  //       };

  //       setUser(data);
  //       storageUser(data);
  //       toast.success("Atualizado com sucesso!");
  //     });
  //   } else if (name !== "" && imageAvatar !== null) {
  //     // Atualizar tanto name quanto a foto
  //     // handleUpload();
  //   }
  // }

  async function handleSubmit(e) {
    e.preventDefault();
    if (
      !senhaContemLetra(formData?.password) ||
      !senhaContemNumero(formData?.password) ||
      !senhaPossuiMinimoCaracteres(formData?.password)
    ) {
      toast.error("Verifique a senha, ela não atende os requisitos mínimos.");
    } else if (formData?.confirmPassword !== formData?.password) {
      toast.error("Confirmação de senha inválida!");
    } else {
      handleFormSubmit(e);
    }
    // console.log(formData)
  }

  const handleFormSubmit = async (e) => {
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/users/create-user`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
          iduser: userEdit,
        },
        body: JSON.stringify(formData),
      });

      if (response.status !== 201) {
        const errorData = await response.json();
        toast.error("Erro ao criar usuário!")
        const errorMessage =
          errorData.msg || "Erro desconhecido ao criar usuário";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // json deve ser uma lista de eventos
      console.log(json);
      toast.success("Usuario criado com sucesso!")
      resetForm()
      getUsers()
      // Use setEvents para salvar os eventos no estado do seu componente
      // setFormData({...formData, ...json});
      //   console.log(user_result);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao criar usuário!"
      );
    }

    e.preventDefault();
    console.log(formData);
  };

  const resetForm = () => {
    setFormData({
      name: '',
      user_email: '',
      password: '',
      confirmPassword: '',
    });
  };
  
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "90%", // Defina a largura desejada
      maxWidth: "900px", // Defina uma largura máxima opcional
      height: "auto", // Defina a altura desejada (exemplo: 60% da altura da viewport)
      minHeight: "70vh",
      maxHeight: "90vh", // Defina uma altura máxima opcional
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.8)", // Cor de fundo do overlay (fundo escuro semitransparente)
      zIndex: 1000, // Ajusta a ordem de empilhamento para garantir que o modal esteja acima do conteúdo
    },
  };

  function senhaPossuiMinimoCaracteres(senha) {
    return senha?.length >= 6;
  }

  function senhaContemNumero(senha) {
    // A expressão regular \d corresponde a qualquer dígito (0-9).
    return /\d/.test(senha);
  }

  function senhaContemLetra(senha) {
    // A expressão regular [a-zA-Z] corresponde a qualquer letra maiúscula ou minúscula.
    return /[a-zA-Z]/.test(senha);
  }

  return (
    <div>
      <Header />

      <div className="content">
        <Title name="Usuários">
          <FiSettings size={25} />
        </Title>

        <div className="container">
          <form className="form-create-users" onSubmit={handleSubmit}>
            <label>
              Nome
              <input
                type="text"
                name="name"
                required
                placeholder="Nome do usuário"
                value={formData?.name || ''}
                // onChange={(e) => setName(e.target.value)}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Email
              <input
                type="text"
                name="user_email"
                required
                placeholder="email@dominio.com"
                value={formData?.user_email || ''}
                onChange={handleInputChange}
              />
            </label>

            <label>
              Senha
              <input
                type="text"
                name="password"
                required
                placeholder="******"
                value={formData?.password || ''}
                onChange={handleInputChange}
              />
              {formData?.password &&
                (!senhaContemLetra(formData?.password) ||
                  !senhaContemNumero(formData?.password) ||
                  !senhaPossuiMinimoCaracteres(formData?.password)) && (
                  <div className="required-password">
                    <span>A senha deve: </span>
                    <span
                      style={{
                        color: senhaPossuiMinimoCaracteres(formData?.password)
                          ? "var(--grass)"
                          : "var(--cardinal)",
                      }}
                    >
                      - conter no mínimo de 6 carcteres.
                    </span>
                    <span
                      style={{
                        color: senhaContemNumero(formData?.password)
                          ? "var(--grass)"
                          : "var(--cardinal)",
                      }}
                    >
                      - conter no mínimo de 1 número.
                    </span>
                    <span
                      style={{
                        color: senhaContemLetra(formData?.password)
                          ? "var(--grass)"
                          : "var(--cardinal)",
                      }}
                    >
                      - conter no mínimo de 1 letra.
                    </span>
                  </div>
                )}
            </label>
            <label>
              Confirme a senha
              <input
                type="text"
                name="confirmPassword"
                required
                placeholder="******"
                value={formData?.confirmPassword || ''}
                onChange={handleInputChange}
              />
              {formData?.confirmPassword !== formData?.password && (
                <div className="required-password">
                  <span style={{ color: "var(--cardinal)" }}>
                    Confirmação de senha inválida!
                  </span>
                </div>
              )}
            </label>
            <button type="submit">Adicionar usuário</button>
          </form>
        </div>

        <table className="table-list-users">
          <thead>
            <tr>
              <th colSpan={6}>Todos os usuários</th>
            </tr>
            <tr>
              <th>Nome</th>
              <th>E-mail</th>
              <th>Admin</th>
              <th>Criado em</th>
              <th>Ultimo login</th>
              <th style={{ width: 100 }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((item) => (
              <tr key={item?.id} style={{}}>
                <td>

                  {item?.nome}
                  {item?.userBlocked &&<>
                  <br/>
                  <span style={{color: 'var(--cardinal)', fontWeight: "bold"}}>Usuário bloqueado</span></>}</td>
                <td>{item?.email}
                {item?.userBlocked &&<>
                  <br/>
                  <span style={{color: 'var(--cardinal)', fontWeight: "bold"}}>Usuário bloqueado</span></>}
                </td>
                <td>{item?.admin ? "Sim" : "Não"}</td>
                <td>{item?.created_at && format(parseISO(item?.created_at), 'dd/MM/yyyy HH:mm:ss')}</td>
                <td>{item?.lastLogin && format(parseISO(item?.lastLogin), 'dd/MM/yyyy HH:mm:ss')}</td>

                <td>
                  <button
                    className="btn-action-users"
                    onClick={() => openModalEdit(item?.id)}
                  >
                    <LiaUserEditSolid size={25} />
                  </button>

                  <button
                    className="btn-action-users"
                    onClick={() => openModalDelete(item?.id)}
                  >
                    <MdOutlineDelete size={25} />
                  </button>

                  <button
                    className="btn-action-users"
                    onClick={() => openModalBlocked(item?.id)}
                  >
                    <MdBlockFlipped  size={24} />
                  </button>
                  

                  
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Modal
          isOpen={modalEditIsOpen}
          onRequestClose={closeModalEdit}
          style={customStyles}
        >
          <ModalEditUser
            closeModalEdit={closeModalEdit}
            senhaContemNumero={senhaContemNumero}
            senhaContemLetra={senhaContemLetra}
            senhaPossuiMinimoCaracteres={senhaPossuiMinimoCaracteres}
            userEdit={userEdit}
          />
        </Modal>

        <Modal
          isOpen={modalDeleteIsOpen}
          onRequestClose={closeModalDelete}
          style={customStyles}
        >
          <ModalDeleteUser
            closeModalDelete={closeModalDelete}
            userEdit={userEdit}
          />
        </Modal>

        <Modal
          isOpen={modalBlockedIsOpen}
          onRequestClose={closeModalBlocked}
          style={customStyles}
        >
          <ModalBlockedUser
            closeModalBlocked={closeModalBlocked}
            userEdit={userEdit}
          />
        </Modal>
      </div>
    </div>
  );
}
