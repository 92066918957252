import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/auth";

import Header from "../../components/Header";
import Title from "../../components/Title";
import { FiPlus, FiMessageSquare, FiSearch, FiEdit2 } from "react-icons/fi";

import Example from './src/example_'
	import { DndProvider } from 'react-dnd'
	import { HTML5Backend } from 'react-dnd-html5-backend'



import "./tables.css";



import React from "react";

export default function Tables() {
  return (
    <div>
      <Header />

      <div className="content">
        <Title name="Mesas">
          <FiMessageSquare size={25} />
        </Title>

        <DndProvider backend={HTML5Backend}>
					<Example />
				</DndProvider>
	
			</div>
	 
    </div>
  );
}

