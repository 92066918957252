// const [overviewStatistics, setOverviewStatistics] = useState({
//   locacoes_vendidas: 0,
//   locacoes_reservadas: 0,
//   locacoes__disponiveis: 0,

import { toast } from "react-toastify";

//   percentual_vendido: 0,
//   percentual_reservado: 0,
//   percentual_disponivel: 0,

//   valor_vendido: 0,
//   valor_reservado: 0,
//   valor_disponivel: 0,

//   vendido_recebebidos: 0,
//   vendido_recebimentos_parcial: 0,
//   vendido_recebimentos_pendente: 0,

//   valor_vendido_recebebidos: 0,
//   valor_vendido_recebimentos_parcial: 0,
//   valor_vendido_recebimentos_pendente: 0,

//   percentual_vendido_recebebidos: 0,
//   percentual_vendido_recebimentos_parcial: 0,
//   percentual_vendido_recebimentos_pendente: 0,
// });
//  let locacoes_vendidas = 0;
// let locacoes_reservadas = 0;
// let locacoes__disponiveis = 0;

// const refreshOverview = async () => {
//   let locacoes_vendidas = 0;
//   let locacoes_reservadas = 0;
//   let locacoes__disponiveis = 0;

//   let valor_vendido = 0;
//   let valor_reservado = 0;
//   let valor_disponivel = 0;

//   let vendido_recebebidos = 0;
//   let vendido_recebimentos_parcial = 0;
//   let vendido_recebimentos_pendente = 0;

//   let valor_vendido_recebebidos = 0;
//   let valor_vendido_recebimentos_parcial = 0;
//   let valor_vendido_recebimentos_pendente = 0;

//   for (let i = 0; i < locacoes.length; i++) {
//     let e = locacoes[i];
//     if (e.status === "vendido") {
//       locacoes_vendidas += 1;
//       valor_vendido += e?.valor_total_locacao;
//       if (e?.valor_total_locacao === e?.valor_recebido) {
//         vendido_recebebidos += 1;
//         valor_vendido_recebebidos += e?.valor_recebido;
//       } else if (
//         e?.valor_total_locacao > e?.valor_recebido &&
//         e?.valor_recebido !== 0
//       ) {
//         vendido_recebimentos_parcial += 1;
//         valor_vendido_recebimentos_parcial += e?.valor_recebido;
//       } else if (e?.valor_recebido === 0) {
//         vendido_recebimentos_pendente += 1;
//         valor_vendido_recebimentos_pendente += e?.valor_recebido;
//       }

//       console.log("vendido");
//     } else if (e.status === "disponivel") {
//       locacoes__disponiveis += 1;
//       valor_disponivel += e?.valor_total_locacao;
//     } else if (e.status === "reservado") {
//       locacoes_reservadas += 1;
//       valor_reservado += e?.valor_total_locacao;
//     }
//   }

//   const updatedBoxes = {
//     locacoes_vendidas,
//     percentual_vendido: parseFloat(
//       ((locacoes_vendidas / locacoes.length) * 100).toFixed(2)
//     ),
//     locacoes_reservadas,
//     percentual_reservado: parseFloat(
//       ((locacoes_reservadas / locacoes.length) * 100).toFixed(2)
//     ),
//     locacoes__disponiveis,
//     percentual_disponivel: parseFloat(
//       ((locacoes__disponiveis / locacoes.length) * 100).toFixed(2)
//     ),
//     valor_vendido,
//     valor_reservado,
//     valor_disponivel,

//     vendido_recebebidos,
//     vendido_recebimentos_parcial,
//     vendido_recebimentos_pendente,

//     percentual_vendido_recebebidos: parseFloat(
//       ((vendido_recebebidos / locacoes_vendidas) * 100).toFixed(2)
//     ),
//     percentual_vendido_recebimentos_parcial: parseFloat(
//       ((vendido_recebimentos_parcial / locacoes_vendidas) * 100).toFixed(2)
//     ),
//     percentual_vendido_recebimentos_pendente: parseFloat(
//       ((vendido_recebimentos_pendente / locacoes_vendidas) * 100).toFixed(2)
//     ),

//     valor_vendido_recebebidos,
//     valor_vendido_recebimentos_parcial,
//     valor_vendido_recebimentos_pendente,
//   };
//   return updatedBoxes;
// };

// setOverviewStatistics((prev) => {
//   // Verifica se há itens no array antes de tentar remover o último
//   if (prevBoxes.length > 0) {
//     const updatedBoxes = prevBoxes.slice(0, -1); // Remove o último item
//     // console.log(updatedBoxes); // Log o array atualizado
//     return updatedBoxes;
//   } else {
//     // Se o array estiver vazio, retorna o array atual sem fazer alterações
//     return prevBoxes;
//   }
// });

// overview.locacoes_vendidas =locacoes_vendidas
// overview.locacoes__disponiveis = locacoes__disponiveis
// overview.locacoes_reservadas = locacoes_reservadas

export const OverviewDetails = async (locacoes, ingressos_avulsos) => {
  let locacoes_vendidas = 0;
  let locacoes_reservadas = 0;
  let locacoes__disponiveis = 0;

  let valor_vendido = 0;
  let valor_reservado = 0;
  let valor_disponivel = 0;

  let vendido_recebebidos = 0;
  let vendido_recebimentos_parcial = 0;
  let vendido_recebimentos_pendente = 0;

  let valor_vendido_recebebidos = 0;
  let valor_vendido_recebimentos_parcial = 0;
  let valor_vendido_pendente_receber = 0;

  let total_de_locacoes = 0;
  let valor_total_das_locacoes = 0;
  let valor_total_recebido = 0;

  let qtd_recebido_por_pix = 0;
  let qtd_recebido_por_dinheiro = 0;
  let qtd_recebido_por_cheque = 0;
  let qtd_recebido_por_outro = 0;
  let qtd_recebido_por_meio_nao_informado = 0;

  let valor_recebido_por_pix = 0;
  let valor_recebido_por_dinheiro = 0;
  let valor_recebido_por_cheque = 0;
  let valor_recebido_por_outro = 0;
  let valor_recebido_por_meio_nao_informado = 0;

  ////

  let ingressos_avulsos_vendidos = 0;
  let ingressos_avulsos_reservados = 0;
  let ingressos_avulsos_disponiveis = 0;

  let ingressos_avulsos_valor_vendido = 0;
  let ingressos_avulsos_valor_reservado = 0;
  let ingressos_avulsos_valor_disponivel = 0;

  let ingressos_avulsos_vendido_recebebidos = 0;
  let ingressos_avulsos_vendido_recebimentos_parcial = 0;
  let ingressos_avulsos_vendido_recebimentos_pendente = 0;

  let ingressos_avulsos_valor_vendido_recebebidos = 0;
  let ingressos_avulsos_valor_vendido_recebimentos_parcial = 0;
  let ingressos_avulsos_valor_vendido_pendente_receber = 0;

  let total_de_ingressos_avulsos = 0;
  let valor_total_de_ingressos_avulsos = 0;
  let valor_total_recebido_ingressos_avulsos = 0;

  let ingressos_avulsos_qtd_recebido_por_pix = 0;
  let ingressos_avulsos_qtd_recebido_por_dinheiro = 0;
  let ingressos_avulsos_qtd_recebido_por_cheque = 0;
  let ingressos_avulsos_qtd_recebido_por_outro = 0;
  let ingressos_avulsos_qtd_recebido_por_meio_nao_informado = 0;

  let ingressos_avulsos_valor_recebido_por_pix = 0;
  let ingressos_avulsos_valor_recebido_por_dinheiro = 0;
  let ingressos_avulsos_valor_recebido_por_cheque = 0;
  let ingressos_avulsos_valor_recebido_por_outro = 0;
  let ingressos_avulsos_valor_recebido_por_meio_nao_informado = 0;

  let locacoes_lugares_totais = 0;
  let locacoes_lugares_vendidos = 0;
  let locacoes_lugares_rervados = 0;
  let locacoes_lugares_disponiveis = 0;

  let ingressos_avulsos_lugares_totais = 0;
  let ingressos_avulsos_lugares_vendidos = 0;
  let ingressos_avulsos_lugares_rervados = 0;
  let ingressos_avulsos_lugares_disponiveis = 0;


  // locacoes
  for (let i = 0; i < locacoes.length; i++) {
    let e = locacoes[i];
    total_de_locacoes += 1;
    valor_total_das_locacoes += e?.valor_total_locacao;
    valor_total_recebido += e?.valor_total_recebido;
    locacoes_lugares_totais += e?.lugares.length;
    if (e.status === "vendido") {
      locacoes_lugares_vendidos += e?.lugares.length;
      locacoes_vendidas += 1;
      valor_vendido += e?.valor_total_locacao;
      if (e?.valor_total_locacao === e?.valor_recebido) {
        vendido_recebebidos += 1;
        valor_vendido_recebebidos += e?.valor_recebido;

        if (e?.forma_de_pagamento === "dinheiro") {
          qtd_recebido_por_dinheiro += 1;
          valor_recebido_por_dinheiro += e?.valor_recebido;
        } else if (e?.forma_de_pagamento === "pix") {
          qtd_recebido_por_pix += 1;
          valor_recebido_por_pix += e?.valor_recebido;
        } else if (e?.forma_de_pagamento === "cheque") {
          qtd_recebido_por_cheque += 1;
          valor_recebido_por_cheque += e?.valor_recebido;
        } else if (e?.forma_de_pagamento === "outro") {
          qtd_recebido_por_outro += 1;
          valor_recebido_por_outro += e?.valor_recebido;
        } else if (e?.forma_de_pagamento === "") {
          qtd_recebido_por_meio_nao_informado += 1;
          valor_recebido_por_meio_nao_informado += e?.valor_recebido;
        } else {
          console.log(`Forma de pagamento não cadastrada, mesa: ${e?.numero}`);
          toast.error(`Forma de pagamento não cadastrada, mesa: ${e?.numero}`);
        }
      } else if (
        e?.valor_total_locacao > e?.valor_recebido &&
        e?.valor_recebido !== 0
      ) {
        vendido_recebimentos_parcial += 1;
        valor_vendido_recebimentos_parcial += e?.valor_recebido;

        if (e?.forma_de_pagamento === "dinheiro") {
          qtd_recebido_por_dinheiro += 1;
          valor_recebido_por_dinheiro += e?.valor_recebido;
        } else if (e?.forma_de_pagamento === "pix") {
          qtd_recebido_por_pix += 1;
          valor_recebido_por_pix += e?.valor_recebido;
        } else if (e?.forma_de_pagamento === "cheque") {
          qtd_recebido_por_cheque += 1;
          valor_recebido_por_cheque += e?.valor_recebido;
        } else if (e?.forma_de_pagamento === "outro") {
          qtd_recebido_por_outro += 1;
          valor_recebido_por_outro += e?.valor_recebido;
        } else if (e?.forma_de_pagamento === "") {
          qtd_recebido_por_meio_nao_informado += 1;
          valor_recebido_por_meio_nao_informado += e?.valor_recebido;
        } else {
          console.log(`Forma de pagamento não cadastrada, mesa: ${e?.numero}`);
          toast.error(`Forma de pagamento não cadastrada, mesa: ${e?.numero}`);
        }
      } else if (e?.valor_recebido === 0) {
        vendido_recebimentos_pendente += 1;
        valor_vendido_pendente_receber += e?.valor_total_locacao;
      }
    } else if (e.status === "disponivel") {
      locacoes_lugares_disponiveis += e?.lugares.length;
      locacoes__disponiveis += 1;
      valor_disponivel += e?.valor_total_locacao;
    } else if (e.status === "reservado") {
      locacoes_lugares_rervados += e?.lugares.length;
      locacoes_reservadas += 1;
      valor_reservado += e?.valor_total_locacao;
    }
  }

  //ingressos avulsos

  if (ingressos_avulsos) {

    for (let i = 0; i < ingressos_avulsos.length; i++) {
      let e = ingressos_avulsos[i];
      total_de_ingressos_avulsos += 1;
      ingressos_avulsos_lugares_totais += 1;
      
      valor_total_de_ingressos_avulsos += e?.valor_total_locacao;
      valor_total_recebido_ingressos_avulsos += e?.valor_total_recebido;
      if (e.status === "vendido") {
        ingressos_avulsos_vendidos += 1;
        ingressos_avulsos_lugares_vendidos += 1;

        ingressos_avulsos_valor_vendido += e?.valor_total_locacao;
        if (e?.valor_total_locacao === e?.valor_recebido) {
          ingressos_avulsos_vendido_recebebidos += 1;
          ingressos_avulsos_valor_vendido_recebebidos += e?.valor_recebido;

          if (e?.forma_de_pagamento === "dinheiro") {
            ingressos_avulsos_qtd_recebido_por_dinheiro += 1;
            ingressos_avulsos_valor_recebido_por_dinheiro += e?.valor_recebido;
          } else if (e?.forma_de_pagamento === "pix") {
            ingressos_avulsos_qtd_recebido_por_pix += 1;
            ingressos_avulsos_valor_recebido_por_pix += e?.valor_recebido;
          } else if (e?.forma_de_pagamento === "cheque") {
            ingressos_avulsos_qtd_recebido_por_cheque += 1;
            ingressos_avulsos_valor_recebido_por_cheque += e?.valor_recebido;
          } else if (e?.forma_de_pagamento === "outro") {
            ingressos_avulsos_qtd_recebido_por_outro += 1;
            ingressos_avulsos_valor_recebido_por_outro += e?.valor_recebido;
          } else if (e?.forma_de_pagamento === "") {
            ingressos_avulsos_qtd_recebido_por_meio_nao_informado += 1;
            ingressos_avulsos_valor_recebido_por_meio_nao_informado +=
              e?.valor_recebido;
          } else {
            console.log(
              `Forma de pagamento não cadastrada, mesa: ${e?.numero}`
            );
            toast.error(
              `Forma de pagamento não cadastrada, mesa: ${e?.numero}`
            );
          }
        } else if (
          e?.valor_total_locacao > e?.valor_recebido &&
          e?.valor_recebido !== 0
        ) {
          ingressos_avulsos_vendido_recebimentos_parcial += 1;
          ingressos_avulsos_valor_vendido_recebimentos_parcial +=
            e?.valor_recebido;

          if (e?.forma_de_pagamento === "dinheiro") {
            ingressos_avulsos_qtd_recebido_por_dinheiro += 1;
            ingressos_avulsos_valor_recebido_por_dinheiro += e?.valor_recebido;
          } else if (e?.forma_de_pagamento === "pix") {
            ingressos_avulsos_qtd_recebido_por_pix += 1;
            ingressos_avulsos_valor_recebido_por_pix += e?.valor_recebido;
          } else if (e?.forma_de_pagamento === "cheque") {
            ingressos_avulsos_qtd_recebido_por_cheque += 1;
            ingressos_avulsos_valor_recebido_por_cheque += e?.valor_recebido;
          } else if (e?.forma_de_pagamento === "outro") {
            ingressos_avulsos_qtd_recebido_por_outro += 1;
            ingressos_avulsos_valor_recebido_por_outro += e?.valor_recebido;
          } else if (e?.forma_de_pagamento === "") {
            ingressos_avulsos_qtd_recebido_por_meio_nao_informado += 1;
            ingressos_avulsos_valor_recebido_por_meio_nao_informado +=
              e?.valor_recebido;
          } else {
            console.log(
              `Forma de pagamento não cadastrada, mesa: ${e?.numero}`
            );
            toast.error(
              `Forma de pagamento não cadastrada, mesa: ${e?.numero}`
            );
          }
        } else if (e?.valor_recebido === 0) {
          ingressos_avulsos_vendido_recebimentos_pendente += 1;
          ingressos_avulsos_valor_vendido_pendente_receber +=
            e?.valor_total_locacao;
        }
      } else if (e.status === "disponivel") {
        ingressos_avulsos_lugares_disponiveis += 1;

        ingressos_avulsos_disponiveis += 1;
        ingressos_avulsos_valor_disponivel += e?.valor_total_locacao;
      } else if (e.status === "reservado") {
        ingressos_avulsos_lugares_rervados += 1;

        ingressos_avulsos_reservados += 1;
        ingressos_avulsos_valor_reservado += e?.valor_total_locacao;
      }
    }
  }
  const details = {
    locacoes_vendidas,
    percentual_vendido: parseFloat(
      ((locacoes_vendidas / locacoes.length) * 100).toFixed(2)
    ),
    locacoes_reservadas,
    percentual_reservado: parseFloat(
      ((locacoes_reservadas / locacoes.length) * 100).toFixed(2)
    ),
    locacoes__disponiveis,
    percentual_disponivel: parseFloat(
      ((locacoes__disponiveis / locacoes.length) * 100).toFixed(2)
    ),
    valor_vendido,
    valor_reservado,
    valor_disponivel,

    vendido_recebebidos,
    vendido_recebimentos_parcial,
    vendido_recebimentos_pendente,

    percentual_vendido_recebebidos: parseFloat(
      (vendido_recebebidos / locacoes_vendidas) * 100
    ),
    percentual_vendido_recebimentos_parcial: parseFloat(
      (vendido_recebimentos_parcial / locacoes_vendidas) * 100
    ),
    percentual_vendido_recebimentos_pendente: parseFloat(
      (vendido_recebimentos_pendente / locacoes_vendidas) * 100
    ),

    valor_vendido_recebebidos,
    valor_vendido_recebimentos_parcial,
    valor_vendido_pendente_receber,

    total_de_locacoes,
    percentual_total_de_locacoes: parseFloat(
      (total_de_locacoes / locacoes.length) * 100
    ),
    valor_total_das_locacoes,

    qtd_recebido_por_pix,
    qtd_recebido_por_dinheiro,
    qtd_recebido_por_cheque,
    qtd_recebido_por_outro,
    qtd_recebido_por_meio_nao_informado,
    valor_recebido_por_pix,
    valor_recebido_por_dinheiro,
    valor_recebido_por_cheque,
    valor_recebido_por_outro,
    valor_recebido_por_meio_nao_informado,

    percentual_recebido_por_pix: parseFloat(
      (qtd_recebido_por_pix /
        (vendido_recebebidos + vendido_recebimentos_parcial)) *
        100
    ),
    percentual_recebido_por_dinheiro: parseFloat(
      (qtd_recebido_por_dinheiro /
        (vendido_recebebidos + vendido_recebimentos_parcial)) *
        100
    ),
    percentual_recebido_por_cheque: parseFloat(
      (qtd_recebido_por_cheque /
        (vendido_recebebidos + vendido_recebimentos_parcial)) *
        100
    ),
    percentual_recebido_por_outro: parseFloat(
      (qtd_recebido_por_outro /
        (vendido_recebebidos + vendido_recebimentos_parcial)) *
        100
    ),
    percentual_recebido_por_meio_nao_informado: parseFloat(
      (qtd_recebido_por_meio_nao_informado /
        (vendido_recebebidos + vendido_recebimentos_parcial)) *
        100
    ),

    ///ingressos avulsos
    ingressos_avulsos_vendidos,
    ingressos_avulsos_percentual_vendido: parseFloat(
      ((ingressos_avulsos_vendidos / ingressos_avulsos?.length) * 100).toFixed(
        2
      )
    ),
    ingressos_avulsos_reservados,
    ingressos_avulsos_percentual_reservado: parseFloat(
      (
        (ingressos_avulsos_reservados / ingressos_avulsos?.length) *
        100
      ).toFixed(2)
    ),
    ingressos_avulsos_disponiveis,
    ingressos_avulsos_percentual_disponivel: parseFloat(
      (
        (ingressos_avulsos_disponiveis / ingressos_avulsos?.length) *
        100
      ).toFixed(2)
    ),
    ingressos_avulsos_valor_vendido,
    ingressos_avulsos_valor_reservado,
    ingressos_avulsos_valor_disponivel,

    ingressos_avulsos_vendido_recebebidos,
    ingressos_avulsos_vendido_recebimentos_parcial,
    ingressos_avulsos_vendido_recebimentos_pendente,

    ingressos_avulsos_percentual_vendido_recebebidos: parseFloat(
      (ingressos_avulsos_vendido_recebebidos / ingressos_avulsos_vendidos) * 100
    ),
    ingressos_avulsos_percentual_vendido_recebimentos_parcial: parseFloat(
      (ingressos_avulsos_vendido_recebimentos_parcial /
        ingressos_avulsos_vendidos) *
        100
    ),
    ingressos_avulsos_percentual_vendido_recebimentos_pendente: parseFloat(
      (ingressos_avulsos_vendido_recebimentos_pendente /
        ingressos_avulsos_vendidos) *
        100
    ),

    ingressos_avulsos_valor_vendido_recebebidos,
    ingressos_avulsos_valor_vendido_recebimentos_parcial,
    ingressos_avulsos_valor_vendido_pendente_receber,

    total_de_ingressos_avulsos,
    percentual_total_de_ingressos_avulsos: parseFloat(
      (total_de_ingressos_avulsos / ingressos_avulsos?.length) * 100
    ),
    valor_total_de_ingressos_avulsos,

    ingressos_avulsos_qtd_recebido_por_pix,
    ingressos_avulsos_qtd_recebido_por_dinheiro,
    ingressos_avulsos_qtd_recebido_por_cheque,
    ingressos_avulsos_qtd_recebido_por_outro,
    ingressos_avulsos_qtd_recebido_por_meio_nao_informado,
    ingressos_avulsos_valor_recebido_por_pix,
    ingressos_avulsos_valor_recebido_por_dinheiro,
    ingressos_avulsos_valor_recebido_por_cheque,
    ingressos_avulsos_valor_recebido_por_outro,
    ingressos_avulsos_valor_recebido_por_meio_nao_informado,

    ingressos_avulsos_percentual_recebido_por_pix: parseFloat(
      (ingressos_avulsos_qtd_recebido_por_pix /
        (ingressos_avulsos_vendido_recebebidos +
          ingressos_avulsos_vendido_recebimentos_parcial)) *
        100
    ),
    ingressos_avulsos_percentual_recebido_por_dinheiro: parseFloat(
      (ingressos_avulsos_qtd_recebido_por_dinheiro /
        (ingressos_avulsos_vendido_recebebidos +
          ingressos_avulsos_vendido_recebimentos_parcial)) *
        100
    ),
    ingressos_avulsos_percentual_recebido_por_cheque: parseFloat(
      (ingressos_avulsos_qtd_recebido_por_cheque /
        (ingressos_avulsos_vendido_recebebidos +
          ingressos_avulsos_vendido_recebimentos_parcial)) *
        100
    ),
    ingressos_avulsos_percentual_recebido_por_outro: parseFloat(
      (ingressos_avulsos_qtd_recebido_por_outro /
        (ingressos_avulsos_vendido_recebebidos +
          ingressos_avulsos_vendido_recebimentos_parcial)) *
        100
    ),
    ingressos_avulsos_percentual_recebido_por_meio_nao_informado: parseFloat(
      (ingressos_avulsos_qtd_recebido_por_meio_nao_informado /
        (ingressos_avulsos_vendido_recebebidos +
          ingressos_avulsos_vendido_recebimentos_parcial)) *
        100
    ),
    lugares_totais: locacoes_lugares_totais+ingressos_avulsos_lugares_totais,
    lugares_vendidos: locacoes_lugares_vendidos+ingressos_avulsos_lugares_vendidos,
    lugares_rervados: locacoes_lugares_rervados+ingressos_avulsos_lugares_rervados,
    lugares_disponiveis: locacoes_lugares_disponiveis+ingressos_avulsos_lugares_disponiveis,

    locacoes_lugares_totais,
    locacoes_lugares_vendidos,
    locacoes_lugares_rervados,
    locacoes_lugares_disponiveis,

    ingressos_avulsos_lugares_totais,
    ingressos_avulsos_lugares_vendidos,
    ingressos_avulsos_lugares_rervados,
    ingressos_avulsos_lugares_disponiveis,
  };
  return details;
};
