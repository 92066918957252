import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/auth";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { FiMessageSquare } from "react-icons/fi";
import Form from "./Form"; // Importa o componente de formulário
import MapPreview from "./MapPreview"; // Importa o componente de visualização do mapa
import config from "../../config";
import "./maps.css";
import { toast } from "react-toastify";
import ListMaps from "./ListMaps";


export default function Maps() {
  const { user } = useContext(AuthContext);
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeBoxId, setActiveBoxId] = useState(null);

  const [formData, setFormData] = useState({
    nome: "",
    descricao: "",
    tipo_locacao: "mesas",
    
    map_url: "",
    urlOrSelect: "",
    map_plan: "",
    quantidade_de_locacoes: 0,
    lugares_por_locacao: 0,
    locacao_width_pixels: 0,
    locacao_height_pixels: 0,
    img_width_pixels: 0,
    img_height_pixels: 0,
  });

  const [boxes, setBoxes] = useState([]);

  useEffect(() => {
    async function loadEvents() {
      await getEvents();
      setLoading(false);
    }

    loadEvents();

    return () => {
      // Cleanup, se necessário
    };
  }, []); // Empty dependency array ensures that this effect runs once after the initial render

  async function getEvents() {
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/event/get-events`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
        },
      });

      // Lógica para buscar eventos...

      setEvents(events);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const _handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData, [name]: value };
  
    // Verifica se o campo alterado é o map_plan
    if (name === "map_plan") {
      // Encontrar o item selecionado na lista de mapPlans
      const selectedMapPlan = mapPlansList.find((item) => item.thumbnail === value);
  
      // Se o item for encontrado, atualiza o map_url com a thumbnail_url
      if (selectedMapPlan) {
        updatedFormData = {
          ...updatedFormData,
          map_url: selectedMapPlan.thumbnail_url,
        };
      }
    }

    if (name === "urlOrSelect") {
      updatedFormData = {
        ...updatedFormData,
        map_url: '',
        map_plan: ''
      };
    }
  
    setFormData(updatedFormData);
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    let urlOrSelectObj =  { }

    if(formData?.urlOrSelect === 'url'){
      urlOrSelectObj.map_plan = ''
    }else if(formData?.urlOrSelect === 'select'){
      urlOrSelectObj.map_url = ''
    }

    try {
      console.log()
      // Lógica para enviar os dados do formData para o servidor aqui...
      // Após o envio bem-sucedido, você pode realizar alguma ação, como redirecionar o usuário ou mostrar uma mensagem de sucesso.
      saveMap({...formData, boxes, ...urlOrSelectObj})
    } catch (error) {
      console.error("Erro ao enviar formulário:", error);
      // Lide com o erro, como exibir uma mensagem de erro para o usuário.
    }
  };

  const saveMap = async (data) =>{
    console.log(data)
    setLoading(true)
    try {
      const response = await fetch(`${config.urlServer}/maps/create-map`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
        },
        body : JSON.stringify({...data}),
      });



      if (response.status !== 201) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido na requisição.";
        toast.error(errorMessage);

        throw new Error(errorMessage);
      }

      const json = await response.json();
      toast.success("Mapa criado com sucesso!")
      console.log(json)
    } catch (error) {
      
    }
  }


  const handleMouseDown = (e, boxId) => {
    setActiveBoxId(boxId);
    setIsDragging(true);
    const clickedBox = boxes.find((box) => box.id === boxId);
    if (clickedBox) {
      setOffset({
        x: e.clientX - clickedBox.x,
        y: e.clientY - clickedBox.y,
      });
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging && activeBoxId !== null) {
      const { clientX, clientY } = e;
      const updatedBoxes = boxes.map((box) => {
        if (box.id === activeBoxId) {
          const newX = clientX - offset.x;
          const newY = clientY - offset.y;

          // Verifica se a caixa não ultrapassa os limites da imagem
          const maxX =
            formData.img_width_pixels - formData.locacao_width_pixels;
          const maxY =
            formData.img_height_pixels - formData.locacao_height_pixels;

          // Atualiza as coordenadas da caixa, garantindo que ela permaneça dentro dos limites
          const x = Math.max(0, Math.min(newX, maxX));
          const y = Math.max(0, Math.min(newY, maxY));

          return {
            ...box,
            x,
            y,
          };
        }
        return box;
      });
      setBoxes(updatedBoxes);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setActiveBoxId(null);
  };
  useEffect(() => {
    if (formData.quantidade_de_locacoes > 0) {
      const numRows = Math.floor(
        formData.img_height_pixels / formData.locacao_height_pixels
      );
      const numCols = Math.floor(
        formData.img_width_pixels / formData.locacao_width_pixels
      );
      const numLocations = Math.min(
        formData.quantidade_de_locacoes,
        numRows * numCols
      );
      const spacingX = formData.img_width_pixels / numCols;
      const spacingY = formData.img_height_pixels / numRows;

      const margin = 5; // Margem de 2 pixels

      const newBoxes = Array.from({ length: numLocations }, (_, index) => {
        const row = Math.floor(index / numCols);
        const col = index % numCols;
        const x = Math.round(col * spacingX + margin);
        const y = Math.round(row * spacingY + margin);
        const number = index + 1; // Número sequencial começando do 1
        return {
          id: index,
          x,
          y,
          number, // Adicione o campo 'number' ao objeto do box
        };
      });
      setBoxes(newBoxes);
    } else {
      setBoxes([]);
    }
  }, [
    formData.quantidade_de_locacoes,
    formData.img_width_pixels,
    formData.img_height_pixels,
    formData.locacao_width_pixels,
    formData.locacao_height_pixels,
  ]);

  // Função para obter as dimensões da imagem referenciada em map_url
  const getImageDimensions = (url) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setFormData((prevData) => ({
        ...prevData,
        img_width_pixels: img.width,
        img_height_pixels: img.height,
      }));
    };
  };

  useEffect(() => {
    // Obtém as dimensões da imagem ao carregar a página
    getImageDimensions(formData.map_url);
  }, [formData.map_url]);


  const [mapPlansList, setMapPlansList] = useState([]);

  useEffect(() => {
    getListMapPlans();
  }, []);

  async function getListMapPlans() {
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/map-plans/list`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
        },
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao buscar os mapas";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // json deve ser uma lista de eventos
      console.log(json);
      setMapPlansList(json);
      // console.log(maps);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao buscar os eventos!"
      );
    }
  }

  let locationPreviewStyle = {
    width: `${formData.locacao_width_pixels}px`,
    height: `${formData.locacao_height_pixels}px`,
    backgroundColor: "rgba(255, 0, 0, 0.5)", // Cor de fundo com transparência
    position: "absolute",
    top: "0",
    left: "0",
  };

  const handleEditBoxPosition = (boxId, property, value) => {
    const updatedBoxes = boxes.map((box) => {
      if (box.id === boxId) {
        return {
          ...box,
          [property]: parseInt(value, 10) || 0, // Garante que o valor seja um número inteiro
        };
      }
      return box;
    });
    setBoxes(updatedBoxes);
  };

  return (
    <div>
      <Header />
      <div className="maps-content">
        <Title name="Mapas">
          <FiMessageSquare size={25} />
          
        </Title>
        {/* <ListMaps
          setFormDataEdit={setFormData}
        /> */}

        <h3>Criar mapa</h3>
        <section >

          <Form
            formData={formData}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            mapPlansList={mapPlansList}
            setMapPlansList={setMapPlansList}
          />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <MapPreview
              formData={formData}
              boxes={boxes}
              handleMouseDown={handleMouseDown}
              handleMouseMove={handleMouseMove}
              handleMouseUp={handleMouseUp}
            />
            <div
              className="maps-box-list"
              style={{
                maxHeight: `${formData.img_height_pixels}px`,
                overflowY: "auto",
              }}
            
            >
              <h2>Lista de locações</h2>
              <ul>
                {boxes.map((box) => (
                  <li key={box.id}>
                    {box.number}:
                    <label>
                      X:{" "}
                      <input
                        type="number"
                        value={box.x}
                        onChange={(e) =>
                          handleEditBoxPosition(box.id, "x", e.target.value)
                        }
                      />
                    </label>
                    <label>
                      Y:{" "}
                      <input
                        type="number"
                        value={box.y}
                        onChange={(e) =>
                          handleEditBoxPosition(box.id, "y", e.target.value)
                        }
                      />
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
