import { useContext, useState } from "react";
import Header from "../../components/Header";
import Title from "../../components/Title";

import { LuDatabaseBackup } from "react-icons/lu";

import { FiSettings, FiUpload } from "react-icons/fi";
import { AuthContext } from "../../contexts/auth";

import ReactLoading from "react-loading";

import { toast } from "react-toastify";
import config from "../../config";

import "./profile.css";

export default function Profile() {
  const { user, storageUser, setUser, logout } = useContext(AuthContext);

  const [avatarUrl, setAvatarUrl] = useState(user && user.avatarUrl);
  const [imageAvatar, setImageAvatar] = useState(null);

  const [name, setName] = useState(user && user.name);
  const [email, setEmail] = useState(user && user.email);
  const [loading, setLoading] = useState(false);

  function handleFile(e) {
    if (e.target.files[0]) {
      const image = e.target.files[0];

      if (image.type === "image/jpeg" || image.type === "image/png") {
        setImageAvatar(image);
        setAvatarUrl(URL.createObjectURL(image));
      } else {
        alert("Envie uma imagem do tipo PNG ou JPEG");
        setImageAvatar(null);
        return;
      }
    }
  }



  const handleSubmit = () => {}
  

  async function handleBackup() {
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/backup`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
        },
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao realizar backup";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // json deve ser uma lista de eventos
      console.log(json);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao buscar os usuarios!"
      );
    }
  }
  return (
    <div>
      <Header />
      {loading ? (
        <article
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            padding: "10%",
          }}
        >
          <ReactLoading
            type="spinningBubbles"
            color="var(--rotary-gold)"
            height={"5%"}
            width={"5%"}
          />
        </article>
      ) : (
      <div className="content">
        <Title name="Minha conta">
          <FiSettings size={25} />
        </Title>
        {user?.admin && (
          <button
          className="btn-backup"
            onClick={() => {
              handleBackup();
            }}
          >
            <span>
            Fazer backup
            </span>
            <LuDatabaseBackup size={20} />
          </button>
        )}
        <div className="container">
            
          <form className="form-profile" onSubmit={handleSubmit}>

            <label>Nome</label>
            <input
              type="text"
              value={name}
              readOnly disabled={true}
            />

            <label>Email</label>
            <input type="text" value={email} readOnly disabled={true} />

            <label>Id</label>
            <input type="text" value={user.uid} readOnly disabled={true} />

            {/* <button type="submit">Salvar</button> */}
          <i>Versão: 09.03.2024-20:25</i>
          <i>Servidor: <a href={`${config?.urlServer}/version`} target="_blank">{config?.urlServer}</a></i>


          </form>
        </div>


        <div className="container">
          <button className="logout-btn" onClick={() => logout()}>
            Sair
          </button>
        </div>
      </div>
      )}
    </div>
  );
}
