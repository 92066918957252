import React from "react";
import { toast } from "react-toastify";
import {
  IoLocationOutline,
  IoCaretUp,
  IoCaretDown,
  IoOptions,
} from "react-icons/io5";

const Form = ({
  formData,
  handleInputChange,
  handleSubmit,
  mapPlansList,
  setFormData,
  boxes,
  setBoxes,
}) => {

  const increment = () => {
    let lastLocation = boxes[boxes.length-1]
    let newLocation = {
      id: (parseInt(lastLocation?.id)+1),
      x: 7, 
      y: 5,
      number: (parseInt(lastLocation?.number)+1), 
    }

    setBoxes((prevBoxes) => {
      const updatedBoxes = [...prevBoxes, newLocation] 
      // console.log(updatedBoxes); // Log the updated boxes
      return updatedBoxes;
    });
  };

 const decrement = () => {
  setBoxes((prevBoxes) => {
    // Verifica se há itens no array antes de tentar remover o último
    if (prevBoxes.length > 0) {
      const updatedBoxes = prevBoxes.slice(0, -1); // Remove o último item
      // console.log(updatedBoxes); // Log o array atualizado
      return updatedBoxes;
    } else {
      // Se o array estiver vazio, retorna o array atual sem fazer alterações
      return prevBoxes;
    }
  });
};


  return (
    <form className="form-create-maps-event" onSubmit={handleSubmit}>
      <section>
        <article>
          <div>
            <label>Nome do mapa:</label>
            <input
              type="text"
              name="nome"
              value={formData.nome}
              onChange={handleInputChange}
            />
          </div>

          <div>
            <label>Descrição do mapa:</label>
            <textarea
              name="descricao"
              value={formData.descricao}
              onChange={handleInputChange}
            />
          </div>
          <div>
            {/* <label>Mapa URL:</label>
            <input
              type="text"
              name="map_url"
              value={formData.map_url}
              onChange={handleInputChange}
            /> */}
                  <article>
            <div>
              <label>Url ou selecionar:</label>
              <select
                name="urlOrSelect"
                value={formData.urlOrSelect}
                onChange={handleInputChange}
              >
                     <option key="0" value="" selected disabled hidden>Escolha uma opção para a imgem</option>

                <option value="url">URL externa</option>
                <option value="select">Selecionar imagem interna</option>
              </select>
            </div>
          </article>
             {formData?.urlOrSelect === "url" ? (
            <div>
              <label>Mapa URL:</label>
              <input
                type="text"
                name="map_url"
                value={formData.map_url}
                onChange={handleInputChange}
              />
              <span>
                Largura de <span>{formData.img_width_pixels}px</span> por{" "}
                <span>{formData.img_height_pixels}px</span> Altura
              </span>
              <span></span>
            </div>
          ) : formData?.urlOrSelect === "select" ? (
            <div>
              <label>Selecione a planta:</label>
              <select
                name="map_plan"
                value={formData.map_plan}
                onChange={handleInputChange}
              >
                     <option key="0" value="" selected disabled hidden>
                Escolha a planta..
              </option>
                {mapPlansList.map((item) => (
                  <option key={item?.thumbnail} value={item?.thumbnail}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
            <span>
              Largura de <span>{formData.img_width_pixels}px</span> por{" "}
              <span>{formData.img_height_pixels}px</span> Altura
            </span>
            <span></span>
          </div>
        </article>
        <div
          style={{ backgroundColor: "#000", width: "1px", margin: "5px" }}
        ></div>
        <article>
          <div>
            <label>Locação por:</label>
            <select
              name="tipo_locacao"
              value={formData.tipo_locacao}
              onChange={handleInputChange}
            >
              <option value="mesas">Mesas</option>
              <option value="cadeiras">Cadeiras</option>
            </select>
          </div>
          <article className="article-increment-decrement-locacoes">
            <label>
              <span>Quantidade de locações:</span>
              <div className="article-increment-decrement-locacoes-div">
                <button type="button" onClick={decrement}>
                  <IoCaretDown className="icon-decrement-increment" />
                </button>
                <span>{boxes?.length}</span>
                <button type="button" onClick={increment}>
                  <IoCaretUp className="icon-decrement-increment" />
                </button>
              </div>
            </label>
          </article>
          <div style={{ flexDirection: "row" }}>
            {/* <div>
              <label>Quantidade de locações:</label>
              <input
                type="number"
                name="quantidade_de_locacoes"
                value={formData.quantidade_de_locacoes}
                readOnly
                disabled
              />
            </div> */}

            <div>
              <label>Pessoas por locação:</label>
              <input
                type="number"
                name="lugares_por_locacao"
                value={formData.lugares_por_locacao}
                onChange={handleInputChange}
              />
            </div>
          </div>
 
          <div style={{ flexDirection: "row" }}>
            <div>
              <label>Largura da locação (em pixels):</label>
              <input
                type="number"
                name="locacao_width_pixels"
                value={formData.locacao_width_pixels}
                onChange={handleInputChange}
              />
            </div>


            <div style={{ flexDirection: "row" }}>
            {/* <div>
              <label>Quantidade de locações:</label>
              <input
                type="number"
                name="quantidade_de_locacoes"
                value={formData.quantidade_de_locacoes}
                readOnly
                disabled
              />
            </div> */}

            <div>
              <label>Pessoas por locação:</label>
              <input
                type="number"
                name="lugares_por_locacao"
                value={formData.lugares_por_locacao}
                onChange={handleInputChange}
              />
            </div>
          </div>

            <div>
              <label>Altura da locação (em pixels):</label>
              <input
                type="number"
                name="locacao_height_pixels"
                value={formData.locacao_height_pixels}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </article>
      </section>
    </form>
  );
};

export default Form;
