import React, { useState, useEffect, useContext } from "react";

import "./modalTransferTable.css";
import { format } from "date-fns";
import { AuthContext } from "../../contexts/auth";

import { AiOutlineClose } from "react-icons/ai";
import { IoLocationOutline, IoCaretUp, IoCaretDown } from "react-icons/io5";
import config from "../../config";
import { toast } from "react-toastify";
import { ImUpload } from "react-icons/im";
import { el } from "date-fns/locale";

export default function ModalTransferTable({
  closeModal,
  boxes,
  _dataLocacao,
  id_event,
  id_map,
  updateLocalBoxes,
  updateTransferLocalBoxes,
  openModalTransfer,
}) {
  const [dataLocacao, setDataLocacao] = useState(null);
  const [formData, setFormData] = useState({
    id_event,
    id_map,
    id_locacao: _dataLocacao?._id,

    eventMapCopy: _dataLocacao?.mapCopy,
    locacao_destino: null
  });
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    return () => ({});
  }, [dataLocacao]);

  const teste = () => {
    console.log(dataLocacao);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    updateTable(formData);

  };



  const handleInputChange = (e, index, fieldName) => {
    const { value, name } = e.target;

    const updatedFormData = { ...formData };

    if (name.includes("lugares")) {
      updatedFormData.lugares[index][fieldName] = value;
    } else {
      updatedFormData[name] = value;
    }

    setFormData(updatedFormData);
    // console.log(updatedFormData);
  };



  useEffect(() => {
    if (_dataLocacao?._id) {
      getDataLocacao(_dataLocacao?._id);

      console.log(boxes);
    }
  }, [_dataLocacao?._id]);

  

  const handleEditAllForm = async (json) => {
    const updatedFormData = { ...formData };
    updatedFormData.status = json?.status ? json?.status : "";
    updatedFormData.qtd_lugares = json?.lugares?.length
      ? json?.lugares?.length
      : 0;
    updatedFormData.valor_total_locacao = json?.valor_total_locacao
      ? json?.valor_total_locacao
      : 0;
    updatedFormData.valor_recebido = json?.valor_recebido
      ? json?.valor_recebido
      : 0;
    updatedFormData.anotacoes_financeiras = json?.anotacoes_financeiras
      ? json?.anotacoes_financeiras
      : "";

    updatedFormData.lugares = json?.lugares || [];
    updatedFormData.comprador = json?.comprador ? json?.comprador : "";
    updatedFormData.forma_de_pagamento = json?.forma_de_pagamento
      ? json?.forma_de_pagamento
      : "";

    setFormData(updatedFormData);
    console.log(updatedFormData);
  };
  const getDataLocacao = async (idLocacao) => {
    try {
      setLoading(true);

      const response = await fetch(
        `${config.urlServer}/event/get-data-locacao`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: user.email,
            token: user.token,
            id_map,
            id_locacao: idLocacao,
          }),
        }
      );

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao buscar locação";
        throw new Error(errorMessage);
      }

      const json = await response.json();
      console.log(json);
      setDataLocacao(json?.locacao);
      handleEditAllForm(json?.locacao);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error(
        error.msg || "Ops, algo deu errado ao buscar informações da locação!"
      );
    }
  };

  async function updateTable(data_edit) {

    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/event/transfer-locacao`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: user.email,
          token: user.token,
          data_edit,
        }),
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao editando o evento";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // const eventData = {
      //   eventId: json.event_id, // Substitua por um valor real, se necessário
      //   nome: json.nome,
      //   descricao: json.descricao,
      //   data: json.data,
      //   localizacao: json.localizacao,
      // };

      console.log(json)
      setLoading(false);
      toast.success("Evento editado com sucesso!");
      updateTransferLocalBoxes(json?.alteracoes)

      closeModal();
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error(error.msg || "Ops, algo deu errado ao editar o evento!");
    }
  }



  if (!dataLocacao) {
    return (
      <div>
        <span>LOADING...</span>
      </div>
    );
  }

  return (
    <section className="modal-transfer-locacao">
      <header>
        <IoLocationOutline color="rgb(50,50,50)" size={24} />
        <div className="view-number-locacao">
          <h3>Locação número:</h3>
          <h3>{dataLocacao?.numero}</h3>
        </div>
        <button onClick={closeModal}>
          <AiOutlineClose color="rgb(50,50,50)" size={24} />
        </button>
      </header>

      <section className="modal-transfer-locacao-section-form">
        <form onSubmit={handleFormSubmit}>
          <label>
            <span style={{ minWidth: 140 }}>Escolha a locação de destino:</span>
            <select
              name="locacao_destino"
              value={formData?.locacao_destino}
              onChange={handleInputChange}
            >
                 <option key={0} value={null}>Selecione a locação de destino...</option>
              {boxes.map((element) => {
                if (element?.status === "disponivel") {
                  return (
                    <option key={element?.numero} value={element?._id}>
                      Locação número: {element?.numero} - {element?.status === 'disponivel' ? 'Disponível': element?.status }
                    </option>
                  );
                } else {
                  return (
                    <option
                      key={element?.numero}
                      disabled
                      value={element?._id}
                      style={{color: 'red'}}
                    >
                      Locação número: {element?.numero} - {element?.status === 'reservado' ? 'Reservado': element?.status === 'vendido' ? 'Vendido' : element?.status }
                    </option>
                  );
                }
              })}
            </select>
          </label>
          <button className="btn-action-save-modal-transfer" type="submit">
            Tranferir dados para a nova locação
          </button>
        </form>
      </section>
    </section>
  );
}
