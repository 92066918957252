 import React, { useState, useEffect, useContext } from "react";

import "./reports.css";
import { format } from "date-fns";
import { AuthContext } from "../../../contexts/auth";
import { saveAs } from "file-saver";
import { AiOutlineClose } from "react-icons/ai";
import {
  IoLocationOutline,
  IoCaretUp,
  IoCaretDown,
  IoOptions,
} from "react-icons/io5";
import config from "../../../config";
import { toast } from "react-toastify";
import PdfViewer from "./PdfViewer";
import { relatorioGeral } from "./RelatorioGeral/relatorioGeral";
import { relatorioPorVendedor } from "./RelatorioPorVendedor/relatorioPorVendedor";
import { relatorioPorFormaDePagamento } from "./RelatorioPorFormaDePagamento/relatorioPorFormaDePagamento";
import { relatorioResumido } from "./RelatorioResumido/relatorioResumido";
import ReactLoading from "react-loading";
import { BsFileEarmarkPdf, BsFiletypeCsv } from "react-icons/bs";
import { relatorioResumidoOrdenadoPorLocacao } from "./RelatorioResumidoOrdenadoPorLocacao/relatorioResumidoOrdenadoPorLocacao";
import { relatorioOrdenadoLugares } from "./RelatorioOrdenadoLugares/relatorioOrdenadoLugares";

export default function Reports({
  event,
  dataLocacao,
  event_id,
  getEvent,
  vendedores,
  boxes,
  detailsMap,
}) {
  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState(null);
  const [relatorio, setRelatorio] = useState(null);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    vendedor: null,
    forma_de_pagamento: null,
  });

  const handleInputChange = (e, index, fieldName) => {
    const { value, name } = e.target;

    const updatedFormData = { ...formData };

    if (name.includes("lugares")) {
      updatedFormData.lugares[index][fieldName] = value;
    } else {
      updatedFormData[name] = value;
    }

    setFormData(updatedFormData);
    // console.log(updatedFormData);
  };
  // useEffect(() => {
  //   // getListMaps();
  //   getUsers();
  //   // setFormData({ ...formData, [name]: value });
  //   return () => ({});
  // }, [dataLocacao]);

  // async function getUsers() {
  //   try {
  //     setLoading(true);

  //     const response = await fetch(`${config.urlServer}/users/get-users`, {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         email: user.email,
  //         token: user.token,
  //       },
  //     });

  //     if (response.status !== 200) {
  //       const errorData = await response.json();
  //       const errorMessage =
  //         errorData.msg || "Erro desconhecido ao buscar usuários";
  //       throw new Error(errorMessage);
  //     }

  //     const json = await response.json();

  //     // json deve ser uma lista de eventos
  //     const users_result = json.map((result) => ({
  //       id: result?._id,
  //       nome: result?.name,
  //       email: result?.email,
  //     }));

  //     // Use setEvents para salvar os eventos no estado do seu componente
  //     setUsers(users_result);
  //     // console.log(users_result);

  //     setLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //     setLoading(false);
  //     throw new Error(
  //       error.msg || "Ops, algo deu errado ao buscar os usuarios!"
  //     );
  //   }
  // }

  async function editEvent() {
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/event/edit-event`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
          eventid: event_id,
        },
        body: JSON.stringify({}),
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao atualizar o evento";
        throw new Error(errorMessage);
      }

      const json = await response?.json();

      //   const eventData = {
      //     eventId: json.event_id, // Substitua por um valor real, se necessário
      //     nome: json.nome,
      //     descricao: json.descricao,
      //     data: json.data,
      //     localizacao: json.localizacao,
      //   };
      getEvent();
      console.log(json);
      setLoading(false);
      toast.success("Evento editado com sucesso!");
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error(error.msg || "Ops, algo deu errado ao editar o evento!");
    }
  }

  // async function getEvent() {
  //   try {
  //     setLoading(true);
  //     // alert(id)

  //     const response = await fetch(`${config.urlServer}/event/get-event-id`, {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         email: user.email,
  //         token: user.token,
  //         eventid: id,
  //       },
  //     });

  //     if (response.status !== 200) {
  //       const errorData = await response.json();
  //       const errorMessage =
  //         errorData.msg || "Erro desconhecido ao buscar os eventos";
  //       throw new Error(errorMessage);
  //     }

  //     const json = await response.json();

  //     console.log(json);

  //     // json deve ser uma lista de eventos

  //     // Use setEvent para salvar os eventos no estado do seu componente
  //     setEvent(json.data);
  //     setBoxes(json?.data?.mapa_mesas.locacoes);
  //     console.log(json?.data?.mapa_mesas.locacoes);

  //     setLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //     setLoading(false);
  //     throw new Error(
  //       error.msg || "Ops, algo deu errado ao buscar os eventos!"
  //     );
  //   }
  // }

  async function _getRelatorioVendas() {
    try {
      setLoading(true);
      // alert(id)

      const response = await fetch(
        `${config.urlServer}/event/relatorios/relatorio-vendas`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: user.email,
            token: user.token,
            eventid: event_id,
          },
        }
      );

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao buscar os eventos";
        throw new Error(errorMessage);
      }

      const json = await response.json();
      console.log(json);

      let relatorio = `numero;status;vendedor;comprador;valor_total_locacao;valor_por_lugar;lugares;valor_recebido;forma_de_pagamento;\n`;
      for (let i = 0; i < json.locacoes.length; i++) {
        let element = json.locacoes[i];

        relatorio += `${element?.numero};${element?.status};${element?.vendedor};${element?.comprador};${element?.valor_total_locacao};${element?.valor_por_lugar};${element?.lugares.length};${element?.valor_recebido};${element?.forma_de_pagamento};\n`;
      }

      downloadCsvFile(json?.relatorio, "locacoes_vendidas");

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(error.msg || "Ops, algo deu errado ao gerar relatório!");
    }
  }

  async function getRelatorioGeralCsv() {
    try {
      setLoading(true);

      const response = await fetch(
        `${config.urlServer}/event/relatorios/relatorio-geral`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: user.email,
            token: user.token,
            eventid: event_id,
          },
        }
      );

      const responseData = await response.json();

      if (response.status !== 200) {
        const errorMessage =
          responseData.msg || "Erro desconhecido ao buscar os eventos";
        throw new Error({ message: errorMessage, status: response.status });
      }

      console.log(responseData);

      let relatorio = `numero;status;vendedor;comprador;lugares;valor_total_locacao;valor_por_lugar;lugares;valor_recebido;forma_de_pagamento;tipo;\n`;

      if (responseData.locacoes) {
        for (let i = 0; i < responseData.locacoes.length; i++) {
          let element = responseData.locacoes[i];
          let lugares = "";
          for (let j = 0; j < element.lugares.length; j++) {
            lugares += `${j + 1}: ${element.lugares[j].nome} - ${
              element.lugares[j].documento
            } ${j === element.lugares.length - 1 ? "" : " | "}`;
          }
          relatorio += `${element?.numero};${element?.status};${element?.vendedor};${element?.comprador};${lugares};${element?.valor_total_locacao};${element?.valor_por_lugar};${element?.lugares.length};${element?.valor_recebido};${element?.forma_de_pagamento};locacao/mesa\n`;
        }
      }

   
      if (responseData.ingressos_avulsos) {
        for (let i = 0; i < responseData.ingressos_avulsos.length; i++) {
          let element = responseData.ingressos_avulsos[i];
          let lugares = "";
          for (let j = 0; j < element.lugares.length; j++) {
            lugares += `${j + 1}: ${element.lugares[j].nome || ''} - ${
              element.lugares[j].documento
            } ${j === element.lugares.length - 1 ? "" : " | "}`;
          }
          relatorio += `${element?.numero};${element?.status};${element?.vendedor};${element?.comprador};${lugares};${element?.valor_total_locacao};${element?.valor_por_lugar};${element?.lugares.length};${element?.valor_recebido};${element?.forma_de_pagamento};ingresso avulso;\n`;
        }
      }

      downloadCsvFile(relatorio, "relatorio_geral");

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.message || "Ops, algo deu errado ao gerar relatório!"
      );
    }
  }

  console.log(vendedores);

  const downloadCsvFile = (value, name) => {
    console.log(value);
    const element = document.createElement("a");
    const file = new Blob([value], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = `${name}.csv`;
    document.body.appendChild(element);
    element.click();
  };

  const handleRelatorioGeralPdf = () => {
    setLoading(true);
    relatorioGeral(user, boxes, detailsMap, event_id, event?.nome)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleRelatorioResumido = () => {
    setLoading(true);
    relatorioResumido(user, boxes, detailsMap, event_id, event?.nome)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const handleRelatorioResumidoOrdenadoLugares = () => {
    setLoading(true);
    relatorioOrdenadoLugares(user, boxes, detailsMap, event_id, event?.nome)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  
  const handleRelatorioResumidoOrdenadoLocacao = () => {
    setLoading(true);
    relatorioResumidoOrdenadoPorLocacao(user, boxes, detailsMap, event_id, event?.nome)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  

  const handleRelatorioPorFormaDePagamento = () => {
    if (formData?.forma_de_pagamento) {
      setLoading(true);
      relatorioPorFormaDePagamento(
        user,
        boxes,
        detailsMap,
        event_id,
        formData?.forma_de_pagamento, event?.nome
      )
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      toast.error("Selecione uma forma de pagamento!");
    }
  };

  const handleRelatorioPorVendedor = () => {
    if (formData?.vendedor) {
      setLoading(true);
      relatorioPorVendedor(
        user,
        boxes,
        detailsMap,
        event_id,
        formData?.vendedor,
        formData?.vendedor_nome, 
        event?.nome
      )
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    } else {
      toast.error("Selecione um vendedor!");
    }
  };

  const handleRelatorioGeralCsv = () => {
    setLoading(true);
    getRelatorioGeralCsv()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };
  return (
    <section className="tab-reports-event">
      {loading ? (
        <article
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            padding: "10%",
          }}
        >
          <ReactLoading
            type="spinningBubbles"
            color="var(--rotary-gold)"
            height={"5%"}
            width={"5%"}
          />
        </article>
      ) : (
        <article className="tab-reports-event-cards">
          <h3>Relatórios diversos</h3>
          {/* <span>{event_id}</span> */}
          <ol>
            <li>
              <button onClick={() => handleRelatorioGeralCsv()}>
                Relatório geral em csv <BsFiletypeCsv size={18} color="green" />
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  handleRelatorioGeralPdf();
                }}
              >
                Relatório geral <BsFileEarmarkPdf size={18} color="red" />
              </button>
            </li>

            <li>
              <select
                name="vendedor"
                value={formData?.vendedor}
                onChange={(e) => {
                  console.log(vendedores);
                  const selectedVendedor = [
                    { id: "0", email: "semvendedor", nome: "Sem vendedor" },
                    ...vendedores,
                  ].find((item) => item.email === e.target.value);
                  if (selectedVendedor) {
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      vendedor: selectedVendedor.email,
                      vendedor_nome: selectedVendedor.nome,
                    }));
                  }
                }}
              >
                <option value={null} selected disabled hidden>
                  Escolha um vendedor...
                </option>
                {[
                  {
                    id: "semvendedor",
                    email: "semvendedor",
                    nome: "Sem vendedor",
                  },
                  ...vendedores,
                ]?.map((item) => (
                  <option key={item?.email} value={item?.email}>
                    {item?.nome}
                  </option>
                ))}
              </select>

              <button onClick={() => handleRelatorioPorVendedor()}>
                Relatório por vendedor{" "}
                <BsFileEarmarkPdf size={18} color="red" />
              </button>
            </li>

            <li>
              <select
                name="forma_de_pagamento"
                value={formData?.forma_de_pagamento}
                onChange={handleInputChange}
              >
                <option value="" selected disabled hidden>
                  Escolha a forma de pagamento...
                </option>
                <option value={"dinheiro"}>Dinheiro</option>
                <option value={"pix"}>Pix</option>
                <option value={"cheque"}>Cheque</option>
                <option value={"outro"}>Outro</option>
              </select>
              <button
                onClick={() => {
                  handleRelatorioPorFormaDePagamento();
                }}
              >
                Relatório por forma de pagamento{" "}
                <BsFileEarmarkPdf size={18} color="red" />
              </button>
            </li>

            
            <li>
              <button
                onClick={() => {
                  handleRelatorioResumido();
                }}
              >
                Relatório resumido ordenado por comprador <BsFileEarmarkPdf size={18} color="red" />
              </button>
            </li>

        
            <li>
              <button
                onClick={() => {
                  handleRelatorioResumidoOrdenadoLugares();
                }}
              >
                Relatório resumido ordenado alfabeticamente por lugares <BsFileEarmarkPdf size={18} color="red" />
              </button>
            </li>

            <li>
              <button
                onClick={() => {
                  handleRelatorioResumidoOrdenadoLocacao();
                }}
              >
                Relatório resumido ordenado por numero de locação <BsFileEarmarkPdf size={18} color="red" />
              </button>
            </li>

          </ol>
        </article>
      )}
    </section>
  );
}
