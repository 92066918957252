import { memo } from 'react'

export const Box = memo(function Box({ number,detailsMap, preview }) {
  const styles = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    // width: '48.5px',
    width: detailsMap?.locacao_width_pixels ? `${detailsMap.locacao_width_pixels}px`: 20,
    height: '48.5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'move',
  }
  const backgroundColor = "var(--grass)";
  return (
    <div
      style={{ ...styles, backgroundColor, color: 'var(--white)' }}
      role={preview ? 'BoxPreview' : 'Box'}
    >
     <span >{number}</span>
   
    </div>
  )
})
