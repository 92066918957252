import React, { useState, useEffect, useContext } from "react";

import "./modalEditUser.css";
import { format } from "date-fns";
import { AuthContext } from "../../contexts/auth";

import { AiOutlineClose } from "react-icons/ai";
import {
  IoLocationOutline,
  IoCaretUp,
  IoCaretDown,
  IoOptions,
} from "react-icons/io5";
import config from "../../config";
import { toast } from "react-toastify";

export default function ModalEditUser({
  closeModalEdit,
  userEdit,
  dataLocacao,
  senhaContemNumero,
  senhaContemLetra,
  senhaPossuiMinimoCaracteres,
}) {
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: null,
    user_email: null,
    password: null,
    edit_password: null,
    admin: false,
  });
  useEffect(() => {
    getUser();
    return () => ({});
  }, [userEdit]);

  async function getUser() {
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/users/get-data-user`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
          iduser: userEdit,
        },
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao buscar usuários";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // json deve ser uma lista de eventos

      // Use setEvents para salvar os eventos no estado do seu componente
      setFormData({
        ...formData,

        name: json?.name,
        user_email: json?.email,
        password: json?.password,
        admin: json?.admin,
      });
      //   console.log(user_result);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao buscar os usuarios!"
      );
    }
  }

  const handleFormSubmit = async (e) => {
    try {
      setLoading(true);

      const response = await fetch(
        `${config.urlServer}/users/update-data-user`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: user.email,
            token: user.token,
            iduser: userEdit,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao atualizar usuário";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // json deve ser uma lista de eventos
      console.log(json);

      // Use setEvents para salvar os eventos no estado do seu componente
      // setFormData({...formData, ...json});
      //   console.log(user_result);
      closeModalEdit();
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao atualizar usuário!"
      );
    }

    e.preventDefault();
    console.log(formData);
  };

  const handleInputChange = (e, index, fieldName) => {
    const { value, name } = e.target;

    const updatedFormData = { ...formData };

    if (name === "edit_password" || name === "admin") {
      updatedFormData[name] = value === "true";
    } else {
      updatedFormData[name] = value;
    }

    setFormData(updatedFormData);
    // console.log(updatedFormData);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (
      formData?.edit_password &&
      (!senhaContemLetra(formData?.password) ||
        !senhaContemNumero(formData?.password) ||
        !senhaPossuiMinimoCaracteres(formData?.password))
    ) {
      toast.error("Verifique a senha, ela não atende os requisitos mínimos.");
    } else {
      handleFormSubmit(e);
    }
    // console.log(formData)
  }

  if (!userEdit) {
    <section className="modal-edit-user">
      <h3>Carregando...</h3>
    </section>
  }

  return (
    <section className="modal-edit-user">
      <header>
        <IoOptions color="rgb(50,50,50)" size={24} />
        <div className="view-number-locacao">
          <h3>Editar usuário</h3>
        </div>
        <button onClick={closeModalEdit}>
          <AiOutlineClose color="rgb(50,50,50)" size={24} />
        </button>
      </header>

      <form className="form-edit-users" onSubmit={handleSubmit}>
        <label>
          Nome
          <input
            type="text"
            name="name"
            value={formData?.name}
            // onChange={(e) => setName(e.target.value)}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Email
          <input
            type="text"
            name="user_email"
            value={formData?.user_email}
            readOnly
            disabled
          />
        </label>

        <label>
          Senha
          <input
            type="text"
            name="password"
            value={formData?.password}
            onChange={handleInputChange}
          />
          {formData?.edit_password &&
            (!senhaContemLetra(formData?.password) ||
              !senhaContemNumero(formData?.password) ||
              !senhaPossuiMinimoCaracteres(formData?.password)) && (
              <div className="required-password">
                <span>A senha deve: </span>
                <span
                  style={{
                    color: senhaPossuiMinimoCaracteres(formData?.password)
                      ? "var(--grass)"
                      : "var(--cardinal)",
                  }}
                >
                  - conter no mínimo de 6 carcteres.
                </span>
                <span
                  style={{
                    color: senhaContemNumero(formData?.password)
                      ? "var(--grass)"
                      : "var(--cardinal)",
                  }}
                >
                  - conter no mínimo de 1 número.
                </span>
                <span
                  style={{
                    color: senhaContemLetra(formData?.password)
                      ? "var(--grass)"
                      : "var(--cardinal)",
                  }}
                >
                  - conter no mínimo de 1 letra.
                </span>
              </div>
            )}
          {formData?.password && (
            <select
              name="edit_password"
              value={formData?.edit_password}
              onChange={handleInputChange}
            >
              <option value="" selected disabled hidden>
                Tem certeza que deseja alterar a senha?
              </option>
              <option value={true}>Sim, alterar a senha deste usuário.</option>
              <option value={false}>
                Não, não alterar a senha deste usuário.
              </option>
            </select>
          )}
        </label>

        <label>
          Usuário é administrador?
          <select
            name="admin"
            value={formData?.admin}
            onChange={handleInputChange}
          >
            <option value={true}>Sim, o usário é administrador.</option>
            <option value={false}>Não, o usuário não é administrador.</option>
          </select>
        </label>

        <button type="submit">Atualizar dados do usuário</button>
      </form>
    </section>
  );
}
