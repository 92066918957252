import { memo } from 'react'
const styles = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  width: '48.5px',
  height: '48.5px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'move',
}
export const Box = memo(function Box({ title, yellow, preview, status }) {
  const backgroundColor = status === 'reserved' ? 'orange':  status === 'sold' ? 'red' : status === 'free' ? 'green' : 'white'
  return (
    <div
      style={{ ...styles, backgroundColor }}
      role={preview ? 'BoxPreview' : 'Box'}
    >
      {title}
    </div>
  )
})
