import { memo, useEffect } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { Box } from './Box.js'
import { ItemTypes } from './ItemTypes.js'
function getStyles(x, y, isDragging) {
  const transform = `translate3d(${x}px, ${y}px, 0)`
  return {
    position: 'absolute',
    transform,
    WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : '',
  }
}
export const DraggableBox = memo(function DraggableBox(props) {
  const { id, title, numero, x, y, status, detailsMap,locacao } = props
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.BOX,
      item: { id, x, y, title },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, x, y, title],
  )
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })

  }, [])
  return (
    <div
      ref={drag}
      style={getStyles(x, y, isDragging)}
      role="DraggableBox"
    >
      <Box title={title} status={status} numero={numero} detailsMap={detailsMap} locacao={locacao}/>
    </div>
  )
})
