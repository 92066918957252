import React, { useState, useEffect, useContext } from "react";

import "./modalTransferQueueForTable.css";
import { format } from "date-fns";
import { AuthContext } from "../../contexts/auth";

import { AiOutlineClose } from "react-icons/ai";
import { IoLocationOutline, IoCaretUp, IoCaretDown } from "react-icons/io5";
import config from "../../config";
import { toast } from "react-toastify";
import { ImUpload } from "react-icons/im";
import { el } from "date-fns/locale";

export default function ModalTransferQueueForTable({
  closeModal,
  boxes,
  _dataLocacao,
  queueIdEdit,
  id_map,
  ordem,
  updateTransferLocalBoxes,
  }) {
  const [dataLocacao, setDataLocacao] = useState(null);
  const [formData, setFormData] = useState({
    
    id_map,
    queueIdEdit,
    locacao_destino: null

  });
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    return () => ({});
  }, [dataLocacao]);

  
  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    updateTable(formData);


  };



  const handleInputChange = (e, index, fieldName) => {
    const { value, name } = e.target;

    const updatedFormData = { ...formData };

      updatedFormData[name] = value;

    setFormData(updatedFormData);
    console.log(updatedFormData);
  };



  useEffect(() => {
    if (_dataLocacao?._id) {
      console.log(boxes);
    }
  }, [_dataLocacao?._id]);

  


  async function updateTable(data_edit) {

    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/event/queue/queue-transfer`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: user.email,
          token: user.token,
          data_edit,
        }),
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao editando o evento";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      console.log(json)
      setLoading(false);
      toast.success("Evento editado com sucesso!");
      updateTransferLocalBoxes(json?.alteracoes)

      closeModal();
    } catch (error) {
      console.error(error.toString());
      setLoading(false);
      toast.error(error.toString() || "Ops, algo deu errado ao editar o evento!");
    }
  }



  if (loading) {
    return (
      <div>
        <span>LOADING...</span>
      </div>
    );
  }

  return (
    <section className="modal-transfer-locacao">
      <header>
        <IoLocationOutline color="rgb(50,50,50)" size={24} />
        <div className="view-number-locacao">
          <h3>Ordem número:</h3>
          <h3>{ordem}</h3>
        </div>
        <button onClick={closeModal}>
          <AiOutlineClose color="rgb(50,50,50)" size={24} />
        </button>
      </header>

      <section className="modal-transfer-locacao-section-form">
        <form onSubmit={handleFormSubmit}>
          <label>
          <span style={{ minWidth: 140 }}>Movendo pessoa da lista de espera para locação...</span>

            <span style={{ minWidth: 140 }}>Escolha a locação de destino:</span>
            <select
              name="locacao_destino"
              value={formData?.locacao_destino}
              onChange={handleInputChange}
            >
                 <option key={0} value={null}>Selecione a locação de destino...</option>
              {boxes.map((element) => {
                if (element?.status === "disponivel") {
                  return (
                    <option key={element?.numero} value={element?._id}>
                      Locação número: {element?.numero} - {element?.status === 'disponivel' ? 'Disponível': element?.status }
                    </option>
                  );
                } else {
                  return (
                    <option
                      key={element?.numero}
                      disabled
                      value={element?._id}
                      style={{color: 'red'}}
                    >
                      Locação número: {element?.numero} - {element?.status === 'reservado' ? 'Reservado': element?.status === 'vendido' ? 'Vendido' : element?.status }
                    </option>
                  );
                }
              })}
            </select>
          </label>
          <button className="btn-action-save-modal-transfer" type="submit">
            Mover dados para a nova locação
          </button>
        </form>
      </section>
    </section>
  );
}
