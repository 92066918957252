import React, { useState, useEffect, useContext } from "react";

import "./modalUpdateTable.css";
import { format } from "date-fns";
import { AuthContext } from "../../contexts/auth";

import { AiOutlineClose } from "react-icons/ai";
import { IoLocationOutline, IoCaretUp, IoCaretDown } from "react-icons/io5";
import config from "../../config";
import { toast } from "react-toastify";
import { ImUpload } from "react-icons/im";

import ReactLoading from "react-loading";


export default function ModalUpdateTable({
  closeModal,
  boxes,
  _dataLocacao,
  vendedores,
  id_event,
  id_map,
  updateLocalBoxes,
  openModalTransfer,
}) {
  const [dataLocacao, setDataLocacao] = useState(null);
  const [formData, setFormData] = useState({
    id_event,
    id_map,
    id_locacao: _dataLocacao?._id,

    eventMapCopy: _dataLocacao?.mapCopy,
    status: dataLocacao?.status,
    qtd_lugares: dataLocacao?.lugares?.length,
    valor_total_locacao: dataLocacao?.valor_total_locacao,
    valor_recebido: dataLocacao?.valor_recebido,
    anotacoes_financeiras: dataLocacao?.anotacoes_financeiras
      ? dataLocacao?.anotacoes_financeiras
      : "",
    lugares: dataLocacao?.lugares || [],
    comprador: dataLocacao?.comprador,
    forma_de_pagamento: dataLocacao?.forma_de_pagamento,
  });
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // getListMaps();
    // setFormData({ ...formData, [name]: value });
    return () => ({});
  }, [dataLocacao]);

  const teste = () => {
    console.log(dataLocacao);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
    updateTable(formData);
    // if (user.email && user.token) {
    //   if (
    //     !formData.nome ||
    //     !formData.descricao ||
    //     !formData.localizacao ||
    //     !formData.data
    //   ) {
    //     toast.error("Preencha todos os campos!");
    //     return;
    //   }
    //   const { nome, descricao, data, localizacao } = formData;
    //   ModalUpdateTable(nome, descricao, data, localizacao);
    // } else {
    //   console.log(user);
    //   console.log("Dados do formulário:", formData);
    // }
  };

  const handleInputChange__ = (e) => {
    const { name, value } = e.target;

    const updatedFormData = { ...formData };

    if (name.includes("lugares")) {
      const [parentName, childName] = name.split(".");
      updatedFormData[parentName][childName] = value;
    } else {
      updatedFormData[name] = value;
    }

    setFormData(updatedFormData);
    // console.log(updatedFormData);
  };

  const handleInputChange = (e, index, fieldName) => {
    const { value, name } = e.target;

    const updatedFormData = { ...formData };

    if (name.includes("lugares")) {
      updatedFormData.lugares[index][fieldName] = value;
    } else {
      updatedFormData[name] = value;
    }

    setFormData(updatedFormData);
    // console.log(updatedFormData);
  };

  const increment = () => {
    // Incrementa o valor de qtd_lugares e atualiza o estado
    let lugares = formData.lugares;
    lugares.push({ nome: "", documento: "" });
    setFormData({
      ...formData,
      qtd_lugares: formData.qtd_lugares + 1,
      valor_total_locacao: parseFloat(
        parseInt(formData.qtd_lugares + 1) *
          parseFloat(dataLocacao.valor_por_lugar)
      ),
      lugares: lugares,
    });
  };

  const decrement = () => {
    // Decrementa o valor de qtd_lugares, mas não permite que ele seja menor que 0
    let lugares = formData.lugares;
    console.log(lugares[lugares.length - 1].nome);
    if (formData.qtd_lugares > 1) {
      if (
        lugares[lugares.length - 1]?.nome === "" &&
        lugares[lugares.length - 1]?.documento === ""
      ) {
        lugares.pop();

        setFormData({
          ...formData,
          qtd_lugares: formData.qtd_lugares - 1,
          valor_total_locacao: parseFloat(
            parseInt(formData.qtd_lugares - 1) *
              parseFloat(dataLocacao.valor_por_lugar)
          ),
        });
      } else {
        toast.error("A ultima linha contém dados.");
      }
    }
  };

  useEffect(() => {
    if (_dataLocacao?._id) {
      getDataLocacao(_dataLocacao?._id);

      console.log(boxes);
    }
  }, [_dataLocacao?._id]);

  const __handleInputChange = (e, index, fieldName) => {
    const { value, name } = e.target;

    const updatedFormData = { ...formData };

    if (name.includes("lugares")) {
      updatedFormData.lugares[index][fieldName] = value;
    } else {
      updatedFormData[name] = value;
    }

    setFormData(updatedFormData);
    console.log(updatedFormData);
  };

  const handleEditAllForm = async (json) => {
    const updatedFormData = { ...formData };
    updatedFormData.status = json?.status ? json?.status : "";
    updatedFormData.qtd_lugares = json?.lugares?.length
      ? json?.lugares?.length
      : 0;
    updatedFormData.valor_total_locacao = json?.valor_total_locacao
      ? json?.valor_total_locacao
      : 0;
    updatedFormData.valor_recebido = json?.valor_recebido
      ? json?.valor_recebido
      : 0;
    updatedFormData.anotacoes_financeiras = json?.anotacoes_financeiras
      ? json?.anotacoes_financeiras
      : "";

    updatedFormData.lugares = json?.lugares || [];
    updatedFormData.comprador = json?.comprador ? json?.comprador : "";
    updatedFormData.vendedor = json?.vendedor ? json?.vendedor : "";
    updatedFormData.vendedor_nome = json?.vendedor_nome ? json?.vendedor_nome : "";


    updatedFormData.forma_de_pagamento = json?.forma_de_pagamento
      ? json?.forma_de_pagamento
      : "";

    setFormData(updatedFormData);
    console.log(updatedFormData);
  };
  const getDataLocacao = async (idLocacao) => {
    try {
      setLoading(true);

      const response = await fetch(
        `${config.urlServer}/event/get-data-locacao`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: user.email,
            token: user.token,
            id_map,
            id_locacao: idLocacao,
          }),
        }
      );

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao buscar locação";
        throw new Error(errorMessage);
      }

      const json = await response.json();
      console.log(json);
      setDataLocacao(json?.locacao);
      handleEditAllForm(json?.locacao);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error(
        error.msg || "Ops, algo deu errado ao buscar informações da locação!"
      );
    }
  };

  async function updateTable(data_edit) {
    // cons saveTable = () => {}
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/event/edit-locacao`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: user.email,
          token: user.token,
          data_edit,
        }),
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao editando o evento";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      const eventData = {
        eventId: json.event_id, // Substitua por um valor real, se necessário
        nome: json.nome,
        descricao: json.descricao,
        data: json.data,
        localizacao: json.localizacao,
      };

      setLoading(false);
      toast.success("Evento editado com sucesso!");
      updateLocalBoxes(_dataLocacao?._id, json.locacao);
      closeModal();
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error(error.msg || "Ops, algo deu errado ao editar o evento!");
    }
  }

  async function get() {
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/maps/get-maps`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
        },
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao buscar os mapas";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // json deve ser uma lista de eventos
      const maps = json.map((map) => ({
        mapId: map._id,
        nome: map.nome,
        tipo_locacao: map.tipo_locacao,
        created_at: map.created_at,
      }));

      // Use setEvents para salvar os eventos no estado do seu componente
      // setListMaps([{ value: "", nome: "Selecione uma localização" }, ...maps]);
      console.log(maps);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao buscar os eventos!"
      );
    }
  }

  if (!dataLocacao) {
    return (
      <div>
        <span>Carregando...</span>
      </div>
    );
  }

  return (
    <section className="modal-update-locacao">
      <header>
        <IoLocationOutline color="rgb(50,50,50)" size={24} />
        <div className="view-number-locacao">
          <h3>Locação número:</h3>
          <h3>{dataLocacao?.numero}</h3>
        </div>
        <button onClick={closeModal}>
          <AiOutlineClose color="rgb(50,50,50)" size={24} />
        </button>
      </header>
      {loading ? (
        <article
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            padding: "10%",
            width: "100%"
          }}
        >
          <ReactLoading
            type="spinningBubbles"
            color="var(--rotary-gold)"
            height={"10%"}
            width={"10%"}
            
          />
        </article>
      ) : (
      <section className="modal-update-locacao-section-form" >
        <form onSubmit={handleFormSubmit} >
          <div className="modal-update-locacao-section-form-div" >
            <section>
              <article>
                <label>
                  <span style={{ minWidth: 140 }}>Status da locação:</span>
                  <select
                    name="status"
                    value={formData?.status}
                    onChange={handleInputChange}
                  >
                    <option value={"disponivel"}>Disponível</option>
                    <option value={"reservado"}>Reservado</option>
                    <option value={"vendido"}>Vendido</option>
                  </select>
                </label>
              </article>

              <article>
                <label>
                  <span> Comprador:</span>
                  <input
                    width={"100%"}
                    type="text"
                    name="comprador"
                    value={formData?.comprador}
                    onChange={handleInputChange}
                    placeholder="Nome do comprador"
                  />
                </label>
              </article>

              <article className="article-lista-lugares">
                <label>
                  <span>Lugares: </span>
                  <button type="button" onClick={decrement}>
                    <IoCaretDown className="icon-decrement-increment" />
                  </button>{" "}
                  <span>{formData.qtd_lugares}</span>
                  <button type="button" onClick={increment}>
                    <IoCaretUp className="icon-decrement-increment" />
                  </button>
                </label>
                {dataLocacao?.lugares.map((item, index) => {
                  return (
                    <div key={index}>
                      <p>{index + 1}</p>
                      <input
                        type="text"
                        name={`lugares[${index}].nome`}
                        placeholder={`Nome `}
                        value={item?.nome}
                        onChange={(e) => handleInputChange(e, index, "nome")}
                      />
                      <input
                        type="text"
                        name={`lugares[${index}].documento`}
                        placeholder={`Documento ${index + 1}`}
                        value={item?.documento}
                        onChange={(e) =>
                          handleInputChange(e, index, "documento")
                        }
                      />
                    </div>
                  );
                })}
              </article>
            </section>

            <section>
              <article>
                {/* <label>
                  <span> Vendedor:</span>
                  <select
                    name="vendedor"
                    value={formData?.vendedor}
                    onChange={handleInputChange}
                  >
                    <option value="" selected disabled hidden>
                      Escolha um vendedor...
                    </option>
                    {vendedores?.map((item) => {
                      return <option value={item?.email}>{item?.nome}</option>;
                    })}
                  </select>

                  
                </label> */}

<label>
      <span> Vendedor:</span>
      <select
        name="vendedor"
        value={formData?.vendedor}
        onChange={(e) => {
          const selectedVendedor = vendedores.find(
            (item) => item.email === e.target.value
          );
          if (selectedVendedor) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              vendedor: selectedVendedor.email,
              vendedor_nome: selectedVendedor.nome,
            }));
          }
        }}
      >
        <option value="" selected disabled hidden>
          Escolha um vendedor...
        </option>
        {vendedores?.map((item) => (
          <option key={item.email} value={item.email}>
            {item.nome}
          </option>
        ))}
      </select>
    </label>
              </article>
              <article>
                <label>
                  <span style={{ minWidth: 150 }}>Valor total:</span>
                  <input
                    type="number"
                    name="valor_total_locacao"
                    value={formData.valor_total_locacao}
                    onChange={handleInputChange}
                    placeholder="0,00"
                  />
                </label>
              </article>
              <article>
                <label>
                  <span style={{ minWidth: 150 }}>Valor recebido:</span>
                  <input
                    width={"100%"}
                    type="number"
                    name="valor_recebido"
                    value={formData.valor_recebido}
                    onChange={handleInputChange}
                    placeholder="0,00"
                  />
                </label>
              </article>

              <article>
                <label>
                  <span>Forma de pagamento:</span>
                  <select
                    name="forma_de_pagamento"
                    value={formData.forma_de_pagamento}
                    onChange={handleInputChange}
                  >
                    {/* "disponivel", "vendido", "reservado" */}
                    <option value="" selected disabled hidden>
          Escolha a forma de pagamento...
        </option>
                    <option value={"dinheiro"}>Dinheiro</option>
                    <option value={"pix"}>Pix</option>
                    <option value={"cheque"}>Cheque</option>
                    <option value={"outro"}>Outro</option>
                  </select>
                </label>
              </article>

              <article>
                <label style={{ alignItems: "start" }}>
                  <span>Anotações financeiras:</span>
                  <textarea
                    name="anotacoes_financeiras"
                    value={formData.anotacoes_financeiras}
                    onChange={handleInputChange}
                    placeholder="Digite as anotações financeiras aqui..."
                  />
                </label>
              </article>
            </section>
          </div>

          <button className="btn-action-save-modal" type="submit">
            Salvar
          </button>
        </form>
        <button
          className="btn-action-transfer-data"
          onClick={() => openModalTransfer()}
        >
          Transferir
          {/* <ImUpload /> */}
        </button>
      </section>
         )}
    </section>
  );
}
