import React, { useState, useEffect, useContext } from "react";

import "./modalEditQueue.css";
import { format } from "date-fns";
import { AuthContext } from "../../contexts/auth";

import { AiOutlineClose } from "react-icons/ai";
import {
  IoLocationOutline,
  IoCaretUp,
  IoCaretDown,
  IoOptions,
} from "react-icons/io5";
import config from "../../config";
import { toast } from "react-toastify";

export default function ModalEditQueue({
  closeModalEdit,
  queueIdEdit,
  id_map,
  vendedores,
  ordem,
  updateTransferLocalBoxes,
  dataEdit
}) {
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    id_map,
    queueIdEdit,
    comprador: dataEdit?.comprador || "",
    vendedor: dataEdit?.vendedor || "",
    anotacoes: dataEdit?.anotacoes || "",
    status: dataEdit?.status || ""
  });


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const response = await fetch(
        `${config.urlServer}/event/queue/queue-update`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: user.email,
            token: user.token,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao atualizar usuário";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // json deve ser uma lista de eventos
      console.log(json);

      // Use setEvents para salvar os eventos no estado do seu componente
      // setFormData({...formData, ...json});
      //   console.log(user_result);
      closeModalEdit();
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao atualizar usuário!"
      );
    }

    e.preventDefault();
    console.log(formData);
  };

  const handleInputChange = (e, index, fieldName) => {
    const { value, name } = e.target;

    const updatedFormData = { ...formData };

    if (name.includes("lugares")) {
      updatedFormData.lugares[index][fieldName] = value;
    } else {
      updatedFormData[name] = value;
    }

    setFormData(updatedFormData);
    // console.log(updatedFormData);
  };

  async function handleSubmit(e) {
    // e.preventDefault();

    // console.log(formData)
    handleFormSubmit(e);
  }

  if (!queueIdEdit) {
    <section className="modal-edit-user">
      <h3>Carregando...</h3>
    </section>
  }

  return (
    <section className="modal-edit-user">
      <header>
        <IoOptions color="rgb(50,50,50)" size={24} />
        <div className="view-number-locacao">
          <h3>Editar registro</h3>
        </div>
        <button onClick={closeModalEdit}>
          <AiOutlineClose color="rgb(50,50,50)" size={24} />
        </button>
      </header>

      <form className="form-edit-users" onSubmit={handleSubmit}>
        <label>
          Comprador
          <input
            type="text"
            name="comprador"
            value={formData?.comprador}
            onChange={handleInputChange}
          />
        </label>

        <label>
          Vendedor
          <select
            name="vendedor"
            value={formData?.vendedor}
            onChange={handleInputChange}
          >
            <option value="" selected disabled hidden>
              Escolha um...
            </option>
            {vendedores?.map((item) => {
              return <option value={item?.email}>{item?.nome}</option>;
            })}
          </select>
        </label>

        <label>
          Anotações
          <input
            type="text"
            name="anotacoes"
            value={formData?.anotacoes}
            onChange={handleInputChange}
          />
        </label>

        <label>
          Status
          <select
            name="status"
            value={formData?.status}
            onChange={handleInputChange}
          >
            <option value="" selected disabled hidden></option>
         
             <option value={'alocado'}>Alocado</option>
            
             <option value={'espera'}>Espera</option>
          </select>
        </label>

        <button type="submit">Atualizar dados do usuário</button>
      </form>
    </section>
  );
}
