import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/auth";
import { useParams, useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Title from "../../components/Title";
import { FiPlus, FiMessageSquare, FiSearch, FiEdit2 } from "react-icons/fi";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import listProcess from "../../api/listProcess.json";
import { Link } from "react-router-dom";
import config from "../../config";

import { IoOptions } from "react-icons/io5";

import { format } from "date-fns";
import Modal from "react-modal";
import "./event.css";
import CreateEvent from "../../components/CreateEvent";
import Tables from "./Tables";
import TablesInteraction from "./TablesInteraction";
import ModalUpdateEvent from "../../components/ModalUpdateEvent";
import EditEvent from "./EditEvent";
import { HiDocumentReport } from "react-icons/hi";
import { MdOutlineSave } from "react-icons/md";

import { TbUsersGroup } from "react-icons/tb";

import { BiSolidEdit, BiSolidEditLocation } from "react-icons/bi";
import Reports from "./Reports";
import { toast } from "react-toastify";
import Queue from "./Queue";
// import background from "../;
export default function Event() {
  const { logout, user } = useContext(AuthContext);
  const { id } = useParams();

  const [event, setEvent] = useState(null);
  const [boxes, setBoxes] = useState([]);
  const [ingressos_avulsos, setIngressosAvulsos] = useState([]);

  const [loading, setLoading] = useState(true);

  const [isEmpty, setIsEmpty] = useState(false);
  const [lastDocs, setLastDocs] = useState();
  const [loadingMore, setLoadingMore] = useState(false);

  const [showPostModal, setShowPostModal] = useState(false);
  const [detail, setDetail] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("interaction");
  const [mapId, setMapId] = useState(null);

  useEffect(() => {
    async function loadEvent() {
      getEvent();
      setLoading(false);
    }

    loadEvent();

    return () => {};
  }, [id]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "90%", // Defina a largura desejada
      maxWidth: "900px", // Defina uma largura máxima opcional
      height: "auto", // Defina a altura desejada (exemplo: 60% da altura da viewport)
      minHeight: "70vh",
      maxHeight: "90vh", // Defina uma altura máxima opcional
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.8)", // Cor de fundo do overlay (fundo escuro semitransparente)
      zIndex: 1000, // Ajusta a ordem de empilhamento para garantir que o modal esteja acima do conteúdo
    },
  };
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [detailsMap, setDetailsMap] = useState({
    img_height_pixels: 12,
    img_width_pixels: 92,
    locacao_height_pixels: 5,
    locacao_width_pixels: 5,
    url_img_map: "",
  });

  useEffect(() => {
    if (!loading) {
      const {
        _id,
        img_height_pixels,
        img_width_pixels,
        locacao_height_pixels,
        locacao_width_pixels,
        url_img_map,
        url_map_plan,
        valor_por_lugar
      } = event?.mapa_mesas;
      setDetailsMap({
        img_height_pixels,
        img_width_pixels,
        locacao_height_pixels,
        locacao_width_pixels,
        url_img_map,
        url_map_plan,
        valor_por_lugar
      });
      setMapId(_id);
      console.log("event::::", _id);
    }
  }, [event]);

  async function getEvent() {
    try {
      setLoading(true);
      // alert(id)

      const response = await fetch(`${config.urlServer}/event/get-event-id`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
          eventid: id,
        },
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao buscar os eventos";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      console.log(json);

      // json deve ser uma lista de eventos

      // Use setEvent para salvar os eventos no estado do seu componente
      setEvent(json.data);
      setBoxes(json?.data?.mapa_mesas.locacoes);
      setIngressosAvulsos(json?.data?.mapa_mesas?.ingressos_avulsos)
      console.log(json?.data?.mapa_mesas.locacoes);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao buscar os eventos!"
      );
    }
  }

  const handleEditTab = (value) => {
    setCurrentTab(value);
  };

  const [editingBoxes, setEditingBoxes] = useState(false);

  const handleOnChange = () => {
    setEditingBoxes(!editingBoxes);
  };

  const saveEditPositions =async () => {
    const isConfirmed = window.confirm("Tem certeza de que deseja salvar as alterações de posição?");
    if (isConfirmed) {
      try {
        setLoading(true);
  
        const response = await fetch(`${config.urlServer}/event/edit-posicoes`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: user.email,
            token: user.token,
            locacoes: boxes,
            event_id: id,
            id_map: event?.mapa_mesas?._id


          }),
        });
  
        if (response.status !== 200) {
          const errorData = await response.json();
          const errorMessage =
            errorData.msg || "Erro desconhecido ao editar o evento";
          throw new Error(errorMessage);
        }
  
        const json = await response.json();

  console.log(json)
        setLoading(false);
        toast.success("Evento editado com sucesso!");
        // getEvent()
      } catch (error) {
        console.error(error);
        setLoading(false);
        toast.error(error.msg || "Ops, algo deu errado ao editar o evento!");
      }

    } else {
      // Lógica a ser executada quando o usuário cancela
      console.log('Ação cancelada.');
    }
  }

  if (loading) {
    return (
      <div>
        <Header />

        <div className="content">
          <Title name={`Carregando...`}>
            <FiMessageSquare size={25} />
          </Title>

          <div className="container event">
            <span>Buscando dados do eventos...</span>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Header />

        <div className="content">
          <Title name={`${event?.nome}`}>
            <FiMessageSquare size={25} />
          </Title>
          <header className="event-view-header">
            <button
              onClick={() =>
                // openModal()
                handleEditTab("interaction")
              }
              className={
                currentTab === "interaction"
                  ? "event-view-header-btn-active"
                  : ""
              }
              // style={{
              //   fontWeight: currentTab ==="interaction" ? "bold" : "normal",
              //  border: currentTab ==="interaction" ? "1px solid var(--rotary-gold)": "0" ,
              //  color: currentTab ==="interaction" ? "var(--rotary-gold)" : "" ,
              //  fontSize: currentTab ==="interaction" ? "1.2em" : "" ,
              // }}
            >
              Locações
              <BiSolidEdit size={25} />
            </button>

            <button
              onClick={() =>
                // openModal()
                handleEditTab("queue")
              }
              className={
                currentTab === "queue" ? "event-view-header-btn-active" : ""
              }

            >
              Fila
              <TbUsersGroup size={25} />
            </button>


            <button
              onClick={() =>
                // openModal()
                handleEditTab("positionEdit")
              }
              className={
                currentTab === "positionEdit"
                  ? "event-view-header-btn-active"
                  : ""
              }
            >
              Posições
              <BiSolidEditLocation size={25} />
            </button>

            <button
              onClick={() =>
                // openModal()
                handleEditTab("reports")
              }
              className={
                currentTab === "reports"
                  ? "event-view-header-btn-active"
                  : ""
              }>
          Relatórios
          <HiDocumentReport color="#FfffFF" size={24} />

            </button>

            <button
              onClick={() =>
                // openModal()
                handleEditTab("editEvent")
              }
              className={
                currentTab === "editEvent" ? "event-view-header-btn-active" : ""
              }
            >
              Ajustes
              <IoOptions size={25} />
            </button>


            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
            >
              <ModalUpdateEvent closeModal={closeModal} boxes={boxes} />
            </Modal>
          </header>

          {currentTab === "positionEdit" ? (
            <>
              <Tables
                boxes={boxes}
                setEvent={setEvent}
                setBoxes={setBoxes}
                detailsMap={detailsMap}
                editingBoxes={editingBoxes}
              />
              <label>
                Editar posições?
                <input
                  type="checkbox"
                  id="topping"
                  name="topping"
                  value="Paneer"
                  checked={editingBoxes}
                  onChange={handleOnChange}
                />
              </label>
              <button onClick={()=>saveEditPositions()} className="btn-save-edit-position">
                Salvar alterações de posições
                <MdOutlineSave  size={25}/>

                </button>

            </>
          ) : currentTab === "editEvent" ? (
            <EditEvent event_id={id} event={event} getEvent={getEvent} />
          ) : currentTab === "interaction" ? (
            <div>
              <TablesInteraction
                boxes={boxes}
                ingressos_avulsos={ingressos_avulsos}
                setIngressosAvulsos={setIngressosAvulsos}
                setBoxes={setBoxes}
                detailsMap={detailsMap}
                id_event={id}
                vendedores={event?.vendedores}
                id_map={mapId}
              />
            </div>
          ) : currentTab === "reports" ? (
            <div>
              <Reports
                event_id={id}
                event={event}
                vendedores={event?.vendedores}
                boxes={boxes}
                detailsMap={detailsMap}
                // id_map={mapId}
              />
            </div>
          ) : currentTab === "queue" ? (
            <div>
              <Queue
                event_id={id}
                vendedores={event?.vendedores}
                id_map={mapId}
                boxes={boxes}
                setBoxes={setBoxes}
              />
            </div>
          )  : (
            <div>
              <p>Tela não definida</p>
            </div>
          )}
        </div>
        <br />
      </div>
    );
  }
}
