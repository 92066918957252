import update from "immutability-helper";
import { useCallback, useState } from "react";
import { useDrop } from "react-dnd";
import { DraggableBox } from "./DraggableBox.js";
import { ItemTypes } from "./ItemTypes.js";
import { snapToGrid as doSnapToGrid } from "./snapToGrid.js";


export const Container = ({ snapToGrid, moveBox, boxes, detailsMap }) => {

  let background = detailsMap?.url_img_map !== '' ? detailsMap?.url_img_map : detailsMap?.url_map_plan;

  const styles = {
    // width: 926,
    width: detailsMap?.img_width_pixels ? `${detailsMap?.img_width_pixels}px` : 926,
    height: detailsMap?.img_height_pixels ? `${detailsMap?.img_height_pixels}px` : 12,
    border: "1px solid black",
    position: "relative",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover", // Ajuste o tamanho da imagem de acordo com sua preferência
    backgroundRepeat: "no-repeat",
  };


  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        let x = Math.round(item.x + delta.x);
        let y = Math.round(item.y + delta.y);
        if (snapToGrid) {
          [x, y] = doSnapToGrid(x, y);
        }
        moveBox(item.id, x, y);
        return undefined;
      },
    }),
    [moveBox]
  );
  return (
    <div ref={drop} style={styles}>
      {Object.keys(boxes).map((key) => (
        // <div>
        //   <button onClick={()=>console.log(1)}>{boxes[key].status}</button>
        <DraggableBox key={key} id={boxes[key]._id} detailsMap={detailsMap} {...boxes[key]} locacao={boxes[key]}/>
        // </div>
      ))}
    </div>
  );
};
