import React, { useState, useEffect, useContext } from "react";

import "./modalDeleteEvent.css";
import { format } from "date-fns";
import { AuthContext } from "../../contexts/auth";

import { AiOutlineClose } from "react-icons/ai";
import {
  IoLocationOutline,
  IoCaretUp,
  IoCaretDown,
  IoOptions,
} from "react-icons/io5";
import config from "../../config";
import { toast } from "react-toastify";
import { IoLockClosedOutline } from "react-icons/io5";



export default function ModalDeleteEvent({
  closeModalDelete,
  event_id,
  nome_evento

}) {
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name_event_confirmation: "",
  });

  const handleFormSubmit = async (e) => {
 
    try {
      setLoading(true);

      const response = await fetch(
        `${config.urlServer}/event/delete-event`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: user.email,
            token: user.token,
            idevent: event_id,
          }
        }
      );

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao deletar evento";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // json deve ser uma lista de eventos
      console.log(json);
      toast.success('Evento deletado com sucesso!')
      closeModalDelete();

      // Use setEvents para salvar os eventos no estado do seu componente
      // setFormData({...formData, ...json});
      //   console.log(event_result);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao deletar evento!"
      );
    }

    e.preventDefault();
    // console.log(formData);
  };

  const handleInputChange = (e, index, fieldName) => {
    const { value, name } = e.target;

    const updatedFormData = { ...formData };


      updatedFormData[name] = value;

    setFormData(updatedFormData);
    // console.log(updatedFormData);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (
      nome_evento !== formData?.name_event_confirmation
    ) {
      toast.error("Nome não validado.");
    } else {
      handleFormSubmit(e);
    }
    // console.log(formData)
  }

  if (!event_id) {
    <section className="modal-edit-event">
      <h3>Carregando...</h3>
    </section>
  }

  return (
    <section className="modal-edit-event">
      <header>
        <IoOptions color="rgb(50,50,50)" size={24} />
        <div className="view-number-locacao">
          <h3>Deletar evento</h3>
        </div>
        <button onClick={closeModalDelete}>
          <AiOutlineClose color="rgb(50,50,50)" size={24} />
        </button>
      </header>

      <form className="form-edit-events" onSubmit={handleSubmit}>
        <span>Tem certeza que deseja deletar esse evento?</span>
        <span>Observe que esta ação não pode ser desfeita.</span>
        <span>
          Para prosseguir digite no campo abaixo o nome do evento{" "}
          <span
            style={{
              fontWeight: "bold",
              fontStyle: "italic",
              color: "var(--cardinal)",
            }}
          >
            {nome_evento}
            
          </span>
        </span>
        <label>
          <input
            type="text"
            name="name_event_confirmation"
            value={formData?.name_event_confirmation}
            onChange={handleInputChange}
          />
        </label>
        {nome_evento === formData.name_event_confirmation ? (
          <button type="submit">Deletar evento</button>
        ) : (
          <button disabled style={{opacity: .5}}><IoLockClosedOutline /></button>
        )}
      </form>
    </section>
  );
}
