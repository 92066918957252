import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import config from "../../../../config";
import { saveAs } from "file-saver";
import { formatarNumero } from "../../../../functions/formatarNumero";

let data_ = require("../relatorio_vendas.json.json");
async function getRelatorioResumidoOrdenadoPorLocacao(user, event_id) {
  try {
    const response = await fetch(
      `${config.urlServer}/event/relatorios/relatorio-resumido-ordenado-locacao`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
          eventid: event_id,
        },
      }
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      const errorMessage =
        responseData.msg || "Erro desconhecido ao buscar os eventos";
      throw new Error({ message: errorMessage, status: response.status });
    }

    console.log(responseData);

    let relatorio = `numero;status;vendedor;comprador;valor_total_locacao;valor_por_lugar;lugares;valor_recebido;forma_de_pagamento;\n`;

    if (responseData.locacoes) {
      for (let i = 0; i < responseData.locacoes.length; i++) {
        let element = responseData.locacoes[i];
        relatorio += `${element?.numero};${element?.status};${element?.vendedor};${element?.comprador};${element?.valor_total_locacao};${element?.valor_por_lugar};${element?.lugares.length};${element?.valor_recebido};${element?.forma_de_pagamento};\n`;
      }
    }
    // downloadJsonFile(responseData.locacoes, "relatorio_vendas.json");
    let locacoes = responseData.locacoes
    let ingressos_avulsos = responseData.ingressos_avulsos
    return {locacoes, ingressos_avulsos};
    // return responseData;
  } catch (error) {
    console.error(error);
    throw new Error(
      error.message || "Ops, algo deu errado ao gerar relatório!"
    );
  }
}

const downloadJsonFile = (content, name) => {
  const jsonBlob = new Blob([JSON.stringify(content)], {
    type: "application/json",
  });
  saveAs(jsonBlob, `${name}.json`);
};
export const relatorioResumidoOrdenadoPorLocacao = async (user, boxes, detailsMap, event_id, nomeEvento) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportTitle = [
    {
      text: `RELATÓRIO RESUMIDO E ORDENADO POR NÚMERO DA LOCAÇÃO`,
      fontSize: 15,
      bold: true,
      margin: [15, 20, 0, 45], // left, top, right, bottom
    },
  ];

  const data = await getRelatorioResumidoOrdenadoPorLocacao(user, event_id);
  // const data = data_;

  const dados = data?.locacoes?.map((item) => {
    const pagamento =
      parseFloat(item?.valor_total_locacao) -
        parseFloat(item?.valor_recebido) ===
        0 && parseFloat(item?.valor_total_locacao) !== 0
        ? "Pago"
        : parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) >
            0 &&
          parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) !==
            parseFloat(item?.valor_total_locacao)
        ? "Pago parcial"
        : parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) ===
          parseFloat(item?.valor_total_locacao)
        ? "Pendente"
        : parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) <
          0
        ? "Valor maior"
        : "Erro";

    let nomes = "";
    const buscaNomes = async () => {
      item.lugares.forEach(
        (element, index) =>
          (nomes += `${index + 1}. ${element?.nome} ${
            index !== item?.lugares?.length - 1 ? "  |  " : ""
          }`)
      );
    };
    buscaNomes();
    return [
      { text: item?.numero, fontSize: 9, margin: [0, 1, 0, 1] },
      { text: item?.comprador, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: item?.lugares.length, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: nomes, fontSize: 9, margin: [0, 2, 0, 2] },
    ];
  });

  
  const ingressos_avulsos = data?.ingressos_avulsos?.map((item) => {
    const pagamento =
      parseFloat(item?.valor_total_locacao) -
        parseFloat(item?.valor_recebido) ===
        0 && parseFloat(item?.valor_total_locacao) !== 0
        ? "Pago"
        : parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) >
            0 &&
          parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) !==
            parseFloat(item?.valor_total_locacao)
        ? "Pago parcial"
        : parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) ===
          parseFloat(item?.valor_total_locacao)
        ? "Pendente"
        : parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) <
          0
        ? "Valor maior"
        : "Erro";

    let nomes = "";
    const buscaNomes = async () => {
      item.lugares.forEach(
        (element, index) =>
          (nomes += `${index + 1}. ${element?.nome || ''} ${
            index !== item?.lugares?.length - 1 ? "  |  " : ""
          }`)
      );
    };
    buscaNomes();
    return [
      // { text: item?.numero, fontSize: 9, margin: [0, 1, 0, 1] },
      { text: item?.comprador, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: item?.lugares.length, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: nomes, fontSize: 9, margin: [0, 2, 0, 2] },
    ];
  });

  const details = [
    { text: "Locações/Mesas", style: "subheader" },
    {
      table: {
        headerRows: 1,
        widths: [28, 90,  48, 360],
        body: [
          [
            { text: "MESA", style: "tableHeader", fontSize: 10, bold: true, },
            { text: "COMPRADOR", style: "tableHeader", fontSize: 10, bold: true},
            { text: "LUGARES", style: "tableHeader", fontSize: 10, bold: true, },
            { text: "NOMES", style: "tableHeader", fontSize: 10 , bold: true,},
          ],
          ...dados,
        ],
      },
      //   layout: "lightHorizontalLines", // headerLineOnly
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex % 2 === 0 ? "#ddd" : null;
        },
      },
    },
    { text: "", pageBreak: "before" },
    { text: "Ingressos Avulsos", style: "subheader" },
    {
      table: {
        headerRows: 1,
        // widths: [28, 90,  48, 360],
        widths: [90,  48, 360],

        body: [
          [
            // { text: "MESA", style: "tableHeader", fontSize: 10, bold: true, },
            { text: "COMPRADOR", style: "tableHeader", fontSize: 10, bold: true},
            { text: "LUGARES", style: "tableHeader", fontSize: 10, bold: true, },
            { text: "NOMES", style: "tableHeader", fontSize: 10 , bold: true,},
          ],
          ...ingressos_avulsos,
        ],
      },
      //   layout: "lightHorizontalLines", // headerLineOnly
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex % 2 === 0 ? "#ddd" : null;
        },
      },
    },

    

    
  ];

  

  function Rodape(currentPage, pageCount) {
    return [
      {
        text: currentPage + " / " + pageCount,
        alignment: "right",
        fontSize: 9,
        margin: [0, 10, 20, 0], // left, top, right, bottom
      },
    ];
  }

  const docDefinitios = {
    pageSize: "A4",
    pageMargins: [15, 50, 15, 40],

    header: [reportTitle],
    content: [details],
    footer: Rodape,
  };

  // pdfMake.createPdf(docDefinitios).download();
    // Crie o PDF
const pdfDocGenerator = pdfMake.createPdf(docDefinitios);
  
// Gere um Blob a partir do PDF
pdfDocGenerator.getBlob((blob) => {
  // Crie um link temporário
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);

  // Defina o atributo "download" com o nome desejado do arquivo
  link.download = `Relatório Resumido E Ordenado Por Número Da Locação - ${nomeEvento || ''}.pdf`;

  // Adicione o link ao corpo da página e clique automaticamente nele
  document.body.appendChild(link);
  link.click();

  // Remova o link depois do download
  document.body.removeChild(link);
});
};
