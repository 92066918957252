import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/auth";

import Header from "../../components/Header";
import Title from "../../components/Title";
import { FiPlus, FiMessageSquare, FiSearch, FiEdit2 } from "react-icons/fi";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import listProcess from "../../api/listProcess.json";
import { Link } from "react-router-dom";
import config from "../../config";

import { format } from "date-fns";
import Modal from "react-modal";
import "./home.css";
import CreateEvent from "../../components/CreateEvent";

export default function Home() {
  const { logout, user } = useContext(AuthContext);

  const [events, setEvents] = useState(listProcess);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "90%", // Defina a largura desejada
      maxWidth: "850px", // Defina uma largura máxima opcional
      height: "auto", // Defina a altura desejada (exemplo: 60% da altura da viewport)
      minHeight: "60vh",
      maxHeight: "80vh", // Defina uma altura máxima opcional
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.8)", // Cor de fundo do overlay (fundo escuro semitransparente)
      zIndex: 1000, // Ajusta a ordem de empilhamento para garantir que o modal esteja acima do conteúdo
    },
  };

  useEffect(() => {
    async function loadEvents() {
      getEvents();
      setLoading(false);
    }

    loadEvents();

    return () => {};
  }, []);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    getEvents();
    setModalIsOpen(false);
  };

  async function getEvents() {
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/event/get-events`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
        },
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao buscar os eventos";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // json deve ser uma lista de eventos
      const events = json.map((event) => ({
        eventId: event._id,
        nome: event.nome,
        descricao: event.descricao,
        data: event.data,
        // localizacao: event.localizacao,
      }));
      console.log(events);
      // Use setEvents para salvar os eventos no estado do seu componente
      setEvents(events);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao buscar os eventos!"
      );
    }
  }

  if (loading) {
    return (
      <div>
        <Header />

        <div className="content">
          <Title name="Eventos">
            <FiMessageSquare size={25} />
          </Title>

          <div className="container home">
            <span>Buscando eventos...</span>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Header />

        <div className="content">
          <Title name="Eventos">
            <FiMessageSquare size={25} />
          </Title>

          <button onClick={openModal} className="btn-create-event">
            Criar evento
            <MdOutlineCreateNewFolder size={25} />
          </button>

          <section className="view-list-cards-event">
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
            >
              <CreateEvent closeModal={closeModal} />
            </Modal>

            {events.length === 0 ? (
              <div className="container home">
                <span>Nenhum evento encontrado...</span>
              </div>
            ) : (
              events?.map((item, index) => {
                const data = new Date(item?.data);
                const formattedDate = `${data.getDate()}/${
                  data.getMonth() + 1
                }/${data.getFullYear()}`;

                return (
                  <Link
                    key={index}
                    className="card-list-event-item"
                    to={`/event/${item?.eventId}`}
                  >
                    {/* <div className="card-event-view-status"><span>{item.status}</span></div> */}
                    <div className="view-title-event">
                      <span>{item?.nome}</span>
                    </div>
                    <div>{item?.descricao}</div>
                    <div className="view-date">
                      <span>Data do evento: {formattedDate}</span>
                    </div>
                  </Link>
                );
              })
            )}
          </section>
        </div>
      </div>
    );
  }
}
