import { Routes, Route } from 'react-router-dom'

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Home from '../pages/Home'
import Profile from '../pages/Profile'
import Customers from '../pages/Customers'
import New from '../pages/New'

import Private from './Private'
import Tables from '../pages/Tables';
import Maps from '../pages/Maps';
import Event from '../pages/Event';
import Users from '../pages/Users';
import EditMap from '../pages/EditMap';
import MapPlans from '../pages/MapPlans';

function RoutesApp(){
  return(
    <Routes>
      <Route path="/" element={ <SignIn/> } />
      <Route path="/register" element={ <SignUp/> } />
      <Route path="/home" element={ <Private><Home/></Private> } />

      <Route path="/event/:id" element={<Private><Event/></Private>} />



      <Route path="/users" element={ <Private><Users/></Private> } />

      <Route path="/create-map" element={ <Private><Maps/></Private> } />
      <Route path="/edit-map" element={ <Private><EditMap/></Private> } />
      <Route path="/map-plans" element={ <Private><MapPlans/></Private> } />



      <Route path="/tables" element={ <Private><Tables/></Private> } />
    
          
      <Route path="/profile" element={ <Private><Profile/></Private> } />

      <Route path="/customers" element={<Private><Customers/></Private>} />
      
      <Route path="/new" element={<Private><New/></Private>} />

      <Route path="/new/:id" element={<Private><New/></Private>} />


   
    </Routes>
  )
}

export default RoutesApp;