import React, { useState, useEffect, useContext } from "react";

import "./createEvent.css";
import { FcRefresh, FcCancel } from "react-icons/fc";
import { format } from "date-fns";
import { AuthContext } from "../../contexts/auth";

import { AiOutlineClose } from "react-icons/ai";
import { addFirestore } from "../../services/dataBase/addFirestore";
import config from "../../config";
import { toast } from "react-toastify";
import { list } from "firebase/storage";

export default function CreateEvent({ closeModal, idCompany, idGroup }) {
  const [formData, setFormData] = useState({
    nome: "",
    descricao: "",
    data: "",
    localizacao: "",
  });
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getListMaps();
    // setFormData({ ...formData, [name]: value });
    return () => ({});
  }, [idCompany, idGroup]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (user.email && user.token) {
      if (
        !formData.nome ||
        !formData.descricao ||
        !formData.localizacao ||
        !formData.data
      ) {
        toast.error("Preencha todos os campos!");
        return;
      }
      const { nome, descricao, data, localizacao } = formData;
      createEvent(nome, descricao, data, localizacao);
    } else {
      console.log(user);
      console.log("Dados do formulário:", formData);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const saveEvent = () => {
    console.log("save ");
  };

  async function createEvent(nome, descricao, data, localizacao) {
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/event/create-event`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: user.email,
          token: user.token,
          nome,
          descricao,
          data,
          localizacao,
        }),
      });

      if (response.status !== 201) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao criar o evento";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      const eventData = {
        eventId: json.event_id, // Substitua por um valor real, se necessário
        nome: json.nome,
        descricao: json.descricao,
        data: json.data,
        localizacao: json.localizacao,
      };

      setLoading(false);
      toast.success("Evento criado com sucesso!");
      closeModal();
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error(error.msg || "Ops, algo deu errado ao criar o evento!");
    }
  }

  const [listMaps, setListMaps] = useState([
    { value: "", nome: "Selecione uma localização" },
  ]);

  async function getListMaps() {
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/maps/get-maps`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
        },
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao buscar os mapas";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // json deve ser uma lista de eventos
      const maps = json.map((map) => ({
        mapId: map._id,
        nome: map.nome,
        tipo_locacao: map.tipo_locacao,
        created_at: map.created_at,
      }));

      // Use setEvents para salvar os eventos no estado do seu componente
      setListMaps([{ value: "", nome: "Selecione uma localização" }, ...maps]);
      console.log(maps);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao buscar os eventos!"
      );
    }
  }

  return (
    <section className="create-event-view">
      <div>
        <h2>Evento</h2>
        <button onClick={closeModal}>
          <AiOutlineClose color="rgb(50,50,50)" size={24} />
        </button>
      </div>

      <section className="create-event-section">
        <h3>Criar novo evento</h3>
        <form onSubmit={handleFormSubmit}>
          <div>
            <label>
              Nome do evento:
              <input
                type="text"
                name="nome"
                value={formData.nome}
                onChange={handleInputChange}
                placeholder="Nome do evento"
              />
            </label>
          </div>
          <div>
            <label style={{ width: "100%" }}>
              Descrição do evento:
              <input
                width={"100%"}
                type="text"
                name="descricao"
                value={formData.descricao}
                onChange={handleInputChange}
                placeholder="Descrição"
              />
            </label>
          </div>
          <div>
            <label>
              Data do evento:
              <input
                type="datetime-local"
                name="data"
                value={formData.data}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div>
            <label>
              Local:
              <select
                name="localizacao"
                value={formData.localizacao}
                onChange={handleInputChange}
              >
                {listMaps.map((item) => (
                  <option key={item.mapId} value={item.mapId}>
                    {item.nome} {" "}
                    {item.tipo_locacao === "cadeiras"
                      ? "- Cadeiras"
                      : item.tipo_locacao === "mesas"
                      ? "- Mesas"
                      : null} {" "}
                      {item?.created_at ? format(new Date(item?.created_at), "- dd/MM/yyyy, HH:mm"): null}
         
                  </option>
                ))}
              </select>
            </label>
          </div>

          <button className="btn-action-create-event" type="submit">
            Salvar
          </button>
        </form>
      </section>
    </section>


  );
}
