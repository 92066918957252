import React, { useState, useEffect, useContext } from "react";

import "./modalDeleteUser.css";
import { format } from "date-fns";
import { AuthContext } from "../../contexts/auth";

import { AiOutlineClose } from "react-icons/ai";
import {
  IoLocationOutline,
  IoCaretUp,
  IoCaretDown,
  IoOptions,
} from "react-icons/io5";
import config from "../../config";
import { toast } from "react-toastify";
import { IoLockClosedOutline } from "react-icons/io5";



export default function ModalDeleteUser({
  closeModalDelete,
  userEdit,
  dataLocacao,
  senhaContemNumero,
  senhaContemLetra,
  senhaPossuiMinimoCaracteres,
}) {
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    user_email: "",
    email_confirmation: "",
  });
  useEffect(() => {
    getUser();
    return () => ({});
  }, [userEdit]);

  async function getUser() {
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/users/get-data-user`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
          iduser: userEdit,
        },
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao buscar usuários";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // json deve ser uma lista de eventos

      // Use setEvents para salvar os eventos no estado do seu componente
      setFormData({
        ...formData,

        name: json?.name,
        user_email: json?.email,
        password: json?.password,
        admin: json?.admin,
      });
      //   console.log(user_result);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao buscar os usuarios!"
      );
    }
  }

  const handleFormSubmit = async (e) => {
    try {
      setLoading(true);

      const response = await fetch(
        `${config.urlServer}/users/delete-user`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: user.email,
            token: user.token,
            iduser: userEdit,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao atualizar usuário";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // json deve ser uma lista de eventos
      console.log(json);
      toast.success('Usuário deletado com sucesso!')
      closeModalDelete();

      // Use setEvents para salvar os eventos no estado do seu componente
      // setFormData({...formData, ...json});
      //   console.log(user_result);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao atualizar usuário!"
      );
    }

    e.preventDefault();
    console.log(formData);
  };

  const handleInputChange = (e, index, fieldName) => {
    const { value, name } = e.target;

    const updatedFormData = { ...formData };

    if (name === "edit_password" || name === "admin") {
      updatedFormData[name] = value === "true";
    } else {
      updatedFormData[name] = value;
    }

    setFormData(updatedFormData);
    // console.log(updatedFormData);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (
      formData?.edit_password &&
      (!senhaContemLetra(formData?.password) ||
        !senhaContemNumero(formData?.password) ||
        !senhaPossuiMinimoCaracteres(formData?.password))
    ) {
      toast.error("Verifique a senha, ela não atende os requisitos mínimos.");
    } else {
      handleFormSubmit(e);
    }
    // console.log(formData)
  }

  if (!userEdit) {
    <section className="modal-edit-user">
      <h3>Carregando...</h3>
    </section>;
  }

  return (
    <section className="modal-edit-user">
      <header>
        <IoOptions color="rgb(50,50,50)" size={24} />
        <div className="view-number-locacao">
          <h3>Deletar usuário</h3>
        </div>
        <button onClick={closeModalDelete}>
          <AiOutlineClose color="rgb(50,50,50)" size={24} />
        </button>
      </header>

      <form className="form-edit-users" onSubmit={handleSubmit}>
        <span>Tem certeza que deseja deletar esse usuário?</span>
        <span>Observe que esta ação não pode ser desfeita.</span>
        <span>
          Para prosseguir digite no campo abaixo o email do mesmo{" "}
          <span
            style={{
              fontWeight: "bold",
              fontStyle: "italic",
              color: "var(--cardinal)",
            }}
          >
            {formData.user_email}
          </span>
        </span>
        <label>
          <input
            type="text"
            name="email_confirmation"
            value={formData?.email_confirmation}
            onChange={handleInputChange}
          />
        </label>
        {formData.user_email === formData.email_confirmation ? (
          <button type="submit">Deletar usuário</button>
        ) : (
          <button disabled style={{opacity: .5}}><IoLockClosedOutline /></button>
        )}
      </form>
    </section>
  );
}
