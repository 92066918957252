// import Example from "./src/example_";
import React, { useState, useEffect, useContext, useCallback } from "react";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { AuthContext } from "../../contexts/auth";
import { format } from "date-fns";

import config from "../../config";

import { MdOutlineSave } from "react-icons/md";

import "./tables.css";

import { Mesas } from "./src/Mesas";

import Header from "../../components/Header";
import Title from "../../components/Title";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import Form from "./Form";
import { Link } from "react-router-dom";
export default function EditMap(
  {
    // boxes,
    //  setBoxes,
    // detailsMap,
    //  editingBoxes
  }
) {
  const [editingBoxes, setEditingBoxes] = useState(false);

  const handleOnChange = () => {
    setEditingBoxes(!editingBoxes);
  };

  const [boxes, setBoxes] = useState([]);

  const [detailsMap, setDetailsMap] = useState({
    img_height_pixels: 12,
    img_width_pixels: 92,
    locacao_height_pixels: 5,
    locacao_width_pixels: 5,
    url_img_map: "",
  });

  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    localizacao: "",
  });
  const [listMaps, setListMaps] = useState([
    { value: "", nome: "Selecione uma localização" },
  ]);
  const [dataMap, setDataMap] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    getListMaps();
    return () => {
      // Cleanup, se necessário
    };
  }, []);

  async function getListMaps() {
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/maps/get-maps`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
        },
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao buscar os mapas";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // json deve ser uma lista de eventos
      const maps = json.map((map) => ({
        mapId: map?._id,
        nome: map?.nome,
        tipo_locacao: map?.tipo_locacao,
        created_at: map?.created_at,
      }));

      // Use setEvents para salvar os eventos no estado do seu componente
      setListMaps([{ value: "", nome: "Selecione uma localização" }, ...maps]);
      console.log(maps);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao buscar os eventos!"
      );
    }
  }

  async function getMap(id) {
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/maps/get-map`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
          idmap: id,
        },
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao buscar os mapas";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      setLoading(false);

      return json;
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao buscar os eventos!"
      );
    }
  }

  const handleEdit = async (data) => {
    console.log(data);
    await getMap(data.localizacao).then((result) => {
      let map = result?.map;
      console.log(result?.map);
      setDataMap("id");

      setBoxes(map?.lugares);
      setDetailsMap({
        img_height_pixels: map?.img_height_pixels || 0,
        img_width_pixels: map?.img_width_pixels || 0,
        locacao_height_pixels: map?.locacao_height_pixels || 0,
        locacao_width_pixels: map?.locacao_width_pixels || 0,
        url_img_map: map?.url_img_map || "",
      });

      setFormData({
        ...formData,
        nome: map?.nome || "",
        descricao: map?.descricao || "",
        tipo_locacao: map?.tipo_locacao || "mesas",
        urlOrSelect: map?.urlOrSelect || "",
        map_url: map?.url_img_map || "",
        map_plan: map?.map_plan || "" ,
        quantidade_de_locacoes: map?.quantidade_de_locacoes || 0,
        lugares_por_locacao: map?.lugares_por_locacao || 0,
        locacao_width_pixels: map?.locacao_width_pixels || 0,
        locacao_height_pixels: map?.locacao_height_pixels || 0,
        img_width_pixels: map?.img_width_pixels || 0,
        img_height_pixels: map?.img_height_pixels || 0,
      });
    });
  };

  const moveBox = (id, x, y) => {
    console.log(id, x, y, editingBoxes);
    if (editingBoxes) {
      moveBox_(id, x, y);
    }
  };

  const moveBox_ = useCallback((id, x, y) => {
    setBoxes((prevBoxes) => {
      const updatedBoxes = prevBoxes.map((box) =>
        box.id === id ? { ...box, x, y } : box
      );
      console.log(updatedBoxes); // Log the updated boxes
      return updatedBoxes;
    });
  }, []);

  const _moveBox_ = useCallback((id, x, y) => {
    console.log(boxes);
    const updatedBoxes = [...boxes];
    const index = updatedBoxes.findIndex((box) => box.id === id);

    if (index !== -1) {
      console.log("oi", index);
      // Atualize a cor do item no índice encontrado
      updatedBoxes[index] = {
        ...updatedBoxes[index],
        x: x,
        y: y,
      };
      setBoxes(updatedBoxes);
      // boxes = updatedBoxes;
      // Atualize o estado com o novo array
    }
    // setBoxes((prevBoxes) => ({
    //   ...prevBoxes,
    //   [id]: { ...prevBoxes[id], x, y },
    // }));
    console.log(boxes);
  }, []);

  const saveEdit = async () => {
    const isConfirmed = window.confirm(
      "Tem certeza de que deseja salvar as alterações de posição?"
    );
    if (isConfirmed) {
      try {
        setLoading(true);

        const response = await fetch(`${config.urlServer}/maps/edit-map`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: user.email,
            token: user.token,
            locacoes: boxes,
            id_map: formData.localizacao,
            formData,
          }),
        });

        if (response.status !== 200) {
          const errorData = await response.json();
          const errorMessage =
            errorData.msg || "Erro desconhecido ao editar o evento";
          throw new Error(errorMessage);
        }

        const json = await response.json();

        console.log(json);
        setLoading(false);
        toast.success("Mapa editado com sucesso!");
        // getEvent()
      } catch (error) {
        console.error(error);
        setLoading(false);
        toast.error(error.msg || "Ops, algo deu errado ao editar o evento!");
      }
    } else {
      // Lógica a ser executada quando o usuário cancela
      console.log("Ação cancelada.");
    }
  };

  const [mapPlansList, setMapPlansList] = useState([]);

  useEffect(() => {
    getListMapPlans();
  }, []);
  async function getListMapPlans() {
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/map-plans/list`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
        },
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao buscar os mapas";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // json deve ser uma lista de eventos
      console.log(json);
      setMapPlansList(json);
      // console.log(maps);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao buscar os eventos!"
      );
    }
  }


  if (dataMap === null) {
    return (
      <div>
        <Header />

        <div className="content">
          <Title name={`Selecione o mapa para editar`}>
            {/* <FiMessageSquare size={25} /> */}
          </Title>

          <section className="view-list-maps-edit">
            <h3>Listagem dos mapas</h3>
            <select
              name="localizacao"
              value={formData.localizacao}
              onChange={handleInputChange}
            >
              {listMaps.map((item) => (
                <option key={item.mapId} value={item.mapId}>
                  {item.nome}{" "}
                  {item.tipo_locacao === "cadeiras"
                    ? "- Cadeiras"
                    : item.tipo_locacao === "mesas"
                    ? "- Mesas"
                    : null}{" "}
                  {item?.created_at
                    ? format(new Date(item?.created_at), "- dd/MM/yyyy, HH:mm")
                    : null}
                </option>
              ))}
            </select>

            <button
              onClick={() => {
                handleEdit(formData);
              }}
            >
              Editar
            </button>
          </section>

          <h4 style={{ padding: 10 }}>Ou</h4>

          <section className="section-view-create-map-link">
            <Link to={"/create-map"}>Criar mapa</Link>

            <Link
              to={"/map-plans"}
              style={{ marginTop: 5, width: 170, textAlign: "left" }}
            >
              Plantas dos mapas
            </Link>
          </section>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header />

      <div className="content">
        <Title name={`Selecione o mapa para editar`}>
          {/* <FiMessageSquare size={25} /> */}
        </Title>

        <div className="view-buttons-edit-map">

          <label>
            Deseja editar posições?
            <input
              type="checkbox"
              id="topping"
              name="topping"
              value="Paneer"
              checked={editingBoxes}
              onChange={handleOnChange}
            />
          </label>
          
          <button onClick={() => saveEdit()} className="btn-save-edit-position">
            Salvar alterações
            <MdOutlineSave size={25} />
          </button>

        </div>

        <Form
          formData={formData}
          handleInputChange={handleInputChange}
          setFormData={setFormData}
          mapPlansList={mapPlansList}
          boxes={boxes}
          setBoxes={setBoxes}
        />

        <div style={{ display: "flex", flexDirection: "row" }}>
          <DndProvider backend={HTML5Backend}>
            {/* <span>
        {boxes.length} {editingBoxes.toString()}
      </span> */}
            {/* <input
        type="checkbox"
        id="topping"
        name="topping"
        value="Paneer"
        checked={editingBoxes}
        onChange={handleOnChange}
      /> */}
            <Mesas moveBox={moveBox} boxes={boxes} detailsMap={detailsMap} />
          </DndProvider>
          <article
            className="view-editing-input-positions"
            style={{
              maxHeight: `${detailsMap.img_height_pixels}px`,
              overflowY: "auto",
            }}
          >
            <h2>Lista de locações</h2>
            <ul>
              {boxes?.map((box) => (
                <li key={box?.id}>
                  <strong>
                    {box?.number} {`=>`}
                  </strong>

                  <label>
                    X:{" "}
                    <input
                      type="number"
                      value={box?.x}
                      onChange={(e) => moveBox(box?.id, e.target.value, box?.y)}
                    />
                  </label>
                  <label>
                    Y:{" "}
                    <input
                      type="number"
                      value={box?.y}
                      onChange={(e) => moveBox(box?.id, box?.x, e.target.value)}
                    />
                  </label>
                </li>
              ))}
            </ul>
          </article>
        </div>
                  
        <button onClick={() => saveEdit()} className="btn-save-edit-position">
            Salvar alterações
            <MdOutlineSave size={25} />
          </button>
      </div>

      <Footer />
    </div>
  );
}
