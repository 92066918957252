import React, { useState, useEffect, useContext } from "react";

import "./modalUpdateIngressoAvulso.css";
import { format } from "date-fns";
import { AuthContext } from "../../contexts/auth";

import { AiOutlineClose } from "react-icons/ai";
import { IoLocationOutline, IoCaretUp, IoCaretDown } from "react-icons/io5";
import config from "../../config";
import { toast } from "react-toastify";
import { ImUpload } from "react-icons/im";

import ReactLoading from "react-loading";

export default function ModalUpdateIngressoAvulso({
  closeModal,
  boxes,
  _dataLocacao,
  vendedores,
  id_event,
  id_map,
  updateLocalBoxes,
  updateLocalIngressos,
  openModalDeleteIngresso
}) {
  const [dataLocacao, setDataLocacao] = useState(null);
  const [formData, setFormData] = useState({
    id_event,
    id_map,
    id_ingresso: _dataLocacao?._id,
    status: dataLocacao?.status,
    qtd_lugares: dataLocacao?.lugares?.length,
    valor_total_locacao: dataLocacao?.valor_total_locacao,
    valor_recebido: dataLocacao?.valor_recebido,
    anotacoes_financeiras: dataLocacao?.anotacoes_financeiras
      ? dataLocacao?.anotacoes_financeiras
      : "",
    lugares: dataLocacao?.lugares || [],
    comprador: dataLocacao?.comprador,
    forma_de_pagamento: dataLocacao?.forma_de_pagamento,
  });
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (_dataLocacao?._id) {
      getDataIngresso(_dataLocacao?._id);
    }
  }, [_dataLocacao?._id]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    updateIngresso(formData);
    console.log('update',formData)
  };

  const getDataIngresso = async (id_ingresso) => {
    try {
      setLoading(true);

      const response = await fetch(
        `${config.urlServer}/event/get-data-ingresso`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: user.email,
            token: user.token,
            id_map,
            id_ingresso: id_ingresso,
          }),
        }
      );

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao buscar locação";
        throw new Error(errorMessage);
      }

      const json = await response.json();
      console.log(json);
      setDataLocacao(json?.ingresso);
      handleEditAllForm(json?.ingresso);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error(
        error.msg || "Ops, algo deu errado ao buscar informações da locação!"
      );
    }
  };

  const handleInputChange = (e, index, fieldName) => {
    const { value, name } = e.target;

    const updatedFormData = { ...formData };

    if (name.includes("lugares")) {
      updatedFormData.lugares[index][fieldName] = value;
    } else {
      updatedFormData[name] = value;
    }

    setFormData(updatedFormData);
    // console.log(updatedFormData);
  };
  const handleEditAllForm = async (json) => {
    const updatedFormData = { ...formData };

    updatedFormData.status = json?.status ? json?.status : "";
    updatedFormData.comprador = json?.comprador ? json?.comprador : "";
    updatedFormData.lugares = json?.lugares || [];
    updatedFormData.vendedor = json?.vendedor ? json?.vendedor : "";
    updatedFormData.vendedor_nome = json?.vendedor_nome
      ? json?.vendedor_nome
      : "";
    updatedFormData.valor_total_locacao = json?.valor_total_locacao
      ? json?.valor_total_locacao
      : 0;
    updatedFormData.valor_recebido = json?.valor_recebido
      ? json?.valor_recebido
      : 0;
    updatedFormData.forma_de_pagamento = json?.forma_de_pagamento
      ? json?.forma_de_pagamento
      : "";

    updatedFormData.anotacoes_financeiras = json?.anotacoes_financeiras
      ? json?.anotacoes_financeiras
      : "";

    updatedFormData.qtd_lugares = json?.lugares?.length
      ? json?.lugares?.length
      : 0;

    setFormData(updatedFormData);
    // console.log(updatedFormData);
  };

  async function updateIngresso(data) {
    // cons saveTable = () => {}
    try {
      setLoading(true);

      const response = await fetch(
        `${config.urlServer}/event/update-ingresso`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: user.email,
            token: user.token,
            data,
          }),
        }
      );

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao editando o evento";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      const eventData = {
        eventId: json.event_id, // Substitua por um valor real, se necessário
        nome: json.nome,
        descricao: json.descricao,
        data: json.data,
        localizacao: json.localizacao,
      };

      setLoading(false);
      console.log('jsonjsonjson', json)
      toast.success("Evento editado com sucesso!");
      updateLocalIngressos(json?.ingressos_avulso)
      // updateLocalBoxes(_dataLocacao?._id, json.locacao);
      closeModal();
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error(error.msg || "Ops, algo deu errado ao editar o evento!");
    }
  }
  return (
    <section className="modal-update-locacao">
      <header>
        <IoLocationOutline color="rgb(50,50,50)" size={24} />
        <div className="view-number-locacao">
          <h3>Atualizar ingresso avulso:</h3>
          <h3>{_dataLocacao?.numero}</h3>
        </div>
        <button onClick={closeModal}>
          <AiOutlineClose color="rgb(50,50,50)" size={24} />
        </button>
      </header>
      {loading ? (
        <article
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            padding: "10%",
            width: "100%",
          }}
        >
          <ReactLoading
            type="spinningBubbles"
            color="var(--rotary-gold)"
            height={"10%"}
            width={"10%"}
          />
        </article>
      ) : (
        <section className="modal-update-locacao-section-form">
          <form onSubmit={handleFormSubmit}>
            <div className="modal-update-locacao-section-form-div">
              <section>
                <article>
                  <label>
                    <span style={{ minWidth: 140 }}>Status do ingresso:</span>
                    <select
                      name="status"
                      value={formData?.status}
                      onChange={handleInputChange}
                    >
                      <option value={"disponivel"}>Disponível</option>
                      <option value={"reservado"}>Reservado</option>
                      <option value={"vendido"}>Vendido</option>
                    </select>
                  </label>
                </article>

                <article>
                  <label>
                    <span> Comprador:</span>
                    <input
                      width={"100%"}
                      type="text"
                      name="comprador"
                      value={formData?.comprador}
                      onChange={handleInputChange}
                      placeholder="Nome do comprador"
                    />
                  </label>
                </article>

                <article className="article-lista-lugares">
                  <label>
                    <span>Lugar para: </span>
                    {/* <button type="button" onClick={teste}>
                    <IoCaretDown className="icon-decrement-increment" />
                  </button>{" "} */}
                    {/* <span>{formData.qtd_lugares}</span> */}
                    {/* <button type="button" onClick={teste}>
                    <IoCaretUp className="icon-decrement-increment" />
                  </button> */}
                  </label>
                  {formData?.lugares.map((item, index) => {
                    return (
                      <div key={index}>
                        {/* <p>{index + 1}</p> */}
                        <input
                          type="text"
                          name={`lugares[${index}].nome`}
                          placeholder={`Nome `}
                          value={item?.nome}
                          onChange={(e) => handleInputChange(e, index, "nome")}
                        />
                        <input
                          type="text"
                          name={`lugares[${index}].documento`}
                          placeholder={`Documento`}
                          value={item?.documento}
                          onChange={(e) =>
                            handleInputChange(e, index, "documento")
                          }
                        />
                      </div>
                    );
                  })}
                </article>
              </section>

              <section>
                <article>
                  <label>
                    <span> Vendedor:</span>
                    <select
                      name="vendedor"
                      value={formData?.vendedor}
                      onChange={(e) => {
                        const selectedVendedor = vendedores.find(
                          (item) => item.email === e.target.value
                        );
                        if (selectedVendedor) {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            vendedor: selectedVendedor.email,
                            vendedor_nome: selectedVendedor.nome,
                          }));
                        }
                      }}
                    >
                      <option value="" selected disabled hidden>
                        Escolha um vendedor...
                      </option>
                      {vendedores?.map((item) => (
                        <option key={item.email} value={item.email}>
                          {item.nome}
                        </option>
                      ))}
                    </select>
                  </label>
                </article>
                <article>
                  <label>
                    <span style={{ minWidth: 150 }}>Valor total:</span>
                    <input
                      type="number"
                      name="valor_total_locacao"
                      value={formData.valor_total_locacao}
                      onChange={handleInputChange}
                      placeholder="0,00"
                    />
                  </label>
                </article>
                <article>
                  <label>
                    <span style={{ minWidth: 150 }}>Valor recebido:</span>
                    <input
                      width={"100%"}
                      type="number"
                      name="valor_recebido"
                      value={formData.valor_recebido}
                      onChange={handleInputChange}
                      placeholder="0,00"
                    />
                  </label>
                </article>

                <article>
                  <label>
                    <span>Forma de pagamento:</span>
                    <select
                      name="forma_de_pagamento"
                      value={formData.forma_de_pagamento}
                      onChange={handleInputChange}
                    >
                      {/* "disponivel", "vendido", "reservado" */}
                      <option value="" selected disabled hidden>
                        Escolha a forma de pagamento...
                      </option>
                      <option value={"dinheiro"}>Dinheiro</option>
                      <option value={"pix"}>Pix</option>
                      <option value={"cheque"}>Cheque</option>
                      <option value={"outro"}>Outro</option>
                    </select>
                  </label>
                </article>

                <article>
                  <label style={{ alignItems: "start" }}>
                    <span>Anotações financeiras:</span>
                    <textarea
                      name="anotacoes_financeiras"
                      value={formData.anotacoes_financeiras}
                      onChange={handleInputChange}
                      placeholder="Digite as anotações financeiras aqui..."
                    />
                  </label>
                </article>
              </section>
            </div>

            <button className="btn-action-save-modal" type="submit">
              Salvar
            </button>

            <button
              className="btn-action-delete-ingresso"
              onClick={() => openModalDeleteIngresso()}
            >
              Deletar ingresso
            </button>
          </form>
        </section>
      )}
    </section>
  );
}
