import React, { useEffect, useState, useMemo } from "react";
import "./tablesInteraction.css";
import colors from "../../../config/colors.json";
import Modal from "react-modal";
import ModalUpdateTable from "../../../components/ModalUpdateTable";
import { GiSandsOfTime } from "react-icons/gi";
import { MdOutlineMoneyOffCsred, MdOutlineAttachMoney } from "react-icons/md";
import { FiAlertTriangle } from "react-icons/fi";
import ModalTransferTable from "../../../components/ModalTransferTable";
import Queue from "../Queue";
import { OverviewDetails } from "../Reports/OverviewDetails";
import ModalUpdateIngressoAvulso from "../../../components/ModalUpdateIngressoAvulso";
import ModalAddIngressoAvulso from "../../../components/ModalAddIngressoAvulso";
import ModalDeleteIngresso from "../../../components/ModalDeleteIngresso";
export default function TablesInteraction({
  detailsMap,
  boxes,
  ingressos_avulsos,
  setIngressosAvulsos,
  vendedores,
  id_event,
  id_map,
  setBoxes,
}) {
  const [itemOver, setItemOver] = useState(null);
  const [dataItemOver, setDataItemOver] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIngressoAvulsoIsOpen, setModalIngressoAvulsoIsOpen] = useState(
    false
  );
  const [
    modalUpdateIngressoAvulsoIsOpen,
    setModalUpdateIngressoAvulsoIsOpen,
  ] = useState(false);
  const [
    modalDeleteIngressoAvulsoIsOpen,
    setModalDeleteIngressoAvulsoIsOpen,
  ] = useState(false);

  const [modalTransferOpen, setModalTransferOpen] = useState(false);
  const [dataLocacao, setDataLocacao] = useState(null);
  const [popupX, setPopupX] = useState(0);
  const [popupY, setPopupY] = useState(0);
  const [overviewDetails, setOverviewDetails] = useState({});

  const styles = {
    width: detailsMap?.img_width_pixels
      ? `${detailsMap?.img_width_pixels}px`
      : 926,
    height: detailsMap?.img_height_pixels
      ? `${detailsMap?.img_height_pixels}px`
      : 1200,
    zIndex: 99,
    border: "1px dashed black",
    position: "relative",
    backgroundImage:
      detailsMap?.url_img_map !== ""
        ? `url(${detailsMap?.url_img_map})`
        : `url(${detailsMap?.url_map_plan})`,
    // backgroundImage:  `url(${detailsMap?.url_map_plan})` ,

    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "90%", // Defina a largura desejada
      maxWidth: "900px", // Defina uma largura máxima opcional
      height: "auto", // Defina a altura desejada (exemplo: 60% da altura da viewport)
      minHeight: "70vh",
      maxHeight: "90vh", // Defina uma altura máxima opcional
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.8)", // Cor de fundo do overlay (fundo escuro semitransparente)
      zIndex: 1000, // Ajusta a ordem de empilhamento para garantir que o modal esteja acima do conteúdo
    },
  };

  const openModal = (item) => {
    setDataLocacao(item);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalTransferOpen(false);
    setDataLocacao(null);
  };

  const openModalTransfer = () => {
    setModalIsOpen(false);
    setModalTransferOpen(true);
  };

  const openModalIngressoAvulso = (item) => {
    setDataLocacao(item);
    setModalIngressoAvulsoIsOpen(true);
  };
  const closeModalIngressoAvulso = () => {
    setModalIngressoAvulsoIsOpen(false);
    setModalTransferOpen(false);
    setDataLocacao(null);
  };

  const openModalUpdateIngressoAvulso = (item) => {
    setDataLocacao(item);
    setModalUpdateIngressoAvulsoIsOpen(true);
  };

  const openModalDeleteIngressoAvulso = () => {
    setModalUpdateIngressoAvulsoIsOpen(false);
    setModalDeleteIngressoAvulsoIsOpen(true);
  };

  const closeModalDeleteIngressoAvulso = () => {
    setDataLocacao(null);
    setModalDeleteIngressoAvulsoIsOpen(false);
    setModalIngressoAvulsoIsOpen(false);
  };
  const closeModalUpdateIngressoAvulso = () => {
    setModalUpdateIngressoAvulsoIsOpen(false);
    setDataLocacao(null);
  };

  const calculatePosition = useMemo(() => {
    return (item) => {
      const mapX = detailsMap?.x || 0;
      const mapY = detailsMap?.y || 0;
      const locacaoX = item.x || 0;
      const locacaoY = item.y || 0;
      const left = mapX + locacaoX;
      const top = mapY + locacaoY;
      return { left, top };
    };
  }, [detailsMap]);

  const handleMouseOut = () => {
    setItemOver(null);
    setDataItemOver(null);
    setPopupVisible(false);
  };

  const handleMouseOver = (itemId, event, item) => {
    // console.log(
    //   // itemId,
    //   //  event,
    //   item
    // );
    if (event && item) {
      setItemOver(itemId);
      setDataItemOver(item);
      setPopupVisible(true);
      // Define as coordenadas do popup com base na posição do mouse
      // setPopupX(event?.pageX);
      // setPopupY(event?.pageY);

      const offset = 100; // Ajuste esse valor conforme necessário
      setPopupX(event.pageX - offset);
      setPopupY(event.pageY - offset);
    } else {
      console.error("Evento inválido ou falta de informações de posição.");
    }
  };

  const tableItem = (item, index) => {
    const { left, top } = calculatePosition(item);
    // const background_color =
    //   item.status === "vendido"
    //     ? colors.grass
    //     : item.status === "reservado"
    //     ? colors.rotary_gold
    //     : item.status === "disponivel"
    //     ? colors.cardinal
    //     : "#eee";

    const background_color =
      item.status === "vendido"
        ? "var(--sky-blue)"
        : item.status === "reservado"
        ? "var(--rotary-gold)"
        : item.status === "disponivel"
        ? "var(--grass)"
        : "#eee";

    return (
      <button
        key={item._id}
        className="map-table-item-details"
        style={{
          backgroundColor:
            itemOver === item?._id ? "var(--cranberry)" : background_color,
          left: `${left}px`,
          top: `${top}px`,
          height: detailsMap?.locacao_height_pixels,
          width: detailsMap?.locacao_width_pixels,
        }}
        onMouseOver={(e) => handleMouseOver(item._id, e, item)}
        onMouseOut={handleMouseOut}
        onClick={() => openModal(item)}
      >
        <span>{`${item?.numero}`}</span>
      </button>
    );
  };

  const updateTransferLocalBoxes = (alteracoes) => {
    // updateLocalBoxes(__id_locacao, json?.locacao_de_origem)
    // updateLocalBoxes(__locacao_destino, json?.locacao_de_destino)

    // Crie uma cópia do array 'boxes'
    const updatedBoxes = [...boxes];

    for (let i = 0; i < alteracoes.length; i++) {
      console.log(alteracoes);
      const index = updatedBoxes.findIndex(
        (box) => box._id === alteracoes[i].id
      );

      if (index !== -1) {
        // Atualize a cor do item no índice encontrado
        updatedBoxes[index] = {
          ...updatedBoxes[index],
          comprador: alteracoes[i].dados?.comprador,

          forma_de_pagamento: alteracoes[i].dados?.forma_de_pagamento,
          lugares: alteracoes[i].dados?.lugares,
          mapCopy: alteracoes[i].dados?.mapCopy,
          numero: alteracoes[i].dados?.numero,
          status: alteracoes[i].dados?.status,
          valor_por_lugar: alteracoes[i].dados?.valor_por_lugar,
          valor_recebido: alteracoes[i].dados?.valor_recebido,
          valor_total_locacao: alteracoes[i].dados?.valor_total_locacao,
          vendedor: alteracoes[i].dados?.vendedor,
        };
        setBoxes(updatedBoxes);
        console.log(updatedBoxes[index]);
        // Atualize o estado com o novo array
      }
    }
  };

  const updateLocalBoxes = async (_id, ___locacao) => {
    // Crie uma cópia do array 'boxes'
    const updatedBoxes = [...boxes];

    // Encontre o índice do item que precisa ser atualizado
    const index = updatedBoxes.findIndex((box) => box._id === _id);
    console.log(___locacao);
    // Verifique se o item com o ID fornecido foi encontrado
    if (index !== -1) {
      // Atualize a cor do item no índice encontrado
      updatedBoxes[index] = {
        ...updatedBoxes[index],
        comprador: ___locacao.comprador,

        forma_de_pagamento: ___locacao.forma_de_pagamento,
        lugares: ___locacao.lugares,
        mapCopy: ___locacao.mapCopy,
        numero: ___locacao.numero,
        status: ___locacao.status,
        valor_por_lugar: ___locacao.valor_por_lugar,
        valor_recebido: ___locacao.valor_recebido,
        valor_total_locacao: ___locacao.valor_total_locacao,
        vendedor: ___locacao.vendedor,
        vendedor_nome: ___locacao.vendedor_nome,
      };

      // Atualize o estado com o novo array
      setBoxes(updatedBoxes);
    }
  };

  const updateLocalIngressos = async (ingresso) => {
    // Crie uma cópia do array 'ingressos'
    const updatedIngresso = [...ingressos_avulsos];

    // Encontre o índice do item que precisa ser atualizado
    const index = updatedIngresso.findIndex(
      (_ingresso) => _ingresso._id === ingresso?._id
    );

    // Verifique se o item com o ID fornecido foi encontrado
    if (index !== -1) {
      // Atualize a cor do item no índice encontrado
      updatedIngresso[index] = {
        ...updatedIngresso[index],
        ...ingresso,
      };

      // Atualize o estado com o novo array
      setIngressosAvulsos(updatedIngresso);
    }
  };

  const deleteLocalIngresso = (ingressoId) => {
    // Crie uma cópia do array de ingressos_avulsos
    const updatedIngressos = [...ingressos_avulsos];

    // Encontre o índice do ingresso a ser removido
    const index = updatedIngressos.findIndex(
      (ingresso) => ingresso._id === ingressoId
    );

    // Verifique se o ingresso com o ID fornecido foi encontrado
    if (index !== -1) {
      // Remova o ingresso do array
      updatedIngressos.splice(index, 1);

      // Atualize o estado com o novo array sem o ingresso removido
      setIngressosAvulsos(updatedIngressos);
    }
  };

  const _tableItemDetails = (item, index) => {
    const background_color =
      item.status === "vendido"
        ? colors.grass
        : item.status === "reservado"
        ? colors.rotary_gold
        : item.status === "disponivel"
        ? colors.cardinal
        : "#eee";

    const pagamento =
      parseFloat(item?.valor_total_locacao) -
        parseFloat(item?.valor_recebido) ===
      0
        ? "Pago"
        : parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) >
            0 &&
          parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) !==
            parseFloat(item?.valor_total_locacao)
        ? "Pago parcial"
        : parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) ===
          parseFloat(item?.valor_total_locacao)
        ? "Pendente"
        : "Erro";

    return (
      <button
        className="list-table-item-details"
        key={item?._id}
        style={{
          backgroundColor:
            itemOver === item?._id ? "var(--cranberry)" : background_color,
        }}
        onMouseOver={(e) => handleMouseOver(item._id, e, item)}
        onMouseOut={handleMouseOut}
      >
        <span>
          {item?.numero} - {item?.status} - {pagamento}
        </span>
      </button>
    );
  };

  const TableItemDetails = (item, index) => {
    const background_color =
      item.status === "vendido"
        ? "var(--sky-blue)"
        : item.status === "reservado"
        ? "var(--rotary-gold)"
        : item.status === "disponivel"
        ? "var(--grass)"
        : "#eee";
    const pagamento =
      parseFloat(item?.valor_total_locacao) -
        parseFloat(item?.valor_recebido) ===
        0 && parseFloat(item?.valor_total_locacao) !== 0
        ? "Pago"
        : parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) >
            0 &&
          parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) !==
            parseFloat(item?.valor_total_locacao)
        ? "Pago parcial"
        : parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) ===
          parseFloat(item?.valor_total_locacao)
        ? "Pendente"
        : parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) <
          0
        ? "Valor maior"
        : "Erro";

    return (
      <tr
        className="list-table-item-details"
        key={item?._id}
        style={{
          backgroundColor:
            itemOver === item?._id ? "var(--cranberry)" : background_color,
        }}
        onMouseOver={(e) => handleMouseOver(item._id, e, item)}
        onMouseOut={handleMouseOut}
        onClick={() => openModal(item)}
      >
        <td>{item?.numero}</td>
        <td>{item?.comprador}</td>
        <td>{item?.vendedor_nome}</td>
        <td>{item?.lugares?.length}</td>
        <td>{item?.status === "disponivel" ? "Disponível" : item?.status}</td>
        <td>{formatarNumero(item?.valor_total_locacao)}</td>
        <td>{formatarNumero(item?.valor_recebido)}</td>

        <td
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {pagamento}
          {pagamento === "Pendente" ? (
            <GiSandsOfTime size={25} />
          ) : pagamento === "Pago parcial" ? (
            <MdOutlineMoneyOffCsred size={25} />
          ) : pagamento === "Pago" ? (
            <MdOutlineAttachMoney size={25} />
          ) : pagamento === "Valor maior" ? (
            <FiAlertTriangle size={35} />
          ) : (
            ""
          )}{" "}
        </td>
      </tr>

      // <button
      //   className="list-table-item-details"
      //   key={item?._id}
      //   style={{
      //     backgroundColor:
      //       itemOver === item?._id ? colors.sky_blue : background_color,
      //   }}
      //   onMouseOver={(e) => handleMouseOver(item._id, e)}
      //   onMouseOut={handleMouseOut}
      // >
      //   <span>
      //     {item?.numero} - {item?.status} - {pagamento}
      //   </span>
      // </button>
    );
  };

  const IngressoAvulsoItemDetails = (item, index) => {
    const background_color =
      item.status === "vendido"
        ? "var(--sky-blue)"
        : item.status === "reservado"
        ? "var(--rotary-gold)"
        : item.status === "disponivel"
        ? "var(--grass)"
        : "#eee";
    const pagamento =
      parseFloat(item?.valor_total_locacao) -
        parseFloat(item?.valor_recebido) ===
        0 && parseFloat(item?.valor_total_locacao) !== 0
        ? "Pago"
        : parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) >
            0 &&
          parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) !==
            parseFloat(item?.valor_total_locacao)
        ? "Pago parcial"
        : parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) ===
          parseFloat(item?.valor_total_locacao)
        ? "Pendente"
        : parseFloat(item?.valor_total_locacao) -
            parseFloat(item?.valor_recebido) <
          0
        ? "Valor maior"
        : "Erro";

    return (
      <tr
        className="list-table-item-details"
        key={item?._id}
        style={{
          backgroundColor:
            itemOver === item?._id ? "var(--cranberry)" : background_color,
        }}
        onMouseOver={(e) => handleMouseOver(item._id, e, item)}
        onMouseOut={handleMouseOut}
        onClick={
          () => openModalUpdateIngressoAvulso(item)
          // console.log(item)
        }
      >
        <td>{item?.numero}</td>
        <td>{item?.comprador}</td>
        <td>{item?.vendedor_nome}</td>
        <td>{item?.lugares?.length}</td>
        <td>{item?.status === "disponivel" ? "Disponível" : item?.status}</td>
        <td>{formatarNumero(item?.valor_total_locacao)}</td>
        <td>{formatarNumero(item?.valor_recebido || 0)}</td>

        <td
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {pagamento}
          {pagamento === "Pendente" ? (
            <GiSandsOfTime size={25} />
          ) : pagamento === "Pago parcial" ? (
            <MdOutlineMoneyOffCsred size={25} />
          ) : pagamento === "Pago" ? (
            <MdOutlineAttachMoney size={25} />
          ) : pagamento === "Valor maior" ? (
            <FiAlertTriangle size={35} />
          ) : (
            ""
          )}{" "}
        </td>
      </tr>

      // <button
      //   className="list-table-item-details"
      //   key={item?._id}
      //   style={{
      //     backgroundColor:
      //       itemOver === item?._id ? colors.sky_blue : background_color,
      //   }}
      //   onMouseOver={(e) => handleMouseOver(item._id, e)}
      //   onMouseOut={handleMouseOut}
      // >
      //   <span>
      //     {item?.numero} - {item?.status} - {pagamento}
      //   </span>
      // </button>
    );
  };
  const popupStyle = {
    position: "absolute",
    left: `${popupX}px`,
    top: `${popupY}px`,
    backgroundColor: "white",
    padding: "10px",
    border: "1px solid black",
    display: popupVisible ? "block" : "none",
  };
  const formatarNumero = (numero) => {
    if (isNaN(numero)) {
      return "Número inválido";
    }

    // Formatação para separar milhares e decimais
    const formato = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formato.format(numero);
  };
  const [overviewStatistics, setOverviewStatistics] = useState({
    locacoes_vendidas: 0,
    locacoes_reservadas: 0,
    locacoes__disponiveis: 0,

    percentual_vendido: 0,
    percentual_reservado: 0,
    percentual_disponivel: 0,

    valor_vendido: 0,
    valor_reservado: 0,
    valor_disponivel: 0,

    vendido_recebebidos: 0,
    vendido_recebimentos_parcial: 0,
    vendido_recebimentos_pendente: 0,

    valor_vendido_recebebidos: 0,
    valor_vendido_recebimentos_parcial: 0,
    valor_vendido_recebimentos_pendente: 0,

    percentual_vendido_recebebidos: 0,
    percentual_vendido_recebimentos_parcial: 0,
    percentual_vendido_recebimentos_pendente: 0,
  });
  //  let locacoes_vendidas = 0;
  // let locacoes_reservadas = 0;
  // let locacoes__disponiveis = 0;

  const refreshOverview = async () => {
    let locacoes_vendidas = 0;
    let locacoes_reservadas = 0;
    let locacoes__disponiveis = 0;

    let valor_vendido = 0;
    let valor_reservado = 0;
    let valor_disponivel = 0;

    let vendido_recebebidos = 0;
    let vendido_recebimentos_parcial = 0;
    let vendido_recebimentos_pendente = 0;

    let valor_vendido_recebebidos = 0;
    let valor_vendido_recebimentos_parcial = 0;
    let valor_vendido_recebimentos_pendente = 0;

    for (let i = 0; i < boxes.length; i++) {
      let e = boxes[i];
      if (e.status === "vendido") {
        locacoes_vendidas += 1;
        valor_vendido += e?.valor_total_locacao;
        if (e?.valor_total_locacao === e?.valor_recebido) {
          vendido_recebebidos += 1;
          valor_vendido_recebebidos += e?.valor_recebido;
        } else if (
          e?.valor_total_locacao > e?.valor_recebido &&
          e?.valor_recebido !== 0
        ) {
          vendido_recebimentos_parcial += 1;
          valor_vendido_recebimentos_parcial += e?.valor_recebido;
        } else if (e?.valor_recebido === 0) {
          vendido_recebimentos_pendente += 1;
          valor_vendido_recebimentos_pendente += e?.valor_recebido;
        }

        console.log("vendido");
      } else if (e.status === "disponivel") {
        locacoes__disponiveis += 1;
        valor_disponivel += e?.valor_total_locacao;
      } else if (e.status === "reservado") {
        locacoes_reservadas += 1;
        valor_reservado += e?.valor_total_locacao;
      }
    }

    setOverviewStatistics((prev) => {
      const updatedBoxes = {
        ...prev,
        locacoes_vendidas,
        percentual_vendido: parseFloat(
          ((locacoes_vendidas / boxes.length) * 100)?.toFixed(2)
        ),
        locacoes_reservadas,
        percentual_reservado: parseFloat(
          ((locacoes_reservadas / boxes.length) * 100)?.toFixed(2)
        ),
        locacoes__disponiveis,
        percentual_disponivel: parseFloat(
          ((locacoes__disponiveis / boxes.length) * 100)?.toFixed(2)
        ),
        valor_vendido,
        valor_reservado,
        valor_disponivel,

        vendido_recebebidos,
        vendido_recebimentos_parcial,
        vendido_recebimentos_pendente,

        percentual_vendido_recebebidos: parseFloat(
          ((vendido_recebebidos / locacoes_vendidas) * 100)?.toFixed(2)
        ),
        percentual_vendido_recebimentos_parcial: parseFloat(
          ((vendido_recebimentos_parcial / locacoes_vendidas) * 100)?.toFixed(2)
        ),
        percentual_vendido_recebimentos_pendente: parseFloat(
          ((vendido_recebimentos_pendente / locacoes_vendidas) * 100)?.toFixed(
            2
          )
        ),

        valor_vendido_recebebidos,
        valor_vendido_recebimentos_parcial,
        valor_vendido_recebimentos_pendente,
      };
      return updatedBoxes;
    });

    // setOverviewStatistics((prev) => {
    //   // Verifica se há itens no array antes de tentar remover o último
    //   if (prevBoxes.length > 0) {
    //     const updatedBoxes = prevBoxes.slice(0, -1); // Remove o último item
    //     // console.log(updatedBoxes); // Log o array atualizado
    //     return updatedBoxes;
    //   } else {
    //     // Se o array estiver vazio, retorna o array atual sem fazer alterações
    //     return prevBoxes;
    //   }
    // });

    // overview.locacoes_vendidas =locacoes_vendidas
    // overview.locacoes__disponiveis = locacoes__disponiveis
    // overview.locacoes_reservadas = locacoes_reservadas
  };

  const refreshDetails = async () => {
    await OverviewDetails(boxes, ingressos_avulsos).then((result) => {
      setOverviewDetails(result);
    });
  };
  useEffect(() => {
    console.log(overviewStatistics);
    console.log(ingressos_avulsos);
    refreshOverview();
    refreshDetails();
  }, [boxes]);

  return (
    <div
      className="view-tables-interaction"
      style={{ display: "flex", position: "relative" }}
    >
      <div className="map-table" style={styles}>
        {boxes?.map((item) => {
          return tableItem(item);
        })}
      </div>
      <div
        //   style={popupStyle}
        style={{
          left: `${popupX}px`,
          top: `${popupY}px`,
          display: popupVisible ? "block" : "none",
          zIndex: 9999,
        }}
        className="table-item-popup-style"
      >
        <h3>
          Mesa {dataItemOver?.numero} -{" "}
          {dataItemOver?.status === "disponivel"
            ? "Disponível"
            : dataItemOver?.status}
        </h3>
        <section style={{ display: "flex", flexDirection: "column" }}>
          <span>Valor Total Locacão: {dataItemOver?.valor_total_locacao}</span>
          <span>Valor Recebido: {dataItemOver?.valor_recebido}</span>
          <span>Valor Por Lugar: {dataItemOver?.valor_por_lugar}</span>
          <span>Comprador: {dataItemOver?.comprador}</span>
          <span>Vendedor: {dataItemOver?.vendedor_nome || "Não definido"}</span>
          <span>
            Pessoas:{" "}
            {dataItemOver?.lugares?.map((item, index) => {
              return (
                <>
                  <br />
                  <span style={{ marginLeft: 45 }}>{`${index + 1} - ${
                    item?.nome
                  }`}</span>
                </>
              );
            })}
          </span>
        </section>
      </div>

      {/* <section
        className="section-list-table-item-details"
        style={{
          maxHeight: detailsMap?.img_height_pixels
            ? `${detailsMap?.img_height_pixels}px`
            : 1200,
        }}
      >
        {boxes?.map((item) => {
          return TableItemDetails(item);
        })}
      </section> */}
      <section
        className="section-list-table-item-details"
        style={{
          maxHeight: detailsMap?.img_height_pixels
            ? `${detailsMap?.img_height_pixels}px`
            : 1200,
        }}
      >
        <article className="overview-locacoes" style={{borderBottom:0}}>
          <table>
            <tr cosplan={4}>
              <h4>Status Locações/Mesas:</h4>
            </tr>
            <tr>
              <th>Desc.</th>
              <th>Qtd.</th>
              <th>%</th>
              <th>R$</th>
            </tr>
            <tr>
              <td>Vendidos</td>
              {/* <td>{overviewStatistics?.locacoes_vendidas}</td>
                <td>{overviewStatistics?.percentual_vendido}%</td>
                <td>{formatarNumero(overviewStatistics?.valor_vendido)}</td> */}

              <td>{overviewDetails?.locacoes_vendidas}</td>
              <td>{overviewDetails?.percentual_vendido}%</td>
              <td>{formatarNumero(overviewDetails?.valor_vendido)}</td>
            </tr>
            <tr>
              <td>Reservados</td>
              <td>{overviewDetails?.locacoes_reservadas}</td>
              <td>{overviewDetails?.percentual_reservado}%</td>
              <td>{formatarNumero(overviewDetails?.valor_reservado)}</td>
            </tr>
            <tr>
              <td>Disponível</td>
              <td>{overviewDetails?.locacoes__disponiveis}</td>
              <td>{overviewDetails?.percentual_disponivel}%</td>
              <td>{formatarNumero(overviewDetails?.valor_disponivel)}</td>
            </tr>

            <tr>
              <td style={{ fontWeight: "bold" }}>TOTAL DE LOCAÇÕES</td>
              <td style={{ fontWeight: "bold" }}>
                {overviewDetails?.total_de_locacoes}
              </td>
              <td style={{ fontWeight: "bold" }}>
                {(overviewDetails?.total_de_locacoes /
                  overviewDetails?.total_de_locacoes) *
                  100}
                %
              </td>
              <td style={{ fontWeight: "bold" }}>
                {formatarNumero(overviewDetails?.valor_total_das_locacoes)}
              </td>
            </tr>
          </table>

          <table>
            <tr cosplan={4}>
              <h4>Recebimentos das Locações/Mesas:</h4>
            </tr>
            <tr>
              <th>Desc.</th>
              <th>Qtd.</th>
              <th>%</th>
              <th>R$</th>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>TOTAL VENDIDOS</td>
              <td style={{ fontWeight: "bold" }}>
                {overviewDetails?.locacoes_vendidas}
              </td>
              <td></td>
              <td style={{ fontWeight: "bold" }}>
                {formatarNumero(overviewDetails?.valor_vendido)}
              </td>
            </tr>
            <tr>
              <td>Recebidos total</td>
              <td>{overviewDetails?.vendido_recebebidos}</td>
              <td>
                {(
                  overviewDetails?.percentual_vendido_recebebidos || 0
                )?.toFixed(2) || ""}
                %
              </td>
              <td>
                {formatarNumero(overviewDetails?.valor_vendido_recebebidos)}
              </td>
            </tr>
            <tr>
              <td>Recebidos parcial</td>
              <td>{overviewDetails?.vendido_recebimentos_parcial}</td>
              <td>
                {(
                  overviewDetails?.percentual_vendido_recebimentos_parcial || 0
                )?.toFixed(2) || ""}
                %
              </td>
              <td>
                {formatarNumero(
                  overviewDetails?.valor_vendido_recebimentos_parcial
                )}
              </td>
            </tr>
            <tr>
              <td>Nenhum valor recebido</td>
              <td>{overviewDetails?.vendido_recebimentos_pendente}</td>
              <td>
                {(
                  overviewDetails?.percentual_vendido_recebimentos_pendente || 0
                )?.toFixed(2) || ""}
                %
              </td>
              <td>
                {formatarNumero(
                  overviewDetails?.valor_vendido_pendente_receber
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={2} style={{ fontWeight: "bold" }}>
                TOTAL A RECEBER
              </td>
              <td style={{ fontWeight: "bold" }}>
                {" "}
                {(
                  ((overviewDetails?.valor_vendido -
                    (overviewDetails?.valor_vendido_recebimentos_parcial +
                      overviewDetails?.valor_vendido_recebebidos)) /
                    overviewDetails?.valor_vendido) *
                    100 || 0
                )?.toFixed(2) + "%"}
              </td>
              <td style={{ fontWeight: "bold" }}>
                {formatarNumero(
                  overviewDetails?.valor_vendido -
                    (overviewDetails?.valor_vendido_recebimentos_parcial +
                      overviewDetails?.valor_vendido_recebebidos)
                )}
              </td>
            </tr>
            <tr style={{ fontWeight: "bold" }}>
              <td colSpan={2} style={{ fontWeight: "bold" }}>
                TOTAL RECEBIDO
              </td>
              <td style={{ fontWeight: "bold" }}>
                {(
                  ((overviewDetails?.valor_vendido_recebimentos_parcial +
                    overviewDetails?.valor_vendido_recebebidos) /
                    overviewDetails?.valor_vendido) *
                    100 || 0
                )?.toFixed(2) + "%"}
              </td>
              <td style={{ fontWeight: "bold" }}>
                {formatarNumero(
                  overviewStatistics?.valor_vendido_recebimentos_parcial +
                    overviewStatistics?.valor_vendido_recebebidos
                )}
              </td>
            </tr>
          </table>
        </article>
        {/* 
        
        Ingressos avulsos abaixo
        */}

        <article className="overview-locacoes" style={{borderTop:0}}>
          {/* lugares */}
          <table style={{border:"1px dashed var(--black)"}}>
            <tr cosplan={4}>
              <h4>Lugares totais:</h4>
            </tr>
            <tr>
              <th>Desc.</th>
              <th>Qtd. em locações</th>
              <th>Qtd. em avulsos</th>
              <th>Qtd. Total</th>

              {/* <th>%</th> */}
              {/* <th>R$</th> */}
            </tr>
            

            <tr>
              <td>Vendidos</td>
              {/* <td>{overviewStatistics?.locacoes_vendidas}</td>
                <td>{overviewStatistics?.percentual_vendido}%</td>
                <td>{formatarNumero(overviewStatistics?.valor_vendido)}</td> */}
              <td>{overviewDetails?.locacoes_lugares_vendidos}</td>
              <td>{overviewDetails?.ingressos_avulsos_lugares_vendidos}</td>
              <td>{overviewDetails?.lugares_vendidos}</td>
              {/* <td>{overviewDetails?.percentual_vendido}%</td> */}
              {/* <td>{formatarNumero(overviewDetails?.valor_vendido)}</td> */}
            </tr>


            <tr>
              <td>Reservados</td>
              <td>{overviewDetails?.locacoes_lugares_rervados}</td>
              <td>{overviewDetails?.ingressos_avulsos_lugares_rervados}</td>
              <td>{overviewDetails?.lugares_rervados}</td>
              {/* <td>{overviewDetails?.percentual_reservado}%</td> */}
              {/* <td>{formatarNumero(overviewDetails?.lugares_rervados)}</td> */}
            </tr>
            <tr>
              <td>Disponível</td>
              <td>{overviewDetails?.locacoes_lugares_disponiveis}</td>
              <td>{overviewDetails?.ingressos_avulsos_lugares_disponiveis}</td>
              <td>{overviewDetails?.lugares_disponiveis}</td>

              {/* <td>{overviewDetails?.percentual_disponivel}%</td> */}
              {/* <td>{formatarNumero(overviewDetails?.valor_disponivel)}</td> */}
            </tr>

            <tr>
              <td style={{ fontWeight: "bold" }}>TOTAL DE LUGARES</td>
              <td style={{ fontWeight: "bold" }}>{overviewDetails?.locacoes_lugares_totais}</td>
              <td style={{ fontWeight: "bold" }}>{overviewDetails?.ingressos_avulsos_lugares_totais}</td>
              <td style={{ fontWeight: "bold" }}>{overviewDetails?.lugares_totais}</td>

              {/* <td style={{ fontWeight: "bold" }}>
                {(overviewDetails?.total_de_locacoes /
                  overviewDetails?.total_de_locacoes) *
                  100}
                %
              </td> */}
              {/* <td style={{ fontWeight: "bold" }}>
                {formatarNumero(overviewDetails?.valor_total_das_locacoes)}
              </td> */}
            </tr>
          </table>
        </article>
        <article className="view-area-button-add-ingresso">
          <h2>Ingressos individuais:</h2>
          <button onClick={() => openModalIngressoAvulso()}>Adicionar +</button>
        </article>
        <table>
          <thead>
            <tr>
              <th scope="col">Nº</th>
              <th scope="col">Comprador</th>
              <th scope="col">Vendedor</th>
              <th scope="col">Lugares</th>
              <th scope="col">Status</th>
              <th scope="col">Valor total</th>
              <th scope="col">Valor recebido</th>
              <th scope="col">Pagamento</th>
            </tr>
          </thead>
          <tbody>
            {ingressos_avulsos?.map((item) => {
              return IngressoAvulsoItemDetails(item);
            })}
          </tbody>
        </table>

        <h2>Locações:</h2>
        <table>
          <thead>
            <tr>
              <th scope="col">Nº</th>
              <th scope="col">Comprador</th>
              <th scope="col">Vendedor</th>
              <th scope="col">Lugares</th>
              <th scope="col">Status</th>
              <th scope="col">Valor total</th>
              <th scope="col">Valor recebido</th>
              <th scope="col">Pagamento</th>
            </tr>
          </thead>
          <tbody>
            {boxes?.map((item) => {
              return TableItemDetails(item);
            })}
          </tbody>
        </table>
      </section>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <ModalUpdateTable
          vendedores={vendedores}
          id_event={id_event}
          id_map={id_map}
          closeModal={closeModal}
          openModalTransfer={openModalTransfer}
          boxes={boxes}
          updateLocalBoxes={updateLocalBoxes}
          _dataLocacao={dataLocacao}
        />
      </Modal>

      <Modal
        isOpen={modalTransferOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <ModalTransferTable
          id_event={id_event}
          id_map={id_map}
          closeModal={closeModal}
          openModalTransfer={openModalTransfer}
          boxes={boxes}
          updateLocalBoxes={updateLocalBoxes}
          updateTransferLocalBoxes={updateTransferLocalBoxes}
          _dataLocacao={dataLocacao}
        />
      </Modal>

      <Modal
        isOpen={modalIngressoAvulsoIsOpen}
        onRequestClose={closeModalIngressoAvulso}
        style={customStyles}
      >
        <ModalAddIngressoAvulso
          vendedores={vendedores}
          id_event={id_event}
          id_map={id_map}
          closeModal={closeModalIngressoAvulso}
          ingressos_avulsos={ingressos_avulsos}
          setIngressosAvulsos={setIngressosAvulsos}
          boxes={boxes}
          detailsMap={detailsMap}
          updateLocalBoxes={updateLocalBoxes}
          _dataLocacao={dataLocacao}
        />
      </Modal>

      <Modal
        isOpen={modalUpdateIngressoAvulsoIsOpen}
        onRequestClose={closeModalUpdateIngressoAvulso}
        style={customStyles}
      >
        <ModalUpdateIngressoAvulso
          vendedores={vendedores}
          id_event={id_event}
          id_map={id_map}
          closeModal={closeModalUpdateIngressoAvulso}
          boxes={boxes}
          updateLocalIngressos={updateLocalIngressos}
          _dataLocacao={dataLocacao}
          openModalDeleteIngresso={openModalDeleteIngressoAvulso}
        />
      </Modal>

      <Modal
        isOpen={modalDeleteIngressoAvulsoIsOpen}
        onRequestClose={closeModalDeleteIngressoAvulso}
        style={customStyles}
      >
        <ModalDeleteIngresso
          vendedores={vendedores}
          id_event={id_event}
          _dataLocacao={dataLocacao}
          id_map={id_map}
          closeModal={closeModalDeleteIngressoAvulso}
          deleteLocalIngresso={deleteLocalIngresso}
        />
      </Modal>
    </div>
  );
}
