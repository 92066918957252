import update from "immutability-helper";
import { useCallback, useState } from "react";
import { useDrop } from "react-dnd";
import { DraggableBox } from "./DraggableBox.js";
import { ItemTypes } from "./ItemTypes.js";
import { snapToGrid as doSnapToGrid } from "./snapToGrid.js";
import background from "./img/mapa_soal.jpg";
const styles = {
  width: 926,
  height: 1200,
  border: "1px solid black",
  position: "relative",
  backgroundImage: `url(${background})`,
  backgroundSize: "cover", // Ajuste o tamanho da imagem de acordo com sua preferência
  backgroundRepeat: "no-repeat",
};
export const Container = ({ snapToGrid }) => {
  // const [boxes, setBoxes] = useState({
  //   a: { top: 20, left: 80, title: "1", status: "reserved" },
  //   b: { top: 180, left: 20, title: "2", status: "sold" },
  //   c: { top: 280, left: 20, title: "3", status: "free" },
  //   d: { top: 380, left: 20, title: "4", status: "sold" },
  //   e: { top: 480, left: 20, title: "5", status: "free" },
  //   f: { top: 580, left: 20, title: "6", status: "free" },
  //   g: { top: 680, left: 20, title: "7", status: "sold" },
  // });

  const [boxes, setBoxes] = useState([
    { top: 244, left: 820, title: "1", status: "free" },
    { top: 244, left: 771.5, title: "2", status: "free" },

    { top: 292.5, left: 820, title: "3", status: "free" },
    { top: 292.5, left: 771.5, title: "4", status: "free" },

    { top: 341, left: 820, title: "5", status: "free" },
    { top: 341, left: 771.5, title: "6", status: "free" },
    { top: 341, left: 723, title: "7", status: "free" },

    { top: 389.5, left: 820, title: "8", status: "free" },
    { top: 389.5, left: 771.5, title: "9", status: "free" },
    { top: 389.5, left: 723, title: "10", status: "free" },

    { top: 438, left: 820, title: "11", status: "free" },
    { top: 438, left: 771.5, title: "12", status: "free" },
    { top: 438, left: 723, title: "13", status: "free" },

    { top: 486.5, left: 820, title: "14", status: "free" },
    { top: 486.5, left: 771.5, title: "15", status: "free" },
    { top: 486.5, left: 723, title: "16", status: "free" },
    // //
    { top: 535, left: 820, title: "17", status: "free" },
    { top: 535, left: 771.5, title: "18", status: "free" },
    { top: 535, left: 723, title: "19", status: "free" },

    { top: 583.5, left: 820, title: "20", status: "free" },
    { top: 583.5, left: 771.5, title: "21", status: "free" },
    { top: 583.5, left: 723, title: "22", status: "free" },

    { top: 632, left: 820, title: "23", status: "free" },
    { top: 632, left: 771.5, title: "24", status: "free" },
    { top: 632, left: 723, title: "25", status: "free" },

    { top: 680.5, left: 820, title: "26", status: "free" },
    { top: 680.5, left: 771.5, title: "27", status: "free" },
    { top: 680.5, left: 723, title: "28", status: "free" },

    { top: 729, left: 820, title: "29", status: "free" },
    { top: 729, left: 771.5, title: "30", status: "free" },
    { top: 729, left: 723, title: "31", status: "free" },

    { top: 777.5, left: 820, title: "32", status: "free" },
    { top: 777.5, left: 771.5, title: "33", status: "free" },
    { top: 777.5, left: 723, title: "34", status: "free" },

    { top: 826, left: 820, title: "35", status: "free" },
    { top: 826, left: 771.5, title: "36", status: "free" },
    { top: 826, left: 723, title: "37", status: "free" },
    { top: 826, left: 674.5, title: "38", status: "free" },
    { top: 826, left: 626, title: "39", status: "free" },
    { top: 826, left: 577.5, title: "40", status: "free" },

    { top: 874.5, left: 820, title: "41", status: "free" },
    { top: 874.5, left: 771.5, title: "42", status: "free" },
    { top: 874.5, left: 723, title: "43", status: "free" },
    { top: 874.5, left: 674.5, title: "44", status: "free" },
    { top: 874.5, left: 626, title: "45", status: "free" },
    { top: 874.5, left: 577.5, title: "46", status: "free" },
    { top: 874.5, left: 529, title: "47", status: "free" },

    { top: 923, left: 820, title: "48", status: "free" },
    { top: 923, left: 771.5, title: "49", status: "free" },
    { top: 923, left: 723, title: "50", status: "free" },
    { top: 923, left: 674.5, title: "51", status: "free" },
    { top: 923, left: 626, title: "52", status: "free" },
    { top: 923, left: 577.5, title: "53", status: "free" },
    { top: 923, left: 529, title: "54", status: "free" },

    { top: 971.5, left: 820, title: "55", status: "free" },
    { top: 971.5, left: 771.5, title: "56", status: "free" },
    { top: 971.5, left: 723, title: "57", status: "free" },
    { top: 971.5, left: 674.5, title: "58", status: "free" },
    { top: 971.5, left: 626, title: "59", status: "free" },
    { top: 971.5, left: 577.5, title: "60", status: "free" },
    { top: 971.5, left: 529, title: "61", status: "free" },

    { top: 1020, left: 820, title: "62", status: "free" },
    { top: 1020, left: 771.5, title: "63", status: "free" },
    { top: 1020, left: 723, title: "64", status: "free" },
    { top: 1020, left: 674.5, title: "65", status: "free" },
    { top: 1020, left: 626, title: "66", status: "free" },
    { top: 1020, left: 577.5, title: "67", status: "free" },
    { top: 1020, left: 529, title: "68", status: "free" },

    { top: 1020, left: 371.5, title: "69", status: "free" },
    { top: 1020, left: 323, title: "70", status: "free" },
    { top: 1020, left: 274.5, title: "71", status: "free" },
    { top: 1020, left: 226, title: "72", status: "free" },

    { top: 971.5, left: 371.5, title: "73", status: "free" },
    { top: 971.5, left: 323, title: "74", status: "free" },
    { top: 971.5, left: 274.5, title: "75", status: "free" },
    { top: 971.5, left: 226, title: "76", status: "free" },

    { top: 923, left: 371.5, title: "77", status: "free" },
    { top: 923, left: 323, title: "78", status: "free" },
    { top: 923, left: 274.5, title: "79", status: "free" },
    { top: 923, left: 226, title: "80", status: "free" },
    { top: 923, left: 177.5, title: "81", status: "free" },

    { top: 874.5, left: 371.5, title: "82", status: "free" },
    { top: 874.5, left: 323, title: "83", status: "free" },
    { top: 874.5, left: 274.5, title: "84", status: "free" },
    { top: 874.5, left: 226, title: "85", status: "free" },
    { top: 874.5, left: 177.5, title: "86", status: "free" },

    { top: 826, left: 323, title: "87", status: "free" },
    { top: 826, left: 274.5, title: "88", status: "free" },
    { top: 826, left: 226, title: "89", status: "free" },
    { top: 826, left: 177.5, title: "90", status: "free" },

    { top: 777.5, left: 274.5, title: "91", status: "free" },
    { top: 777.5, left: 226, title: "92", status: "free" },
    { top: 777.5, left: 177.5, title: "93", status: "free" },

    { top: 729, left: 274.5, title: "94", status: "free" },
    { top: 729, left: 226, title: "95", status: "free" },
    { top: 729, left: 177.5, title: "96", status: "free" },

    { top: 680.5, left: 274.5, title: "97", status: "free" },
    { top: 680.5, left: 226, title: "98", status: "free" },
    { top: 680.5, left: 177.5, title: "99", status: "free" },

    { top: 632, left: 274.5, title: "100", status: "free" },
    { top: 632, left: 226, title: "101", status: "free" },
    { top: 632, left: 177.5, title: "102", status: "free" },


    { top: 550, left: 264, title: "103", status: "free" },
    { top: 550, left: 215.5, title: "104", status: "free" },
    { top: 550, left: 167, title: "105", status: "free" },

    { top: 501.5, left: 264, title: "106", status: "free" },
    { top: 501.5, left: 215.5, title: "107", status: "free" },
    { top: 501.5, left: 167, title: "108", status: "free" },

    { top: 453, left: 264, title: "109", status: "free" },
    { top: 453, left: 215.5, title: "110", status: "free" },
    { top: 453, left: 167, title: "111", status: "free" },
  
    { top: 404.5, left: 264, title: "112", status: "free" },
    { top: 404.5, left: 215.5, title: "113", status: "free" },
    { top: 404.5, left: 167, title: "114", status: "free" },

    { top: 356, left: 264, title: "115", status: "free" },
    { top: 356, left: 215.5, title: "116", status: "free" },
    { top: 356, left: 167, title: "117", status: "free" },

    
    { top: 307.5, left: 264, title: "118", status: "free" },
    { top: 307.5, left: 215.5, title: "119", status: "free" },
    { top: 307.5, left: 167, title: "120", status: "free" },
    
    { top: 259, left: 215.5, title: "121", status: "free" },
    { top: 259, left: 167, title: "122", status: "free" },

    
    { top: 210.5, left: 215.5, title: "123", status: "free" },
    { top: 210.5, left: 167, title: "124", status: "free" },

    
    { top: 210.5, left: 28, title: "125", status: "free" },
    { top: 210.5, left: 76.5, title: "126", status: "free" },

    { top: 259, left: 28, title: "127", status: "free" },
    { top: 259, left: 76.5, title: "128", status: "free" },

    
    { top: 307.5, left: 28, title: "129", status: "free" },
    { top: 307.5, left: 76.5, title: "130", status: "free" },

    { top: 356, left: 28, title: "131", status: "free" },
    { top: 356, left: 76.5, title: "132", status: "free" },

    { top: 404.5, left: 28, title: "133", status: "free" },
    { top: 404.5, left: 76.5, title: "134", status: "free" },

    { top: 453, left: 28, title: "135", status: "free" },
    { top: 453, left: 76.5, title: "136", status: "free" },

    
    { top: 501.5, left: 28, title: "137", status: "free" },
    { top: 501.5, left: 76.5, title: "138", status: "free" },

    
    { top: 550, left: 28, title: "138", status: "free" },
    { top: 550, left: 76.5, title: "140", status: "free" },

    { top: 632, left: 28, title: "141", status: "free" },
    { top: 632, left: 76.5, title: "142", status: "free" },

    { top: 680.5, left: 28, title: "143", status: "free" },
    { top: 680.5, left: 76.5, title: "144", status: "free" },

    { top: 729, left: 28, title: "145", status: "free" },
    { top: 729, left: 76.5, title: "146", status: "free" },

     
    { top: 777.5, left: 28, title: "147", status: "free" },
    { top: 777.5, left: 76.5, title: "148", status: "free" },

     
    { top: 826, left: 28, title: "149", status: "free" },
    { top: 826, left: 76.5, title: "150", status: "free" },
 
    { top: 874.5, left: 28, title: "151", status: "free" },
    { top: 874.5, left: 76.5, title: "152", status: "free" },

 
  ]);

  const moveBox = useCallback((id, left, top) => {
    console.log(id, left, top);

    setBoxes((prevBoxes) => ({
      ...prevBoxes,
      [id]: { ...prevBoxes[id], left, top },
    }));
  }, []);

  const _moveBox = useCallback(
    (id, left, top) => {
      console.log(id, left, top);
      setBoxes(
        update(boxes, {
          [id]: {
            $merge: { left, top },
          },
        })
      );
    },
    [boxes]
  );
  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        let left = Math.round(item.left + delta.x);
        let top = Math.round(item.top + delta.y);
        if (snapToGrid) {
          [left, top] = doSnapToGrid(left, top);
        }
        moveBox(item.id, left, top);
        return undefined;
      },
    }),
    [moveBox]
  );
  return (
    <div ref={drop} style={styles}>
      {Object.keys(boxes).map((key) => (
        <DraggableBox key={key} id={key} {...boxes[key]} />
      ))}
    </div>
  );
};
