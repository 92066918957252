// import Example from "./src/example_";
import { useCallback, useEffect, useState } from "react";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import "./tables.css";

import React from "react";
import { Mesas } from "./src/Mesas";

export default function Tables({ 
  boxes, setBoxes, detailsMap, editingBoxes

}) {

const handleClick = () => {
  console.log(
    // boxes,
    //  setBoxes,
      detailsMap,
    //    editingBoxes
  )

}

  const moveBox = (id, x, y) => {
    console.log(id, x, y, editingBoxes);
    if (editingBoxes) {
      moveBox_(id, x, y);
    }
  };


  const moveBox_ = useCallback((id, x, y) => {
    console.log(boxes)
    const updatedBoxes = [...boxes];
    const index = updatedBoxes.findIndex(
      (box) => box._id === id
    );


    if (index !== -1) {
      // Atualize a cor do item no índice encontrado
      updatedBoxes[index] = {
        ...updatedBoxes[index],
        x: x,
        y: y

      };
      setBoxes(updatedBoxes);
      boxes = updatedBoxes;
      // Atualize o estado com o novo array
    }
    // setBoxes((prevBoxes) => ({
    //   ...prevBoxes,
    //   [id]: { ...prevBoxes[id], x, y },
    // }));
    console.log(boxes)
    
  }, []);

  return (
    <>

    <DndProvider backend={HTML5Backend}>

     <Mesas moveBox={moveBox} boxes={boxes} detailsMap={detailsMap}/>
    </DndProvider> 
    </>
  );
}
