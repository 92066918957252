import { useContext, useState, useEffect } from "react";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { FiSettings, FiUpload } from "react-icons/fi";
import avatar from "../../assets/avatar.png";
import { AuthContext } from "../../contexts/auth";
import { toast } from "react-toastify";
import config from "../../config";
import ReactLoading from "react-loading";
import "./mapPlans.css";
import Footer from "../../components/Footer";

export default function MapPlans() {
  const { user, storageUser, setUser, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [namePlan, setNamePlan] = useState();
  const [descriptionPlan, setDescriptionPlan] = useState();
  const [mapPlansList, setMapPlansList] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);

  async function handleSubmit(e) {
    e.preventDefault();

    // Verificar se o arquivo foi selecionado
    if (!selectedFile) {
      toast.error("Selecione uma foto antes de enviar");
      return;
    }

    try {
      setLoading(true);

      const formData = new FormData();
      // formData.append("email", user?.email);
      // formData.append("token", user?.token);
      formData.append("namePlan", namePlan);
      formData.append("descriptionPlan", descriptionPlan);
      formData.append("thumbnail", selectedFile);

      const response = await fetch(`${config.urlServer}/map-plans/upload`, {
        method: "POST",
        headers: {
          email: user.email,
          token: user.token,
        },
        body: formData,
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao anexar foto";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      console.log(json);

      setLoading(false);
      setNamePlan("");
      setDescriptionPlan("");
      setMapPlansList("");
      getListMapPlans();
      toast.success("Foto anexada com sucesso!");
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error(error.msg || "Ops, algo deu errado ao anexar foto!");
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  useEffect(() => {
    getListMapPlans();
  }, []);


  async function getListMapPlans() {
    try {
      setLoading(true);

      const response = await fetch(`${config.urlServer}/map-plans/list`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
        },
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao buscar os mapas";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      // json deve ser uma lista de eventos
      console.log(json);
      setMapPlansList(json);
      // console.log(maps);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw new Error(
        error.msg || "Ops, algo deu errado ao buscar os eventos!"
      );
    }
  }

  return (
    <div>
      <Header />

      {loading ? (
        <article
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            padding: "10%",
          }}
        >
          <ReactLoading
            type="spinningBubbles"
            color="var(--rotary-gold)"
            height={"5%"}
            width={"5%"}
          />
        </article>
      ) : (
        <div className="content">
          <Title name="Adicionar planta mapas"></Title>

          <div className="container">
            <form className="form-profile" onSubmit={handleSubmit}>
              <label>Nome da planta</label>
              <input
                type="text"
                placeholder="Nome da planta do mapa"
                value={namePlan}
                required
                onChange={(e) => setNamePlan(e.target.value)}
              />

              <label>Descrição</label>
              <input
                type="text"
                placeholder="Descrição"
                value={descriptionPlan}
                onChange={(e) => setDescriptionPlan(e.target.value)}
              />

              {/* Input de foto */}
              <label>Foto da planta</label>
              <input type="file" accept="image/*" onChange={handleFileChange} />

              <button type="submit">Salvar</button>
            </form>
          </div>

          <section className="list-img-map-plans">
            <h2>Lista de imagens de plantas</h2>
            <div>
              {mapPlansList.length > 0 ? (
                mapPlansList?.map((item) => {
                  return (
                    <article>
                      <h3>{item?.name}</h3>
                      <p>{item?.description}</p>
                      <img src={`${item?.thumbnail_url}`} alt="" />
                      <i>{}</i>
                    </article>
                  );
                })
              ) : (
                <div> Nenhuma imagem encontrada</div>
              )}
            </div>
          </section>
        </div>
      )}

      <Footer />
    </div>
  );
}
