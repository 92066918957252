import { useCallback, useState } from 'react'
import { Container } from './Container.js'
import { CustomDragLayer } from './CustomDragLayer.js'
export const Mesas = ({moveBox, boxes, detailsMap}) => {
  const [snapToGridAfterDrop, setSnapToGridAfterDrop] = useState(false)
  const [snapToGridWhileDragging, setSnapToGridWhileDragging] = useState(false)
  const handleSnapToGridAfterDropChange = useCallback(() => {
    setSnapToGridAfterDrop(!snapToGridAfterDrop)
  }, [snapToGridAfterDrop])
  const handleSnapToGridWhileDraggingChange = useCallback(() => {
    setSnapToGridWhileDragging(!snapToGridWhileDragging)
  }, [snapToGridWhileDragging])
  return (
    <div>
      <Container snapToGrid={snapToGridAfterDrop} moveBox={moveBox} boxes={boxes} detailsMap={detailsMap}/>
      <CustomDragLayer snapToGrid={snapToGridWhileDragging} moveBox={moveBox} boxes={boxes}/>
      
    </div>
  )
}
