import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/auth";
import config from "../../config";

const Form = ({ formData, handleInputChange, handleSubmit, mapPlansList, setMapPlansList }) => {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);




  return (
    <form className="form-create-maps-event" onSubmit={handleSubmit}>
      <section>
        <article>
          <div>
            <label>Nome do mapa:</label>
            <input
              type="text"
              name="nome"
              value={formData.nome}
              onChange={handleInputChange}
            />
          </div>

          <div>
            <label>Descrição do mapa:</label>
            <textarea
              name="descricao"
              value={formData.descricao}
              onChange={handleInputChange}
            />
          </div>
          <article>
            <div>
              <label>Url ou selecionar:</label>
              <select
                name="urlOrSelect"
                value={formData.urlOrSelect}
                onChange={handleInputChange}
              >
                     <option key="0" value="" selected disabled hidden>Escolha uma opção para a imgem</option>

                <option value="url">URL externa</option>
                <option value="select">Selecionar imagem interna</option>
              </select>
            </div>
          </article>
          {formData?.urlOrSelect === "url" ? (
            <div>
              <label>Mapa URL:</label>
              <input
                type="text"
                name="map_url"
                value={formData.map_url}
                onChange={handleInputChange}
              />
              <span>
                Largura de <span>{formData.img_width_pixels}px</span> por{" "}
                <span>{formData.img_height_pixels}px</span> Altura
              </span>
              <span></span>
            </div>
          ) : formData?.urlOrSelect === "select" ? (
            <div>
              <label>Selecione a planta:</label>
              <select
                name="map_plan"
                value={formData.map_plan}
                onChange={handleInputChange}
              >
                     <option key="0" value="" selected disabled hidden>
                Escolha a planta..
              </option>
                {mapPlansList.map((item) => (
                  <option key={item?.thumbnail} value={item?.thumbnail}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
        </article>
        <div
          style={{ backgroundColor: "#000", width: "1px", margin: "5px" }}
        ></div>
        <article>
          <div>
            <label>Locação por:</label>
            <select
              name="tipo_locacao"
              value={formData.tipo_locacao}
              onChange={handleInputChange}
            >
              <option value="mesas">Mesas</option>
              <option value="cadeiras">Cadeiras</option>
            </select>
          </div>

          <div style={{ flexDirection: "row" }}>
            <div>
              <label>Quantidade de locações:</label>
              <input
                type="number"
                name="quantidade_de_locacoes"
                value={formData.quantidade_de_locacoes}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label>Pessoas por locação:</label>
              <input
                type="number"
                name="lugares_por_locacao"
                value={formData.lugares_por_locacao}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div style={{ flexDirection: "row" }}>
            <div>
              <label>Largura da locação (em pixels):</label>
              <input
                type="number"
                name="locacao_width_pixels"
                value={formData.locacao_width_pixels}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label>Altura da locação (em pixels):</label>
              <input
                type="number"
                name="locacao_height_pixels"
                value={formData.locacao_height_pixels}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </article>
      </section>
      <button type="submit">Salvar</button>
    </form>
  );
};

export default Form;
